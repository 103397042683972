import { useEffect, useState } from 'react';
import { IntegrationAppProvider } from '@integration-app/react';
import { get } from 'src/api/requests';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { ListAccountConnections } from './ListAccountConnections';

export const ListAccountConnectionsProvider = () => {
  const [token, setToken] = useState('');

  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const getIntegrationAppToken = async () => {
    const response = await get('/account-connections/integration-app/token', {
      envId,
    });

    if (response.token) setToken(response.token);
  };

  useEffect(() => {
    if (environment) getIntegrationAppToken();
  }, [environment]);

  return (
    <IntegrationAppProvider token={token}>
      <ListAccountConnections />
    </IntegrationAppProvider>
  );
};
