import clsx from 'clsx';
import { Link, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

type LayoutProps = {
  children?: React.ReactNode;
};

const cookies = new Cookies(null, { path: '/' });

export const UnloggedLayout = ({ children }: LayoutProps) => {
  cookies.remove('userId');

  return (
    <div
      style={{
        backgroundImage: `url(/assets/onboarding/bg.png)`,
        backgroundRepeat: 'repeat',
      }}
      className="min-h-screen bg-neutral-925"
    >
      <div
        style={{
          backgroundImage: `url(/assets/waves.png)`,
          backgroundRepeat: 'repeat-x',
          animation: 'wave-animation 20s linear infinite',
        }}
        className="fixed w-full min-h-screen flex flex-col justify-between"
      >
        <img
          src="/assets/logo-white.png"
          alt="Circle"
          className={clsx('absolute top-10 left-10 h-10 animate-fade-in-up')}
        />

        <div
          className={clsx(
            'absolute top-10 right-10 h-10 animate-fade-in-up z-10',
          )}
        >
          <a
            href="https://fluents.ai/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium opacity-100 text-white"
          >
            Contact Us
          </a>
        </div>

        <div className="flex-grow flex items-center justify-center animate-fade-in-up">
          {children || <Outlet />}
        </div>
      </div>
    </div>
  );
};
