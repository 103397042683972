import { useLocalStorageState } from 'ahooks';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { FirebaseError } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { clearCachedToken, get, post, renewToken } from 'src/api/requests';
import { Input } from 'src/components/Input';
import { UnloggedCard } from 'src/components/UnloggedCard';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { getFirebaseErrorMessage } from 'src/utils/FirebaseErrorUtils';
import { Button } from '../components/Button';

export const SignUp = () => {
  const { environment, updateEnvironment } = useEnvironment();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const [, setFireBaseDisplayName] = useLocalStorageState<string | null>(
    '@control-plane/fireBaseDisplayName',
    {
      defaultValue: '',
      listenStorageChange: true,
    },
  );

  const notification = useNotification();
  const navigate = useNavigate();

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      notification.error('Passwords do not match!');
      return;
    }

    if (!captchaToken) {
      notification.error('Please complete the reCAPTCHA verification');
      return;
    }

    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const user = userCredential.user;
      sendEmailVerification(user);

      await updateProfile(user, {
        displayName: name,
      });

      await auth.signOut();

      notification.success(
        'User registered successfully! Please confirm your account via the email we just sent you.',
      );

      navigate('/login');
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        console.error(error);
        notification.error('Error during signup');
      }
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
      setCaptchaToken(null);
    }
  };

  const handleGoogleSignUp = async () => {
    if (!captchaToken) {
      notification.error('Please complete the reCAPTCHA verification');
      return;
    }

    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setFireBaseDisplayName(user.displayName);

      if (user) {
        const [fname, lname] = user?.displayName?.split(' ') || [];
        const googleEmail = user.email || '';

        try {
          await post(`/users`, {
            fname,
            lname,
            email: googleEmail,
          });
          await renewToken();
        } catch (error) {
          if (error instanceof Error) {
            if (error?.message !== 'User found in the system') {
              notification.error(error.message);
              return;
            }
          }
          const err = error as AxiosError;
          if (err.status === 403) {
            notification.error(JSON.parse(err.request.response).message);
            return;
          }
        }

        const environments = await get('/environments');
        const isStoredEnvInEnvironments = environments.find(
          (env: { id: string }) => env.id === environment?.envId,
        );

        const initialEnvId =
          environment?.envId &&
          environment.userId === user.uid &&
          isStoredEnvInEnvironments
            ? environment.envId
            : environments[0].id;

        updateEnvironment({
          userId: user.uid,
          envId: initialEnvId,
        });

        await post(
          `/users/sync`,
          {},
          {
            envId: initialEnvId,
          },
        );

        clearCachedToken();

        notification.success('Sign up with Google successful!');

        // sleep 2 seconds to make sure the environment is updated
        await new Promise((resolve) => setTimeout(resolve, 2000));

        navigate('/login');
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error during Google sign up');
      }
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
      setCaptchaToken(null);
    }
  };

  return (
    <UnloggedCard>
      <h2 className="text-h2 font-medium mb-6">Create your account</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Input
            label="Full Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="mb-4">
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="mb-6">
          <Input
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="mb-6">
          <Input
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
          <div className="mb-6 flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              onChange={handleCaptchaChange}
            />
          </div>
        )}

        <Button className="w-full mb-6" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Sign Up'}
        </Button>

        <div className="relative mb-6">
          <div className="flex items-center justify-center">
            <div className="w-full border-t border-gray-300"></div>
            <span className="absolute bg-white px-2 text-gray-600">or</span>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center">
          <div
            className={clsx(
              'w-full mb-4 text-center font-medium text-body cursor-pointer',
              loading ? 'opacity-50 text-gray-400' : 'opacity-100 text-primary',
            )}
            onClick={handleGoogleSignUp}
          >
            Sign up with Google
          </div>

          <div
            className={clsx(
              'w-full text-center font-medium text-body cursor-pointer mb-4',
              loading ? 'opacity-50 text-gray-400' : 'opacity-100 text-primary',
            )}
            onClick={() => navigate('/login')}
          >
            {' '}
            Already have an account? Log In
          </div>

          <span className="font-body-light text-sm text-neutral-925 text-center">
            By signing in or creating an account, you are agreeing to our{' '}
            <a
              className="text-underline underline"
              target="_blank"
              href="https://storage.googleapis.com/fluents-docs/Fluents%20-%20TOS.pdf"
              rel="noopener noreferrer"
            >
              terms of use
            </a>{' '}
            and our{' '}
            <a
              className="text-underline underline"
              target="_blank"
              href="https://fluents.ai/privacy-policy"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
          </span>
        </div>
      </form>
    </UnloggedCard>
  );
};
