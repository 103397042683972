import { CaretLeft } from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';

type HeadingProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string;
  backAction?: () => void;
};

export const Heading = ({
  title,
  subtitle,
  children,
  className,
  backAction,
}: HeadingProps) => {
  return (
    <div className={clsx('p-6 flex flex-col w-full', 'border-b', className)}>
      <div className="flex flex-row justify-between">
        <div className="flex items-center gap-x-2">
          {backAction && (
            <div className="cursor-pointer text-gray-500" onClick={backAction}>
              <CaretLeft size={30} />
            </div>
          )}
          <div className="flex flex-col pt-8">
            <h1 className="text-3xl font-bold text-black mb-2">{title}</h1>
            <span className="font-medium text-neutral-950 truncate max-w-[500px]">
              {!!subtitle && subtitle}
            </span>
          </div>
        </div>
        <div className="flex flex-col min-w-[160px]">{children}</div>
      </div>
    </div>
  );
};
