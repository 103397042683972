import { Play, Spinner } from '@phosphor-icons/react';
import { useState } from 'react';
import { get } from 'src/api/requests';
import { Button } from './Button';
import { Modal } from './Modal';

type CallRecordingButtonProps = {
  callId: string;
  envId: string | undefined;
  recordingAvailable: boolean;
};

export const CallRecordingButton = ({
  callId,
  envId,
  recordingAvailable,
}: CallRecordingButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = async () => {
    if (!recordingAvailable) return;

    setLoading(true);
    try {
      const recording = await get(`/calls/recording/${callId}`, { envId });
      const blob = new Blob([new Uint8Array(recording.data)], {
        type: 'audio/mpeg',
      });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching recording:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        disabled={loading}
        className="flex items-center drop-shadow-md"
        onClick={handleOpenModal}
      >
        {loading ? <Spinner className="animate-spin" /> : <Play />}
      </Button>

      {isModalOpen && (
        <Modal
          title="Call Recording"
          isOpen={isModalOpen}
          onClose={() => {
            if (audioUrl) URL.revokeObjectURL(audioUrl);
            setAudioUrl(null);
            setIsModalOpen(false);
          }}
          className="w-96"
        >
          <div className="text-center">
            <audio
              controls
              src={audioUrl || ''}
              onEnded={() => {
                if (audioUrl) URL.revokeObjectURL(audioUrl);
                setAudioUrl(null);
                setIsModalOpen(false);
              }}
              className="w-full bg-white"
              autoPlay
            />
          </div>
        </Modal>
      )}
    </>
  );
};
