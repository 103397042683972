import _ from 'lodash';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { Trash } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useRef, useState, useEffect, ReactNode } from 'react';
import { Label } from './Label';

export type Option = {
  id?: string;
  value: string;
  label?: string;
};

type AutoCompleteInputProps = {
  id: string;
  label?: ReactNode;
  placeholder?: string;
  value: string;
  fetchOptions: (query: string) => Promise<Option[]>;
  defaultValue?: Option;
  variant?: 'outlined' | 'contained' | 'text';
  required?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onChange?: (value: string) => void;
  onRemove?: (value: string) => Promise<void>;
  disabled?: boolean;
  direction?: 'up' | 'down';
  defaultOptions?: Option[];
};

const variantClasses = {
  contained: {
    primary: 'bg-primary text-white hover:opacity-90',
    secondary: 'bg-gray-500 text-white hover:bg-gray-600',
    error: 'bg-red-500 text-white hover:bg-red-600',
    warning: 'bg-yellow-500 text-white hover:bg-yellow-600',
    success: 'bg-green-500 text-white hover:bg-green-600',
    info: 'bg-blue-500 text-white hover:bg-blue-600',
    default: 'bg-gray-500 text-white hover:bg-gray-600',
  },
  outlined: {
    primary: 'border hover:border-primary hover:text-primary',
    secondary:
      'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
    error:
      'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
    warning:
      'border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white',
    success:
      'border border-green-500 text-green-500 hover:bg-green-500 hover:text-white',
    info: 'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white',
    default:
      'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
  },
  text: {
    primary: 'text-primary hover:bg-primary hover:text-white',
    secondary: 'text-gray-500 hover:bg-gray-500 hover:text-white',
    error: 'text-red-500 hover:bg-red-500 hover:text-white',
    warning: 'text-yellow-500 hover:bg-yellow-500 hover:text-white',
    success: 'text-green-500 hover:bg-green-500 hover:text-white',
    info: 'text-blue-500 hover:bg-blue-500 hover:text-white',
    default: 'text-gray-500 hover:bg-gray-500 hover:text-white',
  },
};

const sizeClasses = {
  small: 'px-2 py-1 text-sm leading-6',
  medium: 'px-3 py-2.5 text-base leading-6',
  large: 'px-4 py-3 text-lg leading-7',
};

export const AutoCompleteInput = ({
  id,
  label,
  value,
  placeholder,
  fetchOptions,
  defaultValue,
  variant = 'outlined',
  color = 'primary',
  size = 'medium',
  className = '',
  onChange,
  onRemove,
  required,
  disabled = false,
  defaultOptions = [],
}: AutoCompleteInputProps) => {
  const [options, setOptions] = useState<Option[]>(defaultOptions);

  const debouncedFetchOptions = useRef(
    _.debounce(async (newQuery) => {
      try {
        const result = await fetchOptions(newQuery);

        setOptions(() =>
          newQuery === ''
            ? _.uniqBy(
                [...(defaultValue ? [defaultValue] : []), ...result],
                'value',
              )
            : result,
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    }, 300),
  );

  useEffect(() => {
    debouncedFetchOptions.current(value);
  }, [value]);

  const variantClass = variantClasses[variant][color];
  const sizeClass = sizeClasses[size];

  const handleRemove = async (option_id = '') => {
    await onRemove?.(option_id);
    debouncedFetchOptions.current(value);
  };

  return (
    <Combobox
      key={id}
      value={value}
      disabled={disabled}
      onChange={(value) => onChange?.(value || '')}
      immediate
    >
      <div className="relative">
        {label && (
          <Label htmlFor={id} className="text-gray-700" required={required}>
            {label}
          </Label>
        )}
        <ComboboxInput
          className={clsx(
            'w-full rounded-md border border-gray-300',
            `${variantClass} ${sizeClass}`,
            'flex justify-between items-center leading-6',
            'focus:outline-none',
            className,
            disabled && 'opacity-50 cursor-not-allowed',
            'leading-[1.7] placeholder:text-[14.9px]',
          )}
          aria-label={id}
          placeholder={placeholder}
          required={required}
          onChange={(event) => onChange?.(event.target.value)}
        />

        <ComboboxOptions
          anchor="bottom"
          transition
          className={clsx(
            'absolute z-50 mt-2 p-2 w-[var(--input-width)] rounded border border-black/5 bg-white [--anchor-gap:var(--spacing-1)] empty:invisible',
            'transition duration-100 ease-in absolute',
          )}
        >
          {options.map((option) => (
            <div className="relative" key={option.value}>
              {!!onRemove && (
                <Trash
                  className="absolute z-[60] right-2 top-1/2 -translate-y-1/2 cursor-pointer text-red-500"
                  onClick={() => handleRemove(option.id)}
                />
              )}
              <ComboboxOption
                value={option.value}
                className="select-none relative py-1 px-2 rounded-lg text-neutral-450 hover:bg-neutral-150 hover:text-neutral-450 flex items-center justify-between"
              >
                <p className="text-sm/6 text-black">{option.label}</p>
              </ComboboxOption>
            </div>
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};
