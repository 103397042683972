import { Checkbox } from '@headlessui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { MultiSelectBox } from 'src/components/MultiSelectBox';
import { SelectBox } from 'src/components/SelectBox';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { Action } from 'src/interfaces/action.interface';
import { Agent, Language, LLMProvider } from 'src/interfaces/agent.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { Webhook } from 'src/interfaces/webhook.interface';
import { getFirstAndLastFourUUID } from 'src/utils/number';

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'de', label: 'German' },
  { value: 'hi', label: 'Hindi' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'fr', label: 'French' },
  { value: 'nl', label: 'Dutch' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
];

const llmProviders = [
  { value: 'openai', label: 'OpenAI' },
  { value: 'google', label: 'Google' },
  { value: 'azure', label: 'Azure OpenAI' },
];

const providerModels = {
  openai: [
    { value: 'gpt-4', label: 'GPT-4' },
    { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
    { value: 'gpt-4o', label: 'GPT-4o' },
    { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
    { value: 'gpt-3.5', label: 'GPT-3.5' },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  ],
  google: [
    { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro' },
    { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash' },
    { value: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash Exp' },
  ],
  azure: [{ value: 'gpt-4o-mini', label: 'GPT-4o Mini' }],
};

const defaultTranscriberOption = { value: 'deepgram', label: 'Deepgram' };

const transcribersOptions = [
  defaultTranscriberOption,
  { value: 'assemblyai', label: 'AssemblyAI' },
];

type AdvancedSettingsProps = {
  agent: Agent;
  setAgent: (agent: Agent) => void;
  loading: boolean;
  defaultVoice: Voice | undefined;
  defaultPrompt: Prompt | undefined;
  defaultWebhook: Webhook | undefined;
  defaultActions: Action[] | undefined;
  actions: Action[];
  finishAction: (() => Promise<void>) | undefined;
};

export const AdvancedSettings = ({
  agent,
  setAgent,
  loading = false,
  defaultVoice,
  defaultWebhook,
  defaultActions = [],
  actions,
}: AdvancedSettingsProps) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const endpoint = agent.id ? `agents/update?id=${agent.id}` : 'agents/create';

  const searchVoices = useCallback(
    async (label: string) => {
      const queryObject: QueryObject = {
        ...(label.length !== 0 && {
          filters: JSON.stringify({ label, scope: 'global|user' }),
        }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/voices?${query.toString()}`, {
        envId,
      });

      return data.items.map((voice: Voice) => ({
        value: voice.id,
        label: voice.label,
      }));
    },
    [envId],
  );

  const defaultActionList = defaultActions.map((action) => ({
    value: action.id,
    label: `${action.label} ${action.type} (${getFirstAndLastFourUUID(action.id)})`,
  }));

  const actionList = _.uniqBy(
    [
      ...defaultActionList,
      ...actions.map((action) => ({
        value: action.id,
        label: `${action.label} ${action.type} (${getFirstAndLastFourUUID(action.id)})`,
      })),
    ],
    'value',
  );

  const searchWebhooks = useCallback(
    async (url: string) => {
      const queryObject: QueryObject = {
        ...(url.length !== 0 && {
          filters: JSON.stringify({ url, scope: 'global|user' }),
        }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/webhooks?${query.toString()}`, {
        envId,
      });

      return data.items.map((webhook: Webhook) => ({
        value: webhook.id,
        label: webhook.label || webhook.url,
      }));
    },
    [envId],
  );

  return (
    <div className="flex flex-col md:grid md:grid-cols-2 gap-2">
      <div className="mb-3">
        <Label required htmlFor="voice">
          Voice
        </Label>

        <AutoCompleteSelectBox
          key={defaultVoice?.id + '_' + defaultVoice?.label}
          defaultValue={
            defaultVoice?.id
              ? {
                  value: defaultVoice.id,
                  label: defaultVoice.label,
                }
              : undefined
          }
          fetchOptions={searchVoices}
          onChange={(value) => {
            setAgent({ ...agent, voice: value });
          }}
          className="min-w-[140px]"
          variant="outlined"
          color="primary"
          size="medium"
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>

      <div className="mb-3">
        <Label htmlFor="language">Language</Label>

        <SelectBox
          options={languages}
          key={agent.language}
          defaultValue={{
            value: agent.language,
            label: languages.find((lang) => lang.value === agent.language)
              ?.label,
          }}
          variant="outlined"
          color="primary"
          size="medium"
          onChange={(value) =>
            setAgent({ ...agent, language: value as Language })
          }
          className="w-full"
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="provider">LLM Provider</Label>

        <SelectBox
          options={llmProviders}
          key={agent.provider}
          defaultValue={{
            value: agent.provider || 'openai',
            label: llmProviders.find(
              (provider) => provider.value === (agent.provider || 'openai'),
            )?.label,
          }}
          variant="outlined"
          color="primary"
          size="medium"
          onChange={(value) =>
            setAgent({
              ...agent,
              provider: value as LLMProvider,
              model: undefined,
            })
          }
          className="min-w-[140px]"
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="model">Model Name</Label>

        <SelectBox
          options={providerModels[agent.provider || 'openai']}
          key={agent.model}
          defaultValue={
            agent.model
              ? {
                  value: String(agent.model),
                  label: providerModels[agent.provider || 'openai'].find(
                    (model) => model.value === agent.model,
                  )?.label,
                }
              : undefined
          }
          variant="outlined"
          color="primary"
          size="medium"
          onChange={(value) =>
            setAgent({
              ...agent,
              model: value as string,
            })
          }
          className="min-w-[140px]"
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="action">Speech to Text</Label>

        <AutoCompleteSelectBox
          key="agent-transcriber"
          defaultValue={
            agent.transcriber
              ? transcribersOptions.find(
                  ({ value }) => value === agent?.transcriber?.type,
                )
              : defaultTranscriberOption
          }
          defaultOptions={transcribersOptions}
          variant="outlined"
          color="primary"
          size="medium"
          className="min-w-[140px]"
          onChange={(value) =>
            setAgent({
              ...agent,
              transcriber: { type: value },
            })
          }
        />
      </div>
      <div className="col-span-2"></div>
      <div className="mb-3">
        <Label htmlFor="prompt">Webhooks</Label>

        <AutoCompleteSelectBox
          key={defaultWebhook?.id}
          defaultValue={
            defaultWebhook?.id
              ? {
                  value: defaultWebhook.id,
                  label: defaultWebhook.label || defaultWebhook.url,
                }
              : undefined
          }
          fetchOptions={searchWebhooks}
          onChange={(value) => {
            setAgent({ ...agent, webhook: value });
          }}
          className="min-w-[140px]"
          variant="outlined"
          color="primary"
          size="medium"
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="action">Actions</Label>

        {actionList.length === 0 && (
          <span className="text-gray-700 text-sm font-bold">
            No actions available, try creating one on the actions page. Click{' '}
            <Link to="/actions" className="text-primary underline">
              here
            </Link>
          </span>
        )}

        {actionList.length > 0 && (
          <MultiSelectBox
            key={defaultActions?.join('-')}
            defaultValue={defaultActionList}
            options={actionList}
            variant="outlined"
            color="primary"
            size="medium"
            className="min-w-[140px]"
            onChange={(value) => setAgent({ ...agent, actions: value })}
            disabled={
              loading || (!agent.user_id && endpoint !== 'agents/create')
            }
          />
        )}
      </div>
      <div className="mb-3">
        <Label htmlFor="llmTemperature">LLM Temperature</Label>

        <input
          type="range"
          id="llmTemperature"
          min="0"
          max="1"
          step="0.1"
          value={agent.llm_temperature}
          onChange={(e) =>
            setAgent({
              ...agent,
              llm_temperature: parseFloat(e.target.value),
            })
          }
          className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
        />

        <span className="text-gray-700/70 text-sm font-bold">
          {agent.llm_temperature}
        </span>
      </div>
      <div className="mb-3">
        <Label htmlFor="conversationSpeed">Conversation Speed</Label>

        <input
          type="range"
          id="conversationSpeed"
          min="0.2"
          max="5"
          step="0.2"
          value={agent.conversation_speed}
          onChange={(e) =>
            setAgent({
              ...agent,
              conversation_speed: parseFloat(e.target.value),
            })
          }
          className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
        />

        <span className="text-gray-700/70 text-sm font-bold">
          {agent.conversation_speed}
        </span>
      </div>
      <div className="mb-3">
        <Label htmlFor="maxIdleCheckCount">Max Idle Check Count</Label>

        <Input
          type="number"
          id="maxIdleCheckCount"
          min="0"
          value={agent.max_idle_check_count || ''}
          onChange={(e) =>
            setAgent({
              ...agent,
              max_idle_check_count: e.target.value
                ? parseInt(e.target.value)
                : undefined,
            })
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
          placeholder="Enter max idle check count"
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="idleTimeSeconds">Idle Time (seconds)</Label>

        <Input
          type="number"
          id="idleTimeSeconds"
          min="0"
          value={agent.idle_time_seconds || ''}
          onChange={(e) =>
            setAgent({
              ...agent,
              idle_time_seconds: e.target.value
                ? parseInt(e.target.value)
                : undefined,
            })
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
          placeholder="Enter idle time in seconds"
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="callDurationSec">Call Duration (seconds)</Label>

        <Input
          type="number"
          id="callDurationSec"
          min="0"
          value={agent.call_duration_sec || ''}
          onChange={(e) =>
            setAgent({
              ...agent,
              call_duration_sec: e.target.value
                ? parseInt(e.target.value)
                : undefined,
            })
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
          placeholder="Enter call duration in seconds"
        />
      </div>
      <div className="mb-3 col-span-2 flex justify-between">
        <Label htmlFor="enableRecording">Enable Recording</Label>

        <div className="flex items-center gap-2">
          <Checkbox
            checked={agent.enable_recording || false}
            onChange={(newChecked) =>
              setAgent({
                ...agent,
                enable_recording: newChecked,
              })
            }
            className={`
                        ${agent.enable_recording ? 'bg-primary border-transparent' : 'bg-white border-gray-300'}
                        relative inline-flex h-5 w-5 items-center justify-center rounded border-2
                        transition-colors duration-200 ease-in-out
                        focus:outline-none
                      `}
          >
            <span className="sr-only">Enable Recording</span>
            <span
              className={`
                          pointer-events-none absolute h-3 w-3 transform
                          ${agent.enable_recording ? 'opacity-100' : 'opacity-0'}
                          transition-opacity duration-200 ease-in-out
                        `}
            >
              <svg
                className="h-3 w-3 text-white"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M3 6l2 2 4-4"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};
