import { Code } from '@phosphor-icons/react';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Tab } from 'src/components/Tab';
import { Action } from 'src/interfaces/action.interface';
import { Agent } from 'src/interfaces/agent.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { Webhook } from 'src/interfaces/webhook.interface';
import { AdvancedSettings } from './components/AdvancedSettings';
import { BasicInformation } from './components/BasicInformation';
import { EquivalentCode } from 'src/components/EquivalentCode';
import clsx from 'clsx';
import { AgentCaller } from './components/AgentCaller';

type TabsAgentProps = {
  agent: Agent;
  setAgent: (agent: Agent) => void;
  loading: boolean;
  defaultVoice: Voice | undefined;
  defaultPrompt: Prompt | undefined;
  defaultWebhook: Webhook | undefined;
  defaultActions: Action[] | undefined;
  actions: Action[];
  finishAction: (() => Promise<void>) | undefined;
  isEditing: boolean;
};

export const TabItem = ({
  children,
  agent,
  isEditing = false,
}: {
  children: React.ReactNode;
  agent: Agent;
  isEditing: boolean;
}) => {
  return (
    <div
      className={clsx(
        'flex',
        'flex-col',
        'md:grid',
        'md:grid-cols-[calc(60%-0.5rem)_calc(40%-0.5rem)]',
        'lg:grid-cols-[calc(65%-0.5rem)_calc(35%-0.5rem)]',
        'gap-x-4',
        'justify-between',
      )}
    >
      <div className="p-6 flex flex-col h-full w-full">{children}</div>

      <AgentCaller agent={agent} isEditing={isEditing} />
    </div>
  );
};

export const TabsAgent = ({
  agent,
  setAgent,
  loading,
  defaultVoice,
  defaultPrompt,
  defaultWebhook,
  defaultActions,
  actions,
  finishAction,
  isEditing = false,
}: TabsAgentProps) => {
  const endpoint = agent.id ? `agents/update?id=${agent.id}` : 'agents/create';

  return (
    <Tab
      className="pl-2"
      items={[
        {
          label: 'Basic Information',
          content: (
            <TabItem agent={agent} isEditing={isEditing}>
              <BasicInformation
                agent={agent}
                setAgent={setAgent}
                loading={loading}
                defaultPrompt={defaultPrompt}
              />
            </TabItem>
          ),
        },
        {
          label: 'Advanced Settings',
          content: (
            <TabItem agent={agent} isEditing={isEditing}>
              <AdvancedSettings
                agent={agent}
                setAgent={setAgent}
                loading={loading}
                defaultVoice={defaultVoice}
                defaultPrompt={defaultPrompt}
                defaultWebhook={defaultWebhook}
                defaultActions={defaultActions}
                actions={actions}
                finishAction={finishAction}
              />
            </TabItem>
          ),
        },
        {
          label: (
            <div className="flex">
              <Code className="mr-2" size={20} />
              Show equivalent code
            </div>
          ),
          content: (
            <ContentWrapper>
              <EquivalentCode
                payload={agent}
                endpoint={endpoint}
                method="POST"
              />
            </ContentWrapper>
          ),
        },
      ]}
      panelClassName="focus:outline-none"
      panelWrapperClassName="mt-0"
    />
  );
};
