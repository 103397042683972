import {
  Code,
  FileText,
  LinkSimpleBreak,
  MagnifyingGlass,
  Pencil,
  Trash,
  X,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { get, patch, remove } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { Option } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table, { Row } from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { AccountConnection } from 'src/interfaces/accountConnection.interface';
import { Agent } from 'src/interfaces/agent.interface';
import { Number } from 'src/interfaces/number.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';
import { BuyNumber } from './components/BuyNumber';
import { LinkNumber } from './components/LinkNumber';
import { AccountConnectionOption } from './numbersTypes';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'number', label: 'Number', width: '20%' },
  { key: 'inbound_agent', label: 'Agent ID', width: '8rem' },
  { key: 'inbound_agent_name', label: 'Agent Name', width: '8rem' },
  { key: 'telephony_provider', label: 'Telephony Provider', width: '10%' },
  {
    key: 'telephony_account_connection',
    label: 'Account Connection',
    width: '8rem',
  },
  { key: 'actions', label: '', width: '20%', disableSorting: true },
];

const providerOptions: { [key: string]: string } = {
  account_connection_twilio: 'Twilio',
  account_connection_telnyx: 'Telnyx',
  account_connection_openai: 'OpenAI',
  account_connection_elevenlabs: 'ElevenLabs',
  account_connection_deepgram: 'Deepgram',
  account_connection_cartesia: 'Cartesia',
  account_connection_playht: 'PlayHT',
  account_connection_azure_speech: 'Azure Speech',
  account_connection_google: 'Google',
  account_connection_azure_llm: 'Azure LLM',
};

export const ListNumbers = () => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const notification = useNotification();

  const [rows, setRows] = useState<Number[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const [modalBuyOpen, setModalBuyOpen] = useState(false);
  const [modalLinkOpen, setModalLinkOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalDetachOpen, setModalDetachOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const [agents, setAgents] = useState<Option[]>([]);
  const [accountConnections, setAccountConnections] = useState<
    AccountConnectionOption[]
  >([]);

  const [selectedNumber, setSelectedNumber] = useState<Number | null>(null);
  const [currentAgent, setCurrentAgent] = useState<Option | undefined>(
    undefined,
  );
  const [hasTelephonyConnection, setHasTelephonyConnection] = useState(false);

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      ...(filter.length !== 0 && {
        filters: JSON.stringify({ number: filter }),
      }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/numbers?${query.toString()}`, {
      envId,
    });

    const items = data.items.map((item: Number) => ({
      ...item,
      number: processPhoneNumber(item.number),
      telephony_account_connection: getFirstAndLastFourUUID(
        item.telephony_account_connection || '',
      ),
    }));

    setRows(items);

    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const openEditModal = (number: Number) => {
    setCurrentAgent(undefined);
    setSelectedNumber(number);
    setModalEditOpen(true);
  };

  const closeEditModal = () => {
    setSelectedNumber(null);
    setEquivalentCodeOpen(false);
    setModalEditOpen(false);
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/[^0-9]/g, '');
  };

  const confirmEditAction = async () => {
    if (selectedNumber) {
      setLoading(true);

      await patch(
        `/numbers/${selectedNumber.id}?phone_number=${cleanPhoneNumber(selectedNumber.number)}`,
        {
          inbound_agent: selectedNumber.inbound_agent,
        },
        { envId },
      );

      setLoading(false);

      notification.success('Number updated successfully');
      setModalEditOpen(false);
      fetchData(currentPage, size);
      setSelectedNumber(null);
    }
  };

  const openDetachModal = (number: Number) => {
    setSelectedNumber(number);
    setModalDetachOpen(true);
  };

  const confirmDetachAction = async () => {
    if (selectedNumber) {
      setLoading(true);

      const cleanedNumber = cleanPhoneNumber(selectedNumber.number);

      await remove(`/numbers/detach/${cleanedNumber}`, { envId });

      setLoading(false);

      notification.success('Number detached successfully');
      setModalDetachOpen(false);
      fetchData(currentPage, size);
      setSelectedNumber(null);
    }
  };

  const closeDetachModal = () => {
    setModalDetachOpen(false);
    setSelectedNumber(null);
  };

  const openDeleteModal = (number: Number) => {
    setSelectedNumber(number);
    setModalDeleteOpen(true);
  };

  const confirmDeleteAction = async () => {
    if (selectedNumber) {
      setLoading(true);

      const cleanedNumber = cleanPhoneNumber(selectedNumber.number);

      await remove(`/numbers/cancel/${cleanedNumber}`, { envId });

      setLoading(false);

      notification.success('Number canceled successfully');
      setModalDeleteOpen(false);
      fetchData(currentPage, size);
      setSelectedNumber(null);
    }
  };

  const closeDeleteModal = () => {
    setModalDeleteOpen(false);
    setSelectedNumber(null);
  };

  const endpoint = selectedNumber
    ? `numbers/update?phone_number=${cleanPhoneNumber(selectedNumber.number)}`
    : ``;

  const fetchCurrentAgent = useCallback(async () => {
    if (selectedNumber?.inbound_agent) {
      const agent = await get(`/agents/${selectedNumber.inbound_agent}`, {
        envId,
      });
      return agent;
    }
  }, [selectedNumber?.inbound_agent]);

  const searchAgents = useCallback(
    async (name: string) => {
      const queryObject: QueryObject = {
        ...(name.length !== 0 && { filters: JSON.stringify({ name }) }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/agents?${query.toString()}`, {
        envId,
      });

      const agentOptions = data.items.map((agent: Agent) => ({
        value: agent.id,
        label: agent.name,
      }));

      setAgents(agentOptions);

      return agentOptions;
    },
    [envId],
  );

  const fetchAccountConnections = async () => {
    const data = await get('/account-connections', {
      envId,
    });

    const numberProviders = [
      'account_connection_twilio',
      'account_connection_telnyx',
    ];

    setAccountConnections(
      data.items.reduce(
        (
          acc: AccountConnectionOption[],
          accountConnection: AccountConnection,
        ) => {
          if (numberProviders.includes(accountConnection.type)) {
            acc.push({
              value: accountConnection.id || '',
              connectionType: accountConnection.type,
              label: `${providerOptions[accountConnection.type]} - ${getFirstAndLastFourUUID(accountConnection.id || '')}`,
            });
          }
          return acc;
        },
        [],
      ),
    );

    setHasTelephonyConnection(
      data.items.some(
        (accountConnection: AccountConnection) =>
          accountConnection.type === 'account_connection_twilio' ||
          accountConnection.type === 'account_connection_telnyx',
      ),
    );
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [selectedNumber, equivalentCodeOpen]);

  const fetchRelatedData = async () => {
    await Promise.all([fetchAccountConnections()]);
  };

  useEffect(() => {
    fetchRelatedData();
  }, []);

  useEffect(() => {
    async function populateCurrentAgent() {
      if (selectedNumber?.inbound_agent) {
        const fetchedAgent = await fetchCurrentAgent();

        setCurrentAgent({
          value: fetchedAgent.id,
          label: fetchedAgent.name,
        });
      }
    }

    populateCurrentAgent();
  }, [selectedNumber?.inbound_agent]);

  return (
    <div className="flex-1">
      <Heading
        title="Numbers"
        subtitle="Phone numbers available for your account"
      >
        <div className="flex mt-6">
          <Button
            className="w-42 border-none flex items-center justify-center text-gray-600"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/numbers"
            target="_blank"
          >
            View help doc
            <FileText className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex flex-col md:flex-row justify-between mb-6">
          <div className="flex w-full md:w-96 self-end">
            <Input
              type="text"
              required
              placeholder="Search numbers"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  submitFilter();
                }
              }}
              icon={<MagnifyingGlass size={20} />}
              iconPosition="left"
              inputAction={() => fetchData(currentPage, size)}
            />
          </div>

          <div className="mt-4 md:mt-0">
            <Button className="mr-2" onClick={() => setModalBuyOpen(true)}>
              Buy
            </Button>

            <Tooltip
              content={
                hasTelephonyConnection
                  ? ''
                  : 'Telephony connection is required to import numbers'
              }
              position="left"
            >
              <Button
                disabled={!hasTelephonyConnection}
                onClick={() => setModalLinkOpen(true)}
                color={hasTelephonyConnection ? 'primary' : 'default'}
              >
                Import
              </Button>
            </Tooltip>
          </div>
        </div>

        <Table
          headers={headers}
          rows={rows.map(
            (row) =>
              ({
                id: (
                  <CopyableField value={row.id} notification={notification} />
                ),
                number: (
                  <CopyableField
                    value={row.number}
                    notification={notification}
                  />
                ),
                inbound_agent: (
                  <CopyableField
                    value={row.inbound_agent}
                    notification={notification}
                  />
                ),
                inbound_agent_name: row.inbound_agent_name,
                telephony_provider: row.telephony_provider,
                telephony_account_connection: row.telephony_account_connection,
                actions: (
                  <div className="flex justify-center items-center">
                    <SideDropActions
                      options={[
                        {
                          label: 'Edit',
                          Icon: Pencil,
                          onClick: () => openEditModal(row),
                        },
                        {
                          label: 'Detach',
                          Icon: LinkSimpleBreak,
                          show: !!row.active,
                          onClick: () => openDetachModal(row),
                        },
                        {
                          label: 'Delete',
                          Icon: Trash,
                          color: 'error',
                          show: !!row.active,
                          onClick: () => openDeleteModal(row),
                        },
                      ]}
                    />
                  </div>
                ),
              }) as Row,
          )}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <BuyNumber
        onClose={() => setModalBuyOpen(false)}
        refreshData={() => fetchData(currentPage, size)}
        modalOpen={modalBuyOpen}
        setModalOpen={setModalBuyOpen}
        agents={agents}
        accountConnections={accountConnections}
      />

      <LinkNumber
        onClose={() => setModalLinkOpen(false)}
        refreshData={() => fetchData(currentPage, size)}
        modalOpen={modalLinkOpen}
        setModalOpen={setModalLinkOpen}
        agents={agents}
        accountConnections={accountConnections}
      />

      {/* Edit Number Modal */}
      <Modal
        title="Edit Number"
        isOpen={modalEditOpen}
        onClose={() => closeEditModal()}
        actionButton={
          <Button onClick={() => confirmEditAction()} disabled={loading}>
            Save Number
          </Button>
        }
      >
        {selectedNumber && (
          <>
            <div className="flex justify-end mb-1">
              <Button
                onClick={() =>
                  equivalentCodeOpen
                    ? setEquivalentCodeOpen(false)
                    : setEquivalentCodeOpen(true)
                }
                className="flex items-center justify-center"
              >
                <Code className="mr-2" size={20} />
                Show equivalent code
              </Button>
            </div>

            <div className="grid grid-cols-1 gap-6">
              <div>
                <Label>Current Number</Label>
                <Input type="text" value={selectedNumber.number} disabled />
              </div>

              <div>
                <Label>Select Agent</Label>
                <AutoCompleteSelectBox
                  key={currentAgent?.value}
                  defaultValue={currentAgent}
                  fetchOptions={searchAgents}
                  onChange={(value) =>
                    setSelectedNumber({
                      ...selectedNumber,
                      inbound_agent: value,
                    })
                  }
                  variant="outlined"
                  color="primary"
                  size="medium"
                  disabled={loading}
                />
              </div>
            </div>

            {equivalentCodeOpen && (
              <div className="mt-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="block text-gray-700 text-md font-bold mb-2">
                    Equivalent Code
                  </h3>

                  <X
                    className="cursor-pointer inline-block w-5 h-5"
                    onClick={() => setEquivalentCodeOpen(false)}
                  />
                </div>

                <EquivalentCode
                  payload={
                    selectedNumber
                      ? {
                          inbound_agent: selectedNumber.inbound_agent,
                        }
                      : {}
                  }
                  endpoint={endpoint}
                  method="POST"
                />
              </div>
            )}
          </>
        )}
      </Modal>

      {/* Detach Number Modal */}
      <Modal
        title="Detach Number"
        isOpen={modalDetachOpen}
        onClose={closeDetachModal}
        actionButton={
          <Button
            color="error"
            onClick={() => confirmDetachAction()}
            disabled={loading}
          >
            Detach Number
          </Button>
        }
      >
        <p className="text-sm text-gray-700">
          Proceed to detach the number ({selectedNumber?.number})? This will
          deactivate it in Fluents but keep it available in your telephony
          provider
        </p>
      </Modal>

      {/* Cancel Number Modal */}
      <Modal
        title="Cancel Number"
        isOpen={modalDeleteOpen}
        onClose={closeDeleteModal}
        actionButton={
          <Button
            color="error"
            onClick={() => confirmDeleteAction()}
            disabled={loading}
          >
            Cancel Number
          </Button>
        }
      >
        <p className="text-sm text-gray-700">
          Are you sure you want to cancel this number? ({selectedNumber?.number}
          )
        </p>
      </Modal>
    </div>
  );
};
