import { Connection, useIntegrationApp } from '@integration-app/react';
import { ArrowClockwise, Pencil, Wrench } from '@phosphor-icons/react';
import axios from 'axios';
import { post } from 'src/api/requests';
import { SideDropActions } from 'src/components/SideDropActions';
import { useNotification } from 'src/contexts/NotificationContext';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { generateActionStub } from 'src/stubs/action.stub';

const IntegrationAppActions = ({
  id,
  integrationId,
  integration,
}: Connection) => {
  const integrationApp = useIntegrationApp();

  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const options = [
    {
      label: 'Edit',
      Icon: Pencil,
      onClick: () => integrationApp?.integration(integrationId).open(),
    },
    {
      label: 'Import tools',
      Icon: Wrench,
      onClick: async () => {
        try {
          // Get actions for integration
          const config = {
            baseURL: `https://api.integration.app/integrations/${integrationId}/actions`,
            headers: {
              Authorization: `Bearer ${integrationApp.token}`,
            },
          };

          const response = await axios.request(config);
          const actions = response.data;

          if (!actions || !actions.length) {
            notification.info('No tools to import!');
            return;
          }

          for (const action of actions) {
            const actionPayload = generateActionStub(
              action.key,
              id,
              action.id,
              integration?.name || '',
            );
            await post('/actions', actionPayload, {
              envId,
            });
          }
          notification.success(
            <div>
              Tools imported successfully. Check them out{' '}
              <a className="underline" href="/actions?tab=external">
                here
              </a>
            </div>,
          );
        } catch {
          notification.error(
            'Error importing tools. Please reach out to support',
          );
        }
      },
    },
    {
      label: 'Run Sync',
      Icon: ArrowClockwise,
      onClick: async () => {
        const flows = await integrationApp.flows.find({
          integrationId,
        });
        const batchFlow = flows?.items.find(
          ({ key }) => key === 'create-batch-call',
        );
        if (batchFlow) {
          await integrationApp.connection(id).flow(batchFlow.id).run({
            input: null,
          });
          return notification.success('Sync run successfully');
        }
        return notification.error('No sync flow found');
      },
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <SideDropActions options={options} />
    </div>
  );
};

export default IntegrationAppActions;
