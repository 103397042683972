import { useState } from 'react';
import clsx from 'clsx';

interface ToggleProps {
  initialState?: boolean;
  onChange?: (isActive: boolean) => void;
  disabled?: boolean;
}

export const Toggle = ({
  initialState = false,
  onChange,
  disabled = false,
}: ToggleProps) => {
  const [isActive, setIsActive] = useState(initialState);

  const handleToggle = () => {
    if (disabled) return;
    const newState = !isActive;
    setIsActive(newState);
    onChange?.(newState);
  };

  return (
    <button
      className={clsx(
        'relative w-11 h-6 rounded-full transition-colors duration-200',
        'focus:outline-none',
        isActive ? 'bg-primary' : 'bg-gray-500',
        disabled && 'opacity-50 cursor-not-allowed pointer-events-none',
      )}
      onClick={handleToggle}
      role="switch"
      aria-checked={isActive}
      disabled={disabled}
    >
      <span
        className={clsx(
          'absolute left-0.5 top-0.5 w-5 h-5 bg-white rounded-full transition-transform duration-200',
          isActive && 'translate-x-5',
        )}
      />
    </button>
  );
};
