import { ReactNode } from 'react';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  zIndex?: 10 | 20 | 30 | 40 | 50;
  className?: string;
  theme?: 'dark' | 'light';
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  position = 'top',
  zIndex = 10,
  className = '',
  theme = 'dark',
}) => {
  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 translate-y-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 -translate-x-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 translate-x-2',
  };

  const arrowPositionClasses = {
    top: 'bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1',
    bottom: 'top-0 left-1/2 transform -translate-x-1/2 -translate-y-1',
    left: 'right-0 top-1/2 transform -translate-y-1/2 translate-x-1',
    right: 'left-0 top-1/2 transform -translate-y-1/2 -translate-x-1',
  };

  const themeClasses = {
    dark: 'bg-tooltip-background text-tooltip-color',
    light: 'bg-white text-neutral-900 shadow-lg',
  };

  const isDisabled = content === '';

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <div className={`relative group inline-block ${className}`}>
      {children}

      <div
        role="tooltip"
        className={`max-w-[15rem] w-max absolute z-${zIndex} ${positionClasses[position]} invisible opacity-0 group-hover:visible group-hover:opacity-100 ${themeClasses[theme]} text-xs rounded py-2 px-3 transition-opacity duration-300`}
      >
        <div className="text-ellipsis line-clamp-5">{content}</div>
        <div
          className={`absolute w-2 h-2 ${themeClasses[theme]} transform rotate-45 ${arrowPositionClasses[position]}`}
        />
      </div>
    </div>
  );
};
