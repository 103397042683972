import { RadioGroup } from '@headlessui/react';
import { useCallback, useState } from 'react';
import { get } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { RadioTab } from 'src/components/RadioTab';
import { Textarea } from 'src/components/Textarea';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { AgentVoiceFemaleStub, AgentVoiceMaleStub } from 'src/stubs/agent.stub';
import { limitString } from 'src/utils/limitString';

type BasicInformationProps = {
  agent: Agent;
  setAgent: (agent: Agent) => void;
  loading: boolean;
  defaultPrompt: Prompt | undefined;
};

export const BasicInformation = ({
  agent,
  setAgent,
  loading = false,
  defaultPrompt,
}: BasicInformationProps) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const endpoint = agent.id ? `agents/update?id=${agent.id}` : 'agents/create';

  const [voiceType, setVoiceType] = useState<'male' | 'female' | undefined>(
    undefined,
  );

  const searchPrompts = useCallback(
    async (content: string) => {
      const queryObject: QueryObject = {
        filters: JSON.stringify({
          ...(content.length !== 0 && { content }),
          type: 'prompt',
          scope: 'global|user',
        }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/prompts?${query.toString()}`, {
        envId,
      });

      return data.items.map((prompt: Prompt) => ({
        value: prompt.id,
        label: limitString(prompt.content, 50),
      }));
    },
    [envId],
  );

  const handleChangeVoiceType = (value: 'male' | 'female' | undefined) => {
    setVoiceType(value);

    if (value === 'male') {
      setAgent({ ...agent, voice: AgentVoiceMaleStub as Voice });
    } else if (value === 'female') {
      setAgent({ ...agent, voice: AgentVoiceFemaleStub as Voice });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-4">
        <Input
          label="Agent Name"
          type="text"
          id="agent-name"
          placeholder="Enter agent name"
          value={agent.name ?? ''}
          onChange={(e) => setAgent({ ...agent, name: e.target.value })}
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
          className="max-w-xs"
        />
      </div>
      {endpoint === 'agents/create' && (
        <div className="mb-4">
          <Label required>Voice Type</Label>
          <RadioGroup
            value={voiceType}
            onChange={(value) => handleChangeVoiceType(value)}
            className="mt-2"
          >
            <div className="flex gap-4">
              <RadioGroup.Option value="male">
                {({ checked }) => (
                  <div
                    className={`${
                      checked ? 'bg-primary text-white' : 'bg-white'
                    } cursor-pointer rounded-lg px-4 py-2 border border-gray-300`}
                  >
                    Male
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="female">
                {({ checked }) => (
                  <div
                    className={`${
                      checked ? 'bg-primary text-white' : 'bg-white'
                    } cursor-pointer rounded-lg px-4 py-2 border border-gray-300`}
                  >
                    Female
                  </div>
                )}
              </RadioGroup.Option>
            </div>
          </RadioGroup>
        </div>
      )}
      <div className="mb-4">
        <Input
          label="Agent Description"
          type="text"
          id="agent-description"
          placeholder="This is a sample description to help remember the purpose of this agent"
          value={agent.description || ''}
          onChange={(e) => setAgent({ ...agent, description: e.target.value })}
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-4">
        <Input
          label="Initial Message"
          type="text"
          id="initialMessage"
          placeholder="Hi {name}, this is Fluents AI. How can I help you today?"
          value={agent.initial_message ?? ''}
          onChange={(e) =>
            setAgent({ ...agent, initial_message: e.target.value })
          }
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="prompt" required>
          Prompts:
        </Label>

        <AutoCompleteSelectBox
          key={defaultPrompt?.id}
          defaultValue={
            defaultPrompt?.id
              ? {
                  value: defaultPrompt.id,
                  label: limitString(defaultPrompt.content, 50),
                }
              : undefined
          }
          fetchOptions={searchPrompts}
          onChange={(value) => {
            setAgent({ ...agent, prompt: value });
          }}
          className="w-full max-w-xs"
          variant="outlined"
          color="primary"
          size="medium"
          disabled={loading || (!agent.user_id && endpoint !== 'agents/create')}
        />
      </div>
      <div className="mb-6 col-span-2">
        <Textarea
          id="text"
          placeholder="Prompt text"
          rows={12}
          value={
            typeof agent.prompt === 'string'
              ? ''
              : (agent.prompt?.content ?? '')
          }
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setAgent({
              ...agent,
              prompt: {
                ...defaultPrompt,
                content: event.target.value,
              },
            });
          }}
        />
      </div>
    </div>
  );
};
