import { useState, useEffect } from 'react';

export const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 990,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
};

const useBreakpoint = () => {
  const [currentBreakpoints, setBreakpoints] = useState({
    isMobile: false,
    isMobileLandscape: false,
    isTablet: false,
    isTabletLandscape: false,
    isDesktop: false,
    isDesktopLarge: false,
    isDesktopWide: false,
  });

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth >= 320;
      const isMobileLandscape = window.innerWidth >= 480;
      const isTablet = window.innerWidth >= 768;
      const isTabletLandscape = window.innerWidth >= 1024;
      const isDesktop = window.innerWidth >= 1200;
      const isDesktopLarge = window.innerWidth >= 1500;
      const isDesktopWide = window.innerWidth >= 1920;

      setBreakpoints({
        isMobile,
        isMobileLandscape,
        isTablet,
        isTabletLandscape,
        isDesktop,
        isDesktopLarge,
        isDesktopWide,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return currentBreakpoints;
};

export default useBreakpoint;
