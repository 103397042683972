import { useLocalStorageState } from 'ahooks';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { FirebaseError } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearCachedToken, get, post, renewToken } from 'src/api/requests';
import { Input } from 'src/components/Input';
import { UnloggedCard } from 'src/components/UnloggedCard';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { getFirebaseErrorMessage } from 'src/utils/FirebaseErrorUtils';
import { Button } from '../components/Button';
import { Loading } from '../components/Loading';

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [, setFireBaseDisplayName] = useLocalStorageState<string | null>(
    '@control-plane/fireBaseDisplayName',
    {
      defaultValue: '',
      listenStorageChange: true,
    },
  );
  const [, setIsClosedAlphaVisible] = useLocalStorageState<boolean>(
    '@control-plane/closed-alpha-visible',
    {
      defaultValue: false,
      listenStorageChange: true,
    },
  );

  const notification = useNotification();
  const { environment, updateEnvironment } = useEnvironment();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const user = userCredential.user;

      // Check if email is verified
      if (!user?.emailVerified) {
        await sendEmailVerification(user);

        notification.error(
          'Email is not verified! Verification email has been resent.',
        );
        auth.signOut();
        setLoading(false);
        return;
      }

      setFireBaseDisplayName(user.displayName);

      if (user) {
        const [fname, lname] = user?.displayName?.split(' ') || [];

        try {
          await post(`/users`, {
            fname,
            lname,
            email,
          });
          await renewToken();
        } catch (error) {
          if (error instanceof Error) {
            if (error?.message !== 'User found in the system') {
              notification.error(error.message);
              return;
            }
          }
          const err = error as AxiosError;
          if (err.status === 403) {
            notification.error(JSON.parse(err.request.response).message);
            return;
          }
        }

        const environments = await get('/environments');
        const isStoredEnvInEnvironments = environments.find(
          (env: { id: string }) => env.id === environment?.envId,
        );

        const initialEnvId =
          environment?.envId &&
          environment.userId === user.uid &&
          isStoredEnvInEnvironments
            ? environment.envId
            : environments[0].id;

        updateEnvironment({
          userId: user.uid,
          envId: initialEnvId,
        });

        await post(
          `/users/sync`,
          {},
          {
            envId: initialEnvId,
          },
        );

        clearCachedToken();

        setLoading(true);

        // sleep 2 seconds to make sure the environment is updated
        await new Promise((resolve) => setTimeout(resolve, 2000));

        navigate('/');
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error during login');
      }
    }

    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);

      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setFireBaseDisplayName(user.displayName);

      if (user) {
        const [fname, lname] = user?.displayName?.split(' ') || [];
        const googleEmail = user.email || '';

        try {
          await post(`/users`, {
            fname,
            lname,
            email: googleEmail,
          });
          await renewToken();
        } catch (error) {
          if (error instanceof Error) {
            if (error?.message !== 'User found in the system') {
              notification.error(error.message);
              return;
            }
          }
          const err = error as AxiosError;
          if (err.status === 403) {
            notification.error(JSON.parse(err.request.response).message);
            return;
          }
        }

        const environments = await get('/environments');
        const isStoredEnvInEnvironments = environments.find(
          (env: { id: string }) => env.id === environment?.envId,
        );

        const initialEnvId =
          environment?.envId &&
          environment.userId === user.uid &&
          isStoredEnvInEnvironments
            ? environment.envId
            : environments[0].id;

        updateEnvironment({
          userId: user.uid,
          envId: initialEnvId,
        });

        await post(
          `/users/sync`,
          {},
          {
            envId: initialEnvId,
          },
        );

        clearCachedToken();

        notification.success('Login with Google successful!');

        // sleep 2 seconds to make sure the environment is updated
        await new Promise((resolve) => setTimeout(resolve, 2000));

        navigate('/');
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error during Google login');
      }
    }
  };

  useEffect(() => {
    setIsClosedAlphaVisible(false);
  }, []);

  return (
    <UnloggedCard>
      <h2 className="text-h2 font-medium mb-6">Sign in to your account</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            required
          />
        </div>

        <div className="mb-6">
          <Input
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            required
          />
        </div>

        <Button className="w-full mb-6" type="submit" disabled={loading}>
          {loading ? (
            <div className="flex gap-2 items-center justify-center">
              <Loading size={'4'} />
              <p>Loading...</p>
            </div>
          ) : (
            'Sign In'
          )}
        </Button>

        <div className="relative mb-6">
          <div className="flex items-center justify-center">
            <div className="w-full border-t border-gray-300"></div>
            <span className="absolute bg-white px-2 text-gray-600">or</span>
          </div>
        </div>

        <div className="flex flex-col">
          <button
            onClick={handleGoogleSignIn}
            disabled={loading}
            className={clsx(
              'w-full mb-6 h-10 relative flex items-center justify-center rounded border border-gray-300 bg-white hover:bg-gray-50',
              loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            )}
          >
            <div className="absolute left-4">
              <img src="/assets/google.svg" width={22} height={22} />
            </div>
            {loading ? (
              <div className="flex gap-2 items-center justify-center">
                <Loading size={'4'} />
                <p>Loading...</p>
              </div>
            ) : (
              <span className="text-sm font-medium text-gray-700">
                Sign in with Google
              </span>
            )}
          </button>

          <div className="text-center text-body mb-4">
            Don't have an account?{' '}
            <span
              className={clsx(
                'w-fulltext-center font-medium text-body cursor-pointer',
                loading
                  ? 'opacity-50 text-gray-400'
                  : 'opacity-100 text-primary',
              )}
              onClick={() => navigate('/signup')}
            >
              Sign Up
            </span>
          </div>

          <a
            className={clsx(
              'w-full mb-4 text-center font-medium text-body cursor-pointer',
              loading ? 'opacity-50 text-gray-400' : 'opacity-100 text-primary',
            )}
            onClick={() => navigate('/reset-password')}
          >
            Forgot Password
          </a>

          <span className="font-body-light text-sm text-neutral-925 text-center">
            By signing in or creating an account, you are agreeing to our{' '}
            <a
              className="text-underline underline"
              target="_blank"
              href="https://storage.googleapis.com/fluents-docs/Fluents%20-%20TOS.pdf"
              rel="noopener noreferrer"
            >
              terms of use
            </a>{' '}
            and our{' '}
            <a
              className="text-underline underline"
              target="_blank"
              href="https://fluents.ai/privacy-policy"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
          </span>
        </div>
      </form>
    </UnloggedCard>
  );
};
