import _ from 'lodash';
import { useRef, useState, useEffect } from 'react';
import {
  AutoCompleteSelectBoxControlled,
  Option,
} from './AutoCompleteSelectBoxControlled';

export type { Option };

type AutoCompleteSelectBoxProps = {
  fetchOptions?: (query: string) => Promise<Option[]>;
  defaultValue?: Option;
  variant?: 'outlined' | 'contained' | 'text';
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  direction?: 'up' | 'down';
  defaultOptions?: Option[];
};

export const AutoCompleteSelectBox = ({
  fetchOptions,
  defaultValue,
  variant = 'outlined',
  color = 'primary',
  size = 'medium',
  className = '',
  onChange,
  disabled = false,
  direction = 'down',
  defaultOptions = [],
}: AutoCompleteSelectBoxProps) => {
  const [selected, setSelected] = useState<Option | null>(defaultValue || null);
  const [options, setOptions] = useState<Option[]>(defaultOptions);

  // Handle option fetching internally
  const handleFetchOptions = async (query: string) => {
    if (!fetchOptions) {
      setOptions(
        defaultOptions.filter(
          (option) =>
            option.value.includes(query) || option.label?.includes(query),
        ),
      );
      return;
    }

    const result = await fetchOptions(query);

    const newOptions =
      query === ''
        ? _.uniqBy(
            [...(defaultValue ? [defaultValue] : []), ...result],
            'value',
          )
        : result;

    setOptions(newOptions);
  };

  // Handle local state change
  const handleChange = (value: string) => {
    const option = options.find((opt) => opt.value === value) || null;
    setSelected(option);
    onChange?.(value);
  };

  return (
    <AutoCompleteSelectBoxControlled
      fetchOptions={handleFetchOptions}
      defaultValue={defaultValue}
      value={selected}
      variant={variant}
      color={color}
      size={size}
      className={className}
      onChange={handleChange}
      disabled={disabled}
      direction={direction}
      options={options}
    />
  );
};
