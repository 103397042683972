import { Diamond, Minus, Sliders } from '@phosphor-icons/react';
import { Popover } from '@headlessui/react';
import { SchemaProperty } from './SchemaBuilder';
import { Tooltip } from './Tooltip';

interface PropertyItemProps {
  property: SchemaProperty;
  onEdit: () => void;
  onDelete: () => void;
}

export const PropertyItem = ({
  property,
  onEdit,
  onDelete,
}: PropertyItemProps) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between p-2 rounded bg-neutral-325">
        <div className="flex items-center gap-2">
          <Diamond size={20} />
          <span className="font-medium">{property.name}</span>
          <span className="ml-2 text-sm text-gray-500">{property.type}</span>
          {property.required && (
            <span className="ml-2 text-xs text-red-500">required</span>
          )}
        </div>

        <div className="flex gap-2 items-center">
          <Tooltip content="Edit property">
            <Popover.Button
              onClick={onEdit}
              className="flex items-center justify-center"
            >
              <Sliders size={16} />
            </Popover.Button>
          </Tooltip>

          <Tooltip content="Delete property">
            <button
              onClick={onDelete}
              className="flex items-center justify-center"
            >
              <Minus size={16} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
