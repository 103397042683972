import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';

interface RadioTabItem {
  label: string;
  value: string | number;
}

interface RadioTabProps {
  items: RadioTabItem[];
  value: string | number;
  onChange: (value: string | number) => void;
  className?: string;
}

export const RadioTab = ({
  items,
  value,
  onChange,
  className,
}: RadioTabProps) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className={clsx('flex gap-2 border-b border-gray-200', className)}>
        {items.map((item) => (
          <RadioGroup.Option
            key={item.label}
            value={item.value}
            className="focus:outline-none"
          >
            {({ checked }) => (
              <button
                className={clsx(
                  'py-2 font-medium transition-all duration-200 focus:outline-none border-b-2 mx-4',
                  checked
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700',
                )}
              >
                {item.label}
              </button>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
