import RecordRTC from 'recordrtc';
import { PCMPlayer } from './PCMPlayer';

const defaultSampleRate = 16000;

export class WebsocketClient {
  public readonly socket: WebSocket;
  public readonly callSid: string;
  // the recorder responsible for recording the audio stream to be prepared as the audio input
  recorder: RecordRTC | null = null;
  // the stream of audio captured from the user's microphone
  audioStream: MediaStream | null = null;
  player: PCMPlayer | null = null;
  setAudioLevel?: (audioLevel: number) => void;

  constructor({
    socket,
    callSid,
    setAudioLevel = () => {},
  }: {
    socket: WebSocket;
    callSid: string;
    setAudioLevel?: (audioLevel: number) => void;
  }) {
    this.socket = socket;
    this.callSid = callSid;
    this.setAudioLevel = setAudioLevel;

    this.socket.addEventListener('open', this.handleOpen);
    this.socket.addEventListener('message', this.handleMessage);
    this.socket.addEventListener('close', this.handleClose);
    this.socket.addEventListener('error', this.handleError);
    this.socket.binaryType = 'arraybuffer';
  }

  close = () => {
    if (this.recorder) {
      this.recorder.stopRecording();
      this.recorder.destroy();
      this.recorder = null;
    }
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
    }
    this.socket.close();

    this.socket.removeEventListener('open', this.handleOpen);
    this.socket.removeEventListener('message', this.handleMessage);
    this.socket.removeEventListener('close', this.handleClose);
    this.socket.removeEventListener('error', this.handleError);
  };

  // function for stopping the audio and clearing the queue
  stopAudio = () => {
    if (!this.player) {
      return;
    }
    this.player.stop();
  };

  handleClose = () => {
    if (!this.player) {
      return;
    }
    this.stopAudio();
    this.player.destroy();
  };

  handleError = () => {
    this.handleClose();
  };

  handleOpen = async () => {
    this.player = new PCMPlayer({
      encoding: '16bitInt',
      channels: 1,
      sampleRate: defaultSampleRate,
      flushingTime: 100,
      bufferSize: 1024,
    });

    const connectedMessage = {
      event: 'connected',
      protocol: 'Call',
      version: '1.0.0',
    };

    const startMessage = {
      event: 'start',
      sequenceNumber: '1',
      start: {
        accountSid: `web-account-${this.callSid}`,
        streamSid: `web-stream-${this.callSid}`,
        callSid: this.callSid,
        tracks: ['inbound'],
        mediaFormat: {
          encoding: 'audio/x-mulaw',
          sampleRate: defaultSampleRate,
          channels: 1,
        },
      },
      streamSid: `web-stream-${this.callSid}`,
    };
    await this.socket?.send(JSON.stringify(connectedMessage));
    await this.socket?.send(JSON.stringify(startMessage));
    await this.captureAudio();
  };

  processMedia = (data: string) => {
    if (!this.player) {
      return;
    }
    const binaryString = atob(data);
    const samples = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      samples[i] = binaryString.charCodeAt(i);
    }

    this.player.feed(samples);

    // Calculate audio volume
    const volume = this.calculateVolume(samples);
    this.setAudioLevel?.(volume);
  };

  calculateVolume = (samples: Uint8Array) => {
    let sum = 0;
    for (let i = 0; i < samples.length; i++) {
      sum += samples[i] * samples[i];
    }
    const rms = Math.sqrt(sum / samples.length);
    return Math.min(rms / 32768, 1); // Normalize volume between 0 and 1
  };

  handleMessage = async (message: { data: string }) => {
    const eventJson = JSON.parse(message.data);
    switch (eventJson.event) {
      // add received audio to the playback queue, and play next audio output
      case 'media':
        this.processMedia(eventJson.data);
        break;

      // stop audio playback, clear audio playback queue, and update audio playback state on interrupt
      case 'user_interruption':
        this.stopAudio();
        break;
    }
  };

  /////////
  // Input
  /////////
  captureAudio = async () => {
    // prompts user for permission to capture audio, obtains media stream upon approval
    this.audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      },
      video: false,
    });

    // instantiate the media recorder
    // configuration is very important in order to
    // send the correct audio encoding, sample size, and chunks
    this.recorder = new RecordRTC(this.audioStream, {
      type: 'audio',
      recorderType: RecordRTC.StereoAudioRecorder,
      mimeType: 'audio/wav',
      timeSlice: 10,
      desiredSampRate: defaultSampleRate,
      numberOfAudioChannels: 1,
      bufferSize: 256,
      ondataavailable: (blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const wavHeader = 'data:audio/wav;base64,';
          const base64data = `${reader.result}`.split(wavHeader)[1];

          if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(
              JSON.stringify({
                event: 'media',
                media: {
                  payload: base64data,
                },
              }),
            );
          }
        };
        reader.readAsDataURL(blob);
      },
    });
    if (this.recorder) {
      this.recorder.startRecording();
    }
  };
}
