export const settings = {
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,
};

export const PROMPT_TAGS = ['objective', 'guideline', 'context', 'example'];

export const VOICE_NAMES: {
  [key: string]: { label: string; value: string }[];
} = {
  voice_azure: [
    { label: 'Aria', value: 'en-US-AriaNeural' },
    { label: 'Ava', value: 'en-US-AvaNeural' },
    { label: 'Brian', value: 'en-US-BrianNeural' },
    { label: 'Christopher', value: 'en-US-ChristopherNeural' },
    { label: 'Elizabeth', value: 'en-US-ElizabethNeural' },
    { label: 'Emma', value: 'en-US-EmmaNeural' },
    { label: 'Eric', value: 'en-US-EricNeural' },
    { label: 'Jacob', value: 'en-US-JacobNeural' },
    { label: 'Jason', value: 'en-US-JasonNeural' },
    { label: 'Jenny', value: 'en-US-JennyNeural' },
    { label: 'Michelle', value: 'en-US-MichelleNeural' },
    { label: 'Monica', value: 'en-US-MonicaNeural' },
    { label: 'Nancy', value: 'en-US-NancyNeural' },
    { label: 'Sara', value: 'en-US-SaraNeural' },
    { label: 'Steffan', value: 'en-US-SteffanNeural' },
  ],
  voice_eleven_labs: [
    { label: 'Alice', value: 'Xb7hH8MSUJpSbSDYk0k2' },
    { label: 'Aria', value: '9BWtsMINqrJLrRacOk9x' },
    { label: 'Bill', value: 'pqHfZKP75CvOlQylNhV4' },
    { label: 'Brian', value: 'nPczCjzI2devNBz1zQrb' },
    { label: 'Callum', value: 'N2lVS1w4EtoT3dr4eOWO' },
    { label: 'Charlie', value: 'IKne3meq5aSn9XLyUdCD' },
    { label: 'Charlotte', value: 'XB0fDUnXU5powFXDhCwa' },
    { label: 'Chris', value: 'iP95p4xoKVk53GoZ742B' },
    { label: 'Daniel', value: 'onwK4e9ZLuTAKqWW03F9' },
    { label: 'Eric', value: 'cjVigY5qzO86Huf0OWal' },
    { label: 'George', value: 'JBFqnCBsd6RMkjVDRZzb' },
    { label: 'Jessica', value: 'cgSgspJ2msm6clMCkdW9' },
    { label: 'Laura', value: 'FGY2WhTYpPnrIDTdsKH5' },
    { label: 'Liam', value: 'TX3LPaxmHKxFdv7VOQHJ' },
    { label: 'Lily', value: 'pFZP5JQG7iQjIQuC4Bku' },
    { label: 'Matilda', value: 'XrExE9yKIg1WjnnlVkGX' },
    { label: 'River', value: 'SAz9YHcvj6GT2YYXdXww' },
    { label: 'Roger', value: 'CwhRBWXzGAHq8TQ4Fs17' },
    { label: 'Sarah', value: 'EXAVITQu4vr4xnSDxMaL' },
    { label: 'Will', value: 'bIHbv24MWmeRgasZH58o' },
  ],
  voice_play_ht: [
    {
      label: 'Adolfo',
      value:
        's3://voice-cloning-zero-shot/d82d246c-148b-457f-9668-37b789520891/adolfosaad/manifest.json',
    },
    {
      label: 'Charlotte (Narrative)',
      value:
        's3://voice-cloning-zero-shot/a59cb96d-bba8-4e24-81f2-e60b888a0275/charlottenarrativesaad/manifest.json',
    },
    {
      label: 'Dylan',
      value:
        's3://voice-cloning-zero-shot/3a831d1f-2183-49de-b6d8-33f16b2e9867/dylansaad/manifest.json',
    },
    {
      label: 'Susan (Advertising)',
      value:
        's3://voice-cloning-zero-shot/f6594c50-e59b-492c-bac2-047d57f8bdd8/susanadvertisingsaad/manifest.json',
    },
    {
      label: 'Navya',
      value:
        's3://voice-cloning-zero-shot/e5df2eb3-5153-40fa-9f6e-6e27bbb7a38e/original/manifest.json',
    },
    {
      label: 'Benton',
      value:
        's3://voice-cloning-zero-shot/b41d1a8c-2c99-4403-8262-5808bc67c3e0/bentonsaad/manifest.json',
    },
    {
      label: 'Olivia (Advertising)',
      value:
        's3://voice-cloning-zero-shot/9fc626dc-f6df-4f47-a112-39461e8066aa/oliviaadvertisingsaad/manifest.json',
    },
    {
      label: 'Delilah',
      value:
        's3://voice-cloning-zero-shot/1afba232-fae0-4b69-9675-7f1aac69349f/delilahsaad/manifest.json',
    },
    {
      label: 'Charles',
      value:
        's3://voice-cloning-zero-shot/9f1ee23a-9108-4538-90be-8e62efc195b6/charlessaad/manifest.json',
    },
    {
      label: 'Madison',
      value:
        's3://voice-cloning-zero-shot/473c81b1-93ea-4662-9e63-7d65392e5f9b/madisonsaad/manifest.json',
    },
    {
      label: 'Frederick',
      value:
        's3://voice-cloning-zero-shot/2879ab87-3775-4992-a228-7e4f551658c2/fredericksaad2/manifest.json',
    },
    {
      label: 'Mason',
      value:
        's3://voice-cloning-zero-shot/a540a448-a9ca-446c-9538-d1bae6c506f1/original/manifest.json',
    },
    {
      label: 'Micah',
      value:
        's3://voice-cloning-zero-shot/a5cc7dd9-069c-4fe8-9ae7-0c4bae4779c5/micahsaad/manifest.json',
    },
    {
      label: 'Adrian',
      value:
        's3://voice-cloning-zero-shot/d99d35e6-e625-4fa4-925a-d65172d358e1/adriansaad/manifest.json',
    },
    {
      label: 'Indigo',
      value:
        's3://voice-cloning-zero-shot/97580643-b568-4198-aaa4-3e07e4a06c47/original/manifest.json',
    },
    {
      label: 'Samuel',
      value:
        's3://voice-cloning-zero-shot/36e9c53d-ca4e-4815-b5ed-9732be3839b4/samuelsaad/manifest.json',
    },
    {
      label: 'Calvin',
      value:
        's3://voice-cloning-zero-shot/743575eb-efdc-4c10-b185-a5018148822f/original/manifest.json',
    },
    {
      label: 'Dick',
      value:
        's3://voice-cloning-zero-shot/7b97b543-7877-41b6-86ee-aa1e0b6c110e/dicksaad/manifest.json',
    },
    {
      label: 'Amelia',
      value:
        's3://voice-cloning-zero-shot/34eaa933-62cb-4e32-adb8-c1723ef85097/original/manifest.json',
    },
    {
      label: 'Sarge',
      value:
        's3://voice-cloning-zero-shot/cebaa3cf-d1d5-4625-ba20-03dcca3b379f/sargesaad/manifest.json',
    },
    {
      label: 'William (Narrative)',
      value:
        's3://voice-cloning-zero-shot/688d0200-7415-42b4-8726-e2f5693aaac8/williamnarrativesaad/manifest.json',
    },
    {
      label: 'April',
      value:
        's3://voice-cloning-zero-shot/9a5deeda-3025-49c5-831a-ac98f86f2a37/aprilsaad/manifest.json',
    },
    {
      label: 'Axel',
      value:
        's3://voice-cloning-zero-shot/def0cd31-1b8c-486e-82d2-050a0ea2965d/axelsaad/manifest.json',
    },
    {
      label: 'Eleanor',
      value:
        's3://voice-cloning-zero-shot/d712cad5-85db-44c6-8ee0-8f4361ed537b/eleanorsaad2/manifest.json',
    },
    {
      label: 'Dudley',
      value:
        's3://voice-cloning-zero-shot/9b559f3c-22e7-4b10-8158-3e11b333751e/dudleysaad/manifest.json',
    },
    {
      label: 'Phoebe',
      value:
        's3://voice-cloning-zero-shot/0c4c229f-7f99-4ed9-b904-223c701672b9/phoebesaad/manifest.json',
    },
    {
      label: 'Autumn',
      value:
        's3://voice-cloning-zero-shot/ff414883-0e32-4a92-a688-d7875922120d/original/manifest.json',
    },
    {
      label: 'Isabella',
      value:
        's3://voice-cloning-zero-shot/a0fa25cc-5f42-4dd0-8a78-a950dd5297cd/original/manifest.json',
    },
    {
      label: 'Arthur (Advertising)',
      value:
        's3://voice-cloning-zero-shot/509221d8-9e2d-486c-9b3c-97e52d86e63d/arthuradvertisingsaad/manifest.json',
    },
    {
      label: 'Billy',
      value:
        's3://voice-cloning-zero-shot/37e5af8b-800a-4a76-8f31-4203315f8a9e/billysaad/manifest.json',
    },
    {
      label: 'Clifton',
      value:
        's3://voice-cloning-zero-shot/7447d346-20d5-4939-b43c-bdf17e757e98/cliftonsaad/manifest.json',
    },
    {
      label: 'Nova',
      value:
        's3://voice-cloning-zero-shot/2a7ddfc5-d16a-423a-9441-5b13290998b8/novasaad/manifest.json',
    },
    {
      label: 'Richie',
      value:
        's3://voice-cloning-zero-shot/dc90b58b-59a9-4e65-955d-c7620deb2d7a/original/manifest.json',
    },
    {
      label: 'Susan (Training)',
      value:
        's3://voice-cloning-zero-shot/7142f297-c55f-451d-8863-108d5101b5ed/susantrainingsaad/manifest.json',
    },
    {
      label: 'Julian',
      value:
        's3://voice-cloning-zero-shot/fd35d956-b644-4513-9cd0-7aa6931dfd43/juliansaad/manifest.json',
    },
    {
      label: 'Lachlan',
      value:
        's3://voice-cloning-zero-shot/bb759cd0-edb0-43d9-8273-f0a7c048fb11/original/manifest.json',
    },
    {
      label: 'Atlas',
      value:
        's3://voice-cloning-zero-shot/e46b4027-b38d-4d24-b292-38fbca2be0ef/original/manifest.json',
    },
    {
      label: 'Finley',
      value:
        's3://voice-cloning-zero-shot/aa753d26-bc20-479f-95af-5c3c1c970d93/original/manifest.json',
    },
    {
      label: 'Pia',
      value:
        's3://voice-cloning-zero-shot/74f59ea4-f07b-4d10-88d1-ca174adac3f3/original/manifest.json',
    },
    {
      label: 'Abigail',
      value:
        's3://voice-cloning-zero-shot/80ba8839-a6e6-470c-8f68-7c1e5d3ee2ff/abigailsaad/manifest.json',
    },
    {
      label: 'Carter',
      value:
        's3://voice-cloning-zero-shot/a72a5d4c-d729-4045-8bc8-1786043a4537/cartersaad/manifest.json',
    },
    {
      label: 'Nigel',
      value:
        's3://voice-cloning-zero-shot/f8af54e1-1534-4f68-ad8a-260cc132f820/original/manifest.json',
    },
    {
      label: 'Arthur',
      value:
        's3://voice-cloning-zero-shot/8ca8c44d-fb2b-4269-abca-4e6748a4a61e/arthursaad/manifest.json',
    },
    {
      label: 'Madelyn',
      value:
        's3://voice-cloning-zero-shot/0a11e4f0-1656-48c9-bc4c-affcd718c414/madelynsaad/manifest.json',
    },
    {
      label: 'Hunter',
      value:
        's3://voice-cloning-zero-shot/087f79be-bb52-436a-93f3-cee27cd3b80d/huntersaad/manifest.json',
    },
    {
      label: 'Aaliyah',
      value:
        's3://voice-cloning-zero-shot/f6c4ed76-1b55-4cd9-8896-31f7535f6cdb/original/manifest.json',
    },
    {
      label: 'Aurora',
      value:
        's3://voice-cloning-zero-shot/5b81dc4c-bf98-469d-96b4-8f09836fb500/aurorasaad/manifest.json',
    },
    {
      label: 'Arthur (Meditation)',
      value:
        's3://voice-cloning-zero-shot/38a41ac2-f574-421c-adb9-ce1bcb6f4a84/arthurmeditationsaad/manifest.json',
    },
    {
      label: 'Darrell',
      value:
        's3://voice-cloning-zero-shot/f669de94-fea6-4af3-a8ea-193b75bb142b/darrellsaad/manifest.json',
    },
    {
      label: 'Oliver',
      value:
        's3://voice-cloning-zero-shot/6c9c01b7-8d38-47ae-8ce5-18a360b26cf3/oliversaad/manifest.json',
    },
    {
      label: 'Eileen',
      value:
        's3://voice-cloning-zero-shot/b709b944-9256-4578-b9d8-a1ce4d729022/eileensaad/manifest.json',
    },
    {
      label: 'Nolan',
      value:
        's3://voice-cloning-zero-shot/e7e9514f-5ffc-4699-a958-3627151559d9/nolansaad2/manifest.json',
    },
    {
      label: 'Wilbert',
      value:
        's3://voice-cloning-zero-shot/5cd30986-f7f7-444b-ad82-d08ceb24ec5f/wilbertsaad/manifest.json',
    },
    {
      label: 'Leroy',
      value:
        's3://voice-cloning-zero-shot/32ae7ca0-634e-4fab-af74-0ec7c663e9da/original/manifest.json',
    },
    {
      label: 'William (Training)',
      value:
        's3://voice-cloning-zero-shot/3b7df114-e269-453a-8dc2-cbbce4c48f48/williamtrainingsaad/manifest.json',
    },
    {
      label: 'Larry (Narrative)',
      value:
        's3://voice-cloning-zero-shot/92519089-3c97-4f31-8743-ab4f06047b88/larrysaad/manifest.json',
    },
    {
      label: 'Audrey',
      value:
        's3://voice-cloning-zero-shot/8ceaa707-4141-46c0-a610-4a2014d7ea97/audreysaad/manifest.json',
    },
    {
      label: 'Ada',
      value:
        's3://voice-cloning-zero-shot/72aafde0-8f4f-4a91-a483-21d76114ab17/original/manifest.json',
    },
    {
      label: 'Mitch',
      value:
        's3://voice-cloning-zero-shot/8007f637-9aec-4426-8c6a-7e713c032623/original/manifest.json',
    },
    {
      label: 'Darnell',
      value:
        's3://voice-cloning-zero-shot/2d5053ed-635a-499a-aa66-e85aeaf46d3e/darnellsaad/manifest.json',
    },
    {
      label: 'Ayla (Narrative)',
      value:
        's3://voice-cloning-zero-shot/483ed913-2817-45cb-b1a2-b79d9bfb2cf8/aylanarrativesaad/manifest.json',
    },
    {
      label: 'Lumi',
      value:
        's3://voice-cloning-zero-shot/640a6636-dc16-4911-b75a-1549daae2c71/original/manifest.json',
    },
    {
      label: 'Hook',
      value:
        's3://voice-cloning-zero-shot/261923bd-a10a-4a90-bced-0ce2b0230398/hooksaad/manifest.json',
    },
    {
      label: 'Luna',
      value:
        's3://voice-cloning-zero-shot/f43cc4b4-b193-4a13-a903-e6b125c3d572/original/manifest.json',
    },
    {
      label: 'Clark',
      value:
        's3://voice-cloning-zero-shot/a2852064-4b50-41a8-82f4-0ef0aae4601c/clarksaad/manifest.json',
    },
    {
      label: 'Bryan',
      value:
        's3://voice-cloning-zero-shot/4c627545-b9c0-4791-ae8e-f48f5475247c/bryansaad/manifest.json',
    },
    {
      label: 'Larry',
      value:
        's3://voice-cloning-zero-shot/75085d4f-839d-4ca5-a3e2-e329f04a591e/larrynarrativesaad/manifest.json',
    },
    {
      label: 'Samara',
      value:
        's3://voice-cloning-zero-shot/90217770-a480-4a91-b1ea-df00f4d4c29d/original/manifest.json',
    },
    {
      label: 'Baptiste',
      value:
        's3://voice-cloning-zero-shot/1d26f4fe-1d08-4cfe-a7c1-d28e4e913ff9/original/manifest.json',
    },
    {
      label: 'Donovan',
      value:
        's3://voice-cloning-zero-shot/07dc6825-4fde-4430-8c86-54ead89e0c88/donovansaad2/manifest.json',
    },
    {
      label: 'Niamh',
      value:
        's3://voice-cloning-zero-shot/928ed0a0-2271-4710-a7c9-1711d36b9897/original/manifest.json',
    },
    {
      label: 'Alex',
      value:
        's3://voice-cloning-zero-shot/72c255f8-00af-4c48-b0de-ad471baa3f52/alexsaad/manifest.json',
    },
    {
      label: 'Furio',
      value:
        's3://voice-cloning-zero-shot/44f32760-8f41-4dfb-b192-ca077fc501ea/original/manifest.json',
    },
    {
      label: 'Alessandro',
      value:
        's3://voice-cloning-zero-shot/7ced805f-611e-433c-8c43-568f48a8af4e/original/manifest.json',
    },
    {
      label: 'Archie',
      value:
        's3://voice-cloning-zero-shot/ac9e2984-c7bb-44c8-8b6b-5c10728ad5cf/original/manifest.json',
    },
    {
      label: 'Waylon',
      value:
        's3://voice-cloning-zero-shot/b4a876c1-8730-435e-9595-141799868808/original/manifest.json',
    },
    {
      label: 'Scarlett',
      value:
        's3://voice-cloning-zero-shot/32b943f6-87cf-4e15-8e7a-d4cb848e3689/original/manifest.json',
    },
    {
      label: 'Jordan',
      value:
        's3://voice-cloning-zero-shot/0ed97923-dd04-449f-a497-6f1109fdd9cc/jordansaad/manifest.json',
    },
    {
      label: 'Teddy',
      value:
        's3://voice-cloning-zero-shot/f3600d9d-39ab-4494-b6ac-c6f5d122184d/original/manifest.json',
    },
    {
      label: 'Abigail',
      value:
        's3://mockingbird-prod/abigail_vo_6661b91f-4012-44e3-ad12-589fbdee9948/voices/speaker/manifest.json',
    },
    { label: 'Abram', value: 'abram' },
    { label: 'Adolfo', value: 'adolfo' },
    { label: 'Adrian', value: 'adrian' },
    {
      label: 'William',
      value:
        's3://voice-cloning-zero-shot/c00f7eb4-fbbd-4a7d-a4c5-ff57e92faf99/williamsaad3/manifest.json',
    },
    { label: 'Alex', value: 'alex' },
    { label: 'Alexander', value: 'alexander' },
    { label: 'Alfonso', value: 'alfonso' },
    { label: 'Alphonso', value: 'alphonso' },
    { label: 'Amado', value: 'amado' },
    { label: 'Anny', value: 'anny' },
    { label: 'Anthony', value: 'anthony' },
    { label: 'April', value: 'spencer' },
    { label: 'Ariana', value: 'victor' },
    { label: 'Arthur', value: 'arthur' },
    {
      label: 'Arthur (Advertising)',
      value: 's3://peregrine-voices/arthur ads parrot saad/manifest.json',
    },
    {
      label: 'Arthur (Meditation)',
      value:
        's3://mockingbird-prod/arthur_vo_meditatoin_211f702d-b185-4115-b8b4-801f8130a38d/voices/speaker/manifest.json',
    },
    {
      label: 'Arthur (Narrative)',
      value:
        's3://mockingbird-prod/arthur_vo_narrative_a33fd610-73a9-4401-9a78-6b8219c68a9e/voices/speaker/manifest.json',
    },
    {
      label: 'Arthur (Training)',
      value:
        's3://mockingbird-prod/arthur_vo_training_9281c8fd-c7f0-4445-a148-466292d3d329/voices/speaker/manifest.json',
    },
    { label: 'Aubrey', value: 'aubrey' },
    { label: 'Audrey', value: 'hipolito' },
    { label: 'Aurora', value: 'aurora' },
    { label: 'Axel', value: 'axel' },
    {
      label: 'Ayla (Advertising)',
      value:
        's3://mockingbird-prod/ayla_vo_commercials_d66900d5-69f5-476f-9bd6-8eab2936dda3/voices/speaker/manifest.json',
    },
    {
      label: 'Ayla (Expressive)',
      value:
        's3://mockingbird-prod/ayla_vo_expressive_16095e08-b9e8-429b-947c-47a75e41053b/voices/speaker/manifest.json',
    },
    {
      label: 'Ayla (Meditation)',
      value:
        's3://mockingbird-prod/ayla_vo_meditation_d11dd9da-b5f1-4709-95a6-e6d5dc77614a/voices/speaker/manifest.json',
    },
    {
      label: 'Ayla (Narrative)',
      value:
        's3://mockingbird-prod/ayla_vo_narrative_d8199dfd-b50f-40c7-9d99-e203ba5f4152/voices/speaker/manifest.json',
    },
    {
      label: 'Ayla (Training)',
      value:
        's3://mockingbird-prod/ayla_vo_training_e6751ca5-e47c-4c4b-ad05-d3a194417600/voices/speaker/manifest.json',
    },
    {
      label: 'Erasmo',
      value:
        's3://voice-cloning-zero-shot/285900ed-b758-4abb-a4ef-e7295741d97d/erasmosaad/manifest.json',
    },
    {
      label: 'Daniel',
      value:
        's3://voice-cloning-zero-shot/81111918-bf87-484b-9ffa-7be344c62223/danielsaad/manifest.json',
    },
    {
      label: 'Earle',
      value:
        's3://voice-cloning-zero-shot/f72dbbd1-1f99-4f5b-b0d0-de06f8854d05/earlesaad/manifest.json',
    },
    {
      label: 'Flynn',
      value:
        's3://voice-cloning-zero-shot/0b29eab5-834f-4463-b3ad-4e6177d2b145/flynnsaad/manifest.json',
    },
    {
      label: 'Carmen',
      value:
        's3://voice-cloning-zero-shot/fdb74aec-ede9-45f8-ad87-71cb45f01816/original/manifest.json',
    },
    {
      label: 'Anthony',
      value:
        's3://voice-cloning-zero-shot/b3def996-302e-486f-a234-172fa0279f0e/anthonysaad/manifest.json',
    },
    {
      label: 'Barry (Advertising)',
      value: 's3://peregrine-voices/barry ads parrot saad/manifest.json',
    },
    {
      label: 'Barry (Narrative)',
      value: 's3://peregrine-voices/barry narrative parrot saad/manifest.json',
    },
    {
      label: 'Logan',
      value:
        's3://voice-cloning-zero-shot/7bad42d5-52be-4687-9a07-7891f31daa6b/logansaad/manifest.json',
    },
    { label: 'Benton', value: 'benton' },
    { label: 'Bertram', value: 'bertram' },
    {
      label: 'Charlotte',
      value:
        's3://voice-cloning-zero-shot/b2f5441d-354f-4c2f-8f32-390aaaabf42d/charlottesaad/manifest.json',
    },
    {
      label: 'Billy',
      value:
        's3://mockingbird-prod/nathan_drake_carmelo_pampillonio_7d540ad6-7d32-41f6-8d53-2584901aa03d/voices/speaker/manifest.json',
    },
    { label: 'Blaine', value: 'blaine' },
    { label: 'Booker', value: 'booker' },
    { label: 'Bret', value: 'bret' },
    { label: 'Bruce', value: 'bruce' },
    { label: 'Bryan', value: 'bryan' },
    {
      label: 'Lance',
      value:
        's3://voice-cloning-zero-shot/7fdf51ea-c162-4e6e-876e-0473a0255bb8/lancesaad/manifest.json',
    },
    {
      label: 'Wilbur',
      value:
        's3://voice-cloning-zero-shot/8218bea1-aad9-49cc-95b3-e9234e28d4a6/wilbursaad/manifest.json',
    },
    {
      label: 'Theodore',
      value:
        's3://voice-cloning-zero-shot/2cbffa49-5dfe-4378-a54f-b824f7bbb032/theodoresaad/manifest.json',
    },
    {
      label: 'Ariana',
      value:
        's3://voice-cloning-zero-shot/f2863f63-5334-4f65-9d30-438feb79c2ec/arianasaad2/manifest.json',
    },
    { label: 'Carlo', value: 'carlo' },
    { label: 'Carter', value: 'carter' },
    {
      label: 'Frankie',
      value:
        's3://voice-cloning-zero-shot/64ea0c61-25f7-42af-928f-83f8915692e5/frankiesaad/manifest.json',
    },
    {
      label: 'Ayla (Advertising)',
      value:
        's3://voice-cloning-zero-shot/33e6b76a-7554-48fa-9798-2e6823ab0a10/aylaadvertisingsaad/manifest.json',
    },
    {
      label: 'Susan (Narrative)',
      value:
        's3://voice-cloning-zero-shot/3b74b785-e06e-4a53-a9c4-ebed08a70ef2/susannarrativesaad/manifest.json',
    },
    { label: 'Charles', value: 'charles' },
    { label: 'Charlotte', value: 'charlotte' },
    {
      label: 'Charlotte (Advertising)',
      value: 's3://peregrine-voices/charlotte ads parrot saad/manifest.json',
    },
    {
      label: 'Charlotte (Meditation)',
      value:
        's3://peregrine-voices/charlotte meditation 2 parrot saad/manifest.json',
    },
    {
      label: 'Charlotte (Narrative)',
      value:
        's3://mockingbird-prod/charlotte_vo_narrative_9290be17-ccea-4700-a7fd-a8fe5c49fb20/voices/speaker/manifest.json',
    },
    {
      label: 'Charlotte (Training)',
      value:
        's3://peregrine-voices/charlotte_training_parrot_saad/manifest.json',
    },
    {
      label: 'Chris',
      value:
        's3://voice-cloning-zero-shot/028a32d4-6a79-4ca3-a303-d6559843114b/chris/manifest.json',
    },
    { label: 'Chuck', value: 'chuck' },
    { label: 'Clark', value: 'clark' },
    { label: 'Clifton', value: 'clifton' },
    { label: 'Cooper', value: 'hayden' },
    {
      label: 'Harris',
      value:
        's3://voice-cloning-zero-shot/3a637c59-4f22-4ad1-bf73-f657dfbb1978/harrissaad/manifest.json',
    },
    {
      label: 'Adelaide',
      value:
        's3://voice-cloning-zero-shot/f9bf96ae-19ef-491f-ae69-644448800566/original/manifest.json',
    },
    {
      label: 'Susan',
      value:
        's3://voice-cloning-zero-shot/65e66dc8-9273-43c8-889a-83e858bf2bb8/susansaad/manifest.json',
    },
    {
      label: 'Olivia (Training)',
      value:
        's3://voice-cloning-zero-shot/9b390a6b-7048-4dc4-a714-76ea2c3a6eab/oliviatrainingsaad/manifest.json',
    },
    {
      label: 'Chuck',
      value:
        's3://voice-cloning-zero-shot/40738a3a-34bb-4ac3-97c5-aed7b31ccf1d/chucksaad/manifest.json',
    },
    { label: 'Daisy', value: 'daisy' },
    { label: 'Dane', value: 'dane' },
    { label: 'Daniel', value: 'daniel' },
    { label: 'Darnell', value: 'darnell' },
    { label: 'Daron', value: 'daron' },
    { label: 'Darrell', value: 'darrell' },
    { label: 'Davis', value: 's3://peregrine-voices/a10/manifest.json' },
    {
      label: 'Ayla (Meditation)',
      value:
        's3://voice-cloning-zero-shot/f741f871-63ad-4207-8278-907aec4e9e50/aylameditationsaad/manifest.json',
    },
    { label: 'Delilah', value: 'ignacio' },
    {
      label: 'Sumita',
      value:
        's3://voice-cloning-zero-shot/f3c22a65-87e8-441f-aea5-10a1c201e522/original/manifest.json',
    },
    { label: 'Dick', value: 'dick' },
    { label: 'Domenic', value: 'domenic' },
    {
      label: 'Donna (Meditation)',
      value: 's3://peregrine-voices/donna_meditation_saad/manifest.json',
    },
    {
      label: 'Donna (Narrative)',
      value: 's3://peregrine-voices/donna_parrot_saad/manifest.json',
    },
    { label: 'Donovan', value: 'donovan' },
    { label: 'Dudley', value: 'dudley' },
    { label: 'Dylan', value: 'dylan' },
    {
      label: 'Owen',
      value:
        's3://voice-cloning-zero-shot/b2da9576-07a4-4e1d-b71f-684f1657516f/owensaad/manifest.json',
    },
    {
      label: 'Siobhán',
      value:
        's3://voice-cloning-zero-shot/30884451-1eff-4fd8-9a24-d1ee3353b215/original/manifest.json',
    },
    {
      label: 'Alfonso',
      value:
        's3://voice-cloning-zero-shot/5d243997-dcf6-4895-b504-eb5ce95a043e/alphonsosaad/manifest.json',
    },
    { label: 'Earle', value: 'earle' },
    { label: 'Efren', value: 'efren' },
    {
      label: 'Eileen',
      value:
        's3://mockingbird-prod/eileen_vo_5d7b2bcc-d635-4301-97e8-d97c13768514/voices/speaker/manifest.json',
    },
    { label: 'Eleanor', value: 'denis' },
    { label: 'Elijah', value: 'elijah' },
    { label: 'Ellie', value: 'ellie' },
    { label: 'Erasmo', value: 'erasmo' },
    {
      label: 'Evelyn',
      value: 's3://peregrine-voices/evelyn 2 saad parrot/manifest.json',
    },
    {
      label: 'Samantha',
      value:
        's3://voice-cloning-zero-shot/0b423fde-02e2-4bea-91ce-c932162e4428/samanthasaad/manifest.json',
    },
    {
      label: 'Arthur (Narrative)',
      value:
        's3://voice-cloning-zero-shot/0326e8a4-9001-4d92-853b-3a14dd2ea38a/arthurnarrativesaad/manifest.json',
    },
    {
      label: 'Arthur (Training)',
      value:
        's3://voice-cloning-zero-shot/4bcdf603-fc5f-4040-a6dd-f8d0446bca9d/arthurtrainingsaad/manifest.json',
    },
    {
      label: 'Ranger',
      value:
        's3://voice-cloning-zero-shot/abc2d0e6-9433-4dcc-b416-0b035169f37e/original/manifest.json',
    },
    {
      label: 'Oliver (Training)',
      value:
        's3://voice-cloning-zero-shot/d0d2afef-93f5-43c3-85c7-e6d824da6dad/olivertrainingsaad/manifest.json',
    },
    { label: 'Fletcher', value: 'fletcher' },
    { label: 'Flynn', value: 'flynn' },
    { label: 'Frankie', value: 'frankie' },
    { label: 'Frederick', value: 'frederick' },
    {
      label: 'George',
      value:
        's3://voice-cloning-zero-shot/418a94fa-2395-4487-81d8-22daf107781f/george/manifest.json',
    },
    { label: 'Greg', value: 'greg' },
    { label: 'Harold', value: 'harold' },
    { label: 'Harper', value: 'bill' },
    { label: 'Harris', value: 'harris' },
    { label: 'Harrison', value: 'harrison' },
    {
      label: 'Hook',
      value:
        's3://mockingbird-prod/hook_1_chico_a3e5e83f-08ae-4a9f-825c-7e48d32d2fd8/voices/speaker/manifest.json',
    },
    {
      label: 'Hudson',
      value: 's3://peregrine-voices/hudson saad parrot/manifest.json',
    },
    { label: 'Hunter', value: 'hunter' },
    { label: 'Jack', value: 's3://peregrine-voices/mel28/manifest.json' },
    { label: 'Jarrett', value: 'jarrett' },
    {
      label: 'Jennifer',
      value:
        's3://voice-cloning-zero-shot/801a663f-efd0-4254-98d0-5c175514c3e8/jennifer/manifest.json',
    },
    { label: 'Jerrell', value: 'jerrell' },
    { label: 'Jordan', value: 'jordan' },
    {
      label: 'Joseph',
      value:
        's3://voice-cloning-zero-shot/dc23bb38-f568-4323-b6fb-7d64f685b97a/joseph/manifest.json',
    },
    { label: 'Judson', value: 'judson' },
    { label: 'Julian', value: 'julian' },
    { label: 'Lance', value: 'lance' },
    { label: 'Larry', value: 'larry' },
    {
      label: 'Larry (Advertising)',
      value: 's3://peregrine-voices/larry_ads3_parrot_saad/manifest.json',
    },
    {
      label: 'Larry (Narrative)',
      value:
        's3://mockingbird-prod/larry_vo_narrative_4bd5c1bd-f662-4a38-b5b9-76563f7b92ec/voices/speaker/manifest.json',
    },
    { label: 'Lillian', value: 'lillian' },
    { label: 'Logan', value: 'ahmed' },
    { label: 'Lottie', value: 'lottie' },
    { label: 'Lucius', value: 'lucius' },
    { label: 'Madelyn', value: 'mickey' },
    { label: 'Madison', value: 'florencio' },
    {
      label: 'Mark',
      value:
        's3://voice-cloning-zero-shot/0b5b2e4b-5103-425e-8aa0-510dd35226e2/mark/manifest.json',
    },
    {
      label: 'Matt',
      value:
        's3://voice-cloning-zero-shot/09b5c0cc-a8f4-4450-aaab-3657b9965d0b/podcaster/manifest.json',
    },
    { label: 'Melissa', value: 's3://peregrine-voices/mel21/manifest.json' },
    { label: 'Micah', value: 'micah' },
    {
      label: 'Michael',
      value:
        's3://voice-cloning-zero-shot/7c339a9d-370f-4643-adf5-4134e3ec9886/mlae02/manifest.json',
    },
    {
      label: 'Nicole',
      value:
        's3://voice-cloning-zero-shot/7c38b588-14e8-42b9-bacd-e03d1d673c3c/nicole/manifest.json',
    },
    {
      label: 'Nolan',
      value: 's3://peregrine-voices/nolan saad parrot/manifest.json',
    },
    { label: 'Nova', value: 'nova' },
    { label: 'Oliver', value: 'oliver' },
    {
      label: 'Oliver (Advertising)',
      value: 's3://peregrine-voices/oliver_ads2_parrot_saad/manifest.json',
    },
    {
      label: 'Oliver (Narrative)',
      value:
        's3://peregrine-voices/oliver_narrative2_parrot_saad/manifest.json',
    },
    {
      label: 'Oliver (Training)',
      value:
        's3://mockingbird-prod/oliver_vo_training_6e3f604a-5605-4542-948d-347b0d7546fc/voices/speaker/manifest.json',
    },
    {
      label: 'Olivia (Advertising)',
      value:
        's3://mockingbird-prod/olivia_vo_commercials_6e3c384f-15d6-4fe7-b9a4-0cb1d69daeba/voices/speaker/manifest.json',
    },
    {
      label: 'Olivia (Narrative)',
      value: 's3://peregrine-voices/olivia_ads3_parrot_saad/manifest.json',
    },
    {
      label: 'Olivia (Training)',
      value:
        's3://mockingbird-prod/olivia_vo_training_4376204f-a411-4e5d-a5c0-ce6cc3908052/voices/speaker/manifest.json',
    },
    { label: 'Oscar', value: 'oscar' },
    { label: 'Owen', value: 'owen' },
    { label: 'Pedro', value: 'pedro' },
    { label: 'Phoebe', value: 'phoebe' },
    { label: 'Randall', value: 'randall' },
    { label: 'Reynaldo', value: 'reynaldo' },
    { label: 'Rodrick', value: 'rodrick' },
    {
      label: 'Ruby',
      value:
        's3://voice-cloning-zero-shot/d9ff78ba-d016-47f6-b0ef-dd630f59414e/female-cs/manifest.json',
    },
    {
      label: 'Russell',
      value: 's3://peregrine-voices/russell2_parrot_saad/manifest.json',
    },
    { label: 'Samantha', value: 'gabriel' },
    { label: 'Samuel', value: 'samuel' },
    {
      label: 'Sarah',
      value:
        's3://voice-cloning-zero-shot/820da3d2-3a3b-42e7-844d-e68db835a206/sarah/manifest.json',
    },
    {
      label: 'Sarge',
      value:
        's3://mockingbird-prod/agent_47_carmelo_pampillonio_58e796e1-0b87-4f3e-8b36-7def6d65ce66/voices/speaker/manifest.json',
    },
    {
      label: 'Sophia',
      value:
        's3://voice-cloning-zero-shot/1f44b3e7-22ea-4c2e-87d0-b4d9c8f1d47d/sophia/manifest.json',
    },
    { label: 'Stella', value: 'stella' },
    { label: 'Susan', value: 'susan' },
    {
      label: 'Susan (Advertising)',
      value:
        's3://mockingbird-prod/susan_vo_commercials_0f4fa663-6eba-4582-be1e-2d5bde798f1c/voices/speaker/manifest.json',
    },
    {
      label: 'Susan (Narrative)',
      value:
        's3://mockingbird-prod/susan_vo_narrative_73051c90-460b-4e54-adab-9235f45c5e5f/voices/speaker/manifest.json',
    },
    {
      label: 'Susan (Training)',
      value:
        's3://mockingbird-prod/susan_vo_training_46ffcc60-d630-42f6-acfe-4affd003ae7a/voices/speaker/manifest.json',
    },
    { label: 'Theodore', value: 'theodore' },
    { label: 'Wilbert', value: 'wilbert' },
    { label: 'Wilbur', value: 'wilbur' },
    { label: 'Wilfred', value: 'wilfred' },
    { label: 'Will', value: 's3://peregrine-voices/mel22/manifest.json' },
    { label: 'William', value: 'william' },
    {
      label: 'William (Narrative)',
      value:
        's3://mockingbird-prod/william_vo_narrative_0eacdff5-6243-4e26-8b3b-66e03458c1d1/voices/speaker/manifest.json',
    },
    {
      label: 'William (Training)',
      value:
        's3://mockingbird-prod/william_vo_training_1b939b71-14fa-41f0-b1db-7d94f194ad0a/voices/speaker/manifest.json',
    },
  ],
  voice_rime: [
    { label: 'Abbie', value: 'abbie' },
    { label: 'Allison', value: 'allison' },
    { label: 'Ally', value: 'ally' },
    { label: 'Alona', value: 'alona' },
    { label: 'Amber', value: 'amber' },
    { label: 'Ana', value: 'ana' },
    { label: 'Antoine', value: 'antoine' },
    { label: 'Armon', value: 'armon' },
    { label: 'Brenda', value: 'brenda' },
    { label: 'Brittany', value: 'brittany' },
    { label: 'Carol', value: 'carol' },
    { label: 'Colin', value: 'colin' },
    { label: 'Courtney', value: 'courtney' },
    { label: 'Elena', value: 'elena' },
    { label: 'Elliot', value: 'elliot' },
    { label: 'Eva', value: 'eva' },
    { label: 'Geoff', value: 'geoff' },
    { label: 'Gerald', value: 'gerald' },
    { label: 'Hank', value: 'hank' },
    { label: 'Helen', value: 'helen' },
    { label: 'Hera', value: 'hera' },
    { label: 'Jen', value: 'jen' },
    { label: 'Joe', value: 'joe' },
    { label: 'Joy', value: 'joy' },
    { label: 'Juan', value: 'juan' },
    { label: 'Kendra', value: 'kendra' },
    { label: 'Kendrick', value: 'kendrick' },
    { label: 'Kenneth', value: 'kenneth' },
    { label: 'Kevin', value: 'kevin' },
    { label: 'Kris', value: 'kris' },
    { label: 'Linda', value: 'linda' },
    { label: 'Madison', value: 'madison' },
    { label: 'Marge', value: 'marge' },
    { label: 'Marina', value: 'marina' },
    { label: 'Marissa', value: 'marissa' },
    { label: 'Marta', value: 'marta' },
    { label: 'Maya', value: 'maya' },
    { label: 'Nicholas', value: 'nicholas' },
    { label: 'Nyles', value: 'nyles' },
    { label: 'Phil', value: 'phil' },
    { label: 'Reba', value: 'reba' },
    { label: 'Rex', value: 'rex' },
    { label: 'Rick', value: 'rick' },
    { label: 'Ritu', value: 'ritu' },
    { label: 'Rob', value: 'rob' },
    { label: 'Rodney', value: 'rodney' },
    { label: 'Rohan', value: 'rohan' },
    { label: 'Rosco', value: 'rosco' },
    { label: 'Samantha', value: 'samantha' },
    { label: 'Sandy', value: 'sandy' },
    { label: 'Selena', value: 'selena' },
    { label: 'Seth', value: 'seth' },
    { label: 'Sharon', value: 'sharon' },
    { label: 'Stan', value: 'stan' },
    { label: 'Tamra', value: 'tamra' },
    { label: 'Tanya', value: 'tanya' },
    { label: 'Tibur', value: 'tibur' },
    { label: 'Tj', value: 'tj' },
    { label: 'Tyler', value: 'tyler' },
    { label: 'Viv', value: 'viv' },
    { label: 'Yadira', value: 'yadira' },
    { label: 'Marsh', value: 'marsh' },
    { label: 'Bayou', value: 'bayou' },
    { label: 'Creek', value: 'creek' },
    { label: 'Brook', value: 'brook' },
    { label: 'Flower', value: 'flower' },
    { label: 'Spore', value: 'spore' },
    { label: 'Glacier', value: 'glacier' },
    { label: 'Gulch', value: 'gulch' },
    { label: 'Alpine', value: 'alpine' },
    { label: 'Cove', value: 'cove' },
    { label: 'Lagoon', value: 'lagoon' },
    { label: 'Tundra', value: 'tundra' },
    { label: 'Steppe', value: 'steppe' },
    { label: 'Mesa', value: 'mesa' },
    { label: 'Grove', value: 'grove' },
    { label: 'Rainforest', value: 'rainforest' },
    { label: 'Moraine', value: 'moraine' },
    { label: 'Wildflower', value: 'wildflower' },
    { label: 'Peak', value: 'peak' },
    { label: 'Boulder', value: 'boulder' },
    { label: 'Summit', value: 'summit' },
    { label: 'Talon', value: 'talon' },
    { label: 'Blaze', value: 'blaze' },
    { label: 'Stone', value: 'stone' },
    { label: 'Cedar', value: 'cedar' },
    { label: 'Storm', value: 'storm' },
    { label: 'Fjord', value: 'fjord' },
    { label: 'Wolf', value: 'wolf' },
    { label: 'River', value: 'river' },
    { label: 'Ironwood', value: 'ironwood' },
    { label: 'Falcon', value: 'falcon' },
    { label: 'Thunder', value: 'thunder' },
    { label: 'Granite', value: 'granite' },
    { label: 'Hawk', value: 'hawk' },
    { label: 'Willow', value: 'willow' },
    { label: 'Moon', value: 'moon' },
    { label: 'Breeze', value: 'breeze' },
    { label: 'Iris', value: 'iris' },
    { label: 'Stream', value: 'stream' },
    { label: 'Violet', value: 'violet' },
    { label: 'Rain', value: 'rain' },
    { label: 'Petal', value: 'petal' },
    { label: 'Ember', value: 'ember' },
    { label: 'Blossom', value: 'blossom' },
    { label: 'Dew', value: 'dew' },
    { label: 'Lotus', value: 'lotus' },
    { label: 'Pearl', value: 'pearl' },
    { label: 'Benjamin', value: 'benjamin' },
    { label: 'Frank', value: 'frank' },
  ],
};

// These need to match the action keys in Integration App
export enum ActionKey {
  RETRIEVE_AVAILABILITY = 'retrieve-availability',
  BOOK_APPOINTMENT = 'book-appointment',
}
