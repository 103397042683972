import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { FirebaseUserGuard } from './components/guards/FirebaseUserGuard';
import { LoggedLayout } from './components/LoggedLayout';
import { UnloggedLayout } from './components/UnloggedLayout';
import { FirebaseAuthProvider } from './contexts/AuthContext/FirebaseAuthContext';
import { EnvironmentProvider } from './contexts/EnvironmentContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { RightHandSidebarProvider } from './contexts/RightHandSidebarContext';
import {
  Billing,
  Calls,
  Home,
  ListAgents,
  ListNumbers,
  ListPrompts,
  ListVoices,
  Login,
  ManageAgent,
  ManagePrompt,
  NotFound,
  ResetPassword,
  SamplePageUnlogged,
  Settings,
  SignUp,
  Profile,
  ListAccountConnections,
  ListActions,
  ListWebhooks,
  ChangeOrganization,
  ListWorkflowsProvider,
  Onboarding,
} from './pages';
import { OnboardingLayout } from './components/OnboardingLayout';
import { OrganizationLockGuard } from './components/guards/OrganizationLockGuard';

export const AllRoutes = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: String(process.env.REACT_APP_GTM_ID),
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      <FirebaseAuthProvider>
        <NotificationProvider>
          <EnvironmentProvider>
            <RightHandSidebarProvider>
              <Routes>
                <Route element={<FirebaseUserGuard />}>
                  <Route element={<LoggedLayout />}>
                    <Route element={<OrganizationLockGuard />}>
                      <Route path="/" element={<Home />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/agents" element={<ListAgents />} />
                      <Route path="/agent/new" element={<ManageAgent />} />
                      <Route
                        path="/agent/edit/:id"
                        element={<ManageAgent editing={true} />}
                      />
                      <Route path="/calls" element={<Calls />} />
                      <Route path="/prompts" element={<ListPrompts />} />
                      <Route path="/prompt/create" element={<ManagePrompt />} />
                      <Route
                        path="/prompt/edit/:id"
                        element={<ManagePrompt editing={true} />}
                      />
                      <Route path="/numbers" element={<ListNumbers />} />
                      <Route path="/voices" element={<ListVoices />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route
                        path="/providers"
                        element={<ListAccountConnections />}
                      />
                      <Route path="/actions" element={<ListActions />} />
                      <Route path="/webhooks" element={<ListWebhooks />} />
                      <Route
                        path="/workflows"
                        element={<ListWorkflowsProvider />}
                      />
                      <Route
                        path="/change-organization"
                        element={<ChangeOrganization />}
                      />
                    </Route>
                    <Route path="/billing" element={<Billing />} />
                  </Route>

                  <Route element={<OnboardingLayout />}>
                    <Route path="/onboarding" element={<Onboarding />} />
                  </Route>
                </Route>

                <Route element={<UnloggedLayout />}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/contact-us" element={<SamplePageUnlogged />} />
                  <Route path="/terms" element={<SamplePageUnlogged />} />
                  <Route path="/privacy" element={<SamplePageUnlogged />} />

                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </RightHandSidebarProvider>
          </EnvironmentProvider>
        </NotificationProvider>
      </FirebaseAuthProvider>
    </Router>
  );
};
