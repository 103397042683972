import { Listbox, Transition } from '@headlessui/react';
import { CaretDown, CaretUp, Check } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type Option = {
  value: string;
  label?: string;
};

type SelectBoxProps = {
  options: Option[];
  defaultValue?: Option;
  value?: Option | null;
  variant?: 'outlined' | 'contained' | 'text';
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  direction?: 'up' | 'down';
};

const variantClasses = {
  contained: {
    primary: 'bg-primary text-white hover:opacity-90',
    secondary: 'bg-gray-500 text-white hover:bg-gray-600',
    error: 'bg-red-500 text-white hover:bg-red-600',
    warning: 'bg-yellow-500 text-white hover:bg-yellow-600',
    success: 'bg-green-500 text-white hover:bg-green-600',
    info: 'bg-blue-500 text-white hover:bg-blue-600',
    default: 'bg-gray-500 text-white hover:bg-gray-600',
  },
  outlined: {
    primary: 'border hover:border-primary hover:text-primary',
    secondary:
      'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
    error:
      'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
    warning:
      'border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white',
    success:
      'border border-green-500 text-green-500 hover:bg-green-500 hover:text-white',
    info: 'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white',
    default:
      'border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white',
  },
  text: {
    primary: 'text-primary hover:bg-primary hover:text-white',
    secondary: 'text-gray-500 hover:bg-gray-500 hover:text-white',
    error: 'text-red-500 hover:bg-red-500 hover:text-white',
    warning: 'text-yellow-500 hover:bg-yellow-500 hover:text-white',
    success: 'text-green-500 hover:bg-green-500 hover:text-white',
    info: 'text-blue-500 hover:bg-blue-500 hover:text-white',
    default: 'text-gray-500 hover:bg-gray-500 hover:text-white',
  },
};

const sizeClasses = {
  small: 'px-2 py-1 text-sm leading-6',
  medium: 'px-3 py-2.5 text-base leading-6',
  large: 'px-4 py-3 text-lg leading-7',
};

export const SelectBox = ({
  options,
  defaultValue,
  value,
  variant = 'outlined',
  color = 'primary',
  size = 'medium',
  className = '',
  onChange,
  disabled = false,
  direction = 'down',
}: SelectBoxProps) => {
  // Initialize state with null instead of undefined to ensure consistent controlled behavior
  const [selected, setSelected] = useState<Option | null>(
    value !== undefined ? value : defaultValue || null,
  );
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const listboxRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  // Update internal state when value changes from parent
  useEffect(() => {
    // Only update if value is explicitly passed (even if null)
    if (value !== undefined) {
      setSelected(value);
    }
  }, [value]);

  const updatePosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const maxHeight = 280; // Maximum height for dropdown (7 items * 40px)
      const dropdownHeight = Math.min(options.length * 40, maxHeight);
      const viewportHeight = window.innerHeight;

      // Check if there's enough space below
      const spaceBelow = viewportHeight - rect.bottom;
      const spaceAbove = rect.top;
      const shouldShowAbove =
        direction === 'up' ||
        (spaceBelow < dropdownHeight && spaceAbove > spaceBelow);

      setPosition({
        top: shouldShowAbove ? rect.top - dropdownHeight - 8 : rect.bottom + 8,
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const variantClass = variantClasses[variant][color];
  const sizeClass = sizeClasses[size];

  const maxHeight = 280;

  return (
    <Listbox
      value={selected}
      onChange={(newValue: Option) => {
        if (value === undefined) {
          // Only update internal state if not fully controlled
          setSelected(newValue);
        }
        onChange?.(newValue.value);
      }}
      disabled={disabled || options.length === 0}
    >
      <div className="relative">
        <Listbox.Button
          ref={buttonRef}
          onClick={updatePosition}
          className={clsx(
            'w-full rounded-md border border-gray-300',
            `${variantClass} ${sizeClass}`,
            'flex items-center leading-6',
            'focus:outline-none',
            className,
            disabled && 'opacity-50 cursor-not-allowed',
            options.length === 0 && 'opacity-50 cursor-not-allowed',
          )}
        >
          <div className="flex-1 text-left truncate">
            {selected ? (
              selected.label || selected.value
            ) : (
              <i>
                {options.length > 0
                  ? 'Select value...'
                  : 'No options available'}
              </i>
            )}
          </div>
          {direction === 'up' ? (
            <CaretUp className="ml-2 flex-shrink-0" />
          ) : (
            <CaretDown className="ml-2 flex-shrink-0" />
          )}
        </Listbox.Button>

        <Transition
          as={'div'}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {createPortal(
            <Listbox.Options
              ref={listboxRef}
              className={clsx(
                'absolute z-[9999] bg-white shadow-lg rounded-md py-1',
                'overflow-auto focus:outline-none text-sm',
                'border border-gray-200',
              )}
              style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                width: `${position.width}px`,
                position: 'fixed',
                maxHeight: `${maxHeight}px`,
              }}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  value={option}
                  className={({ active }) =>
                    clsx(
                      'cursor-pointer select-none relative py-3 px-4 m-2 rounded-lg truncate flex justify-between',
                      active && !option?.value && 'bg-neutral-150',
                      option?.value === defaultValue?.value && 'bg-neutral-50',
                      option?.value === defaultValue?.value
                        ? 'text-neutral-715 font-bold'
                        : 'text-neutral-715',
                      'hover:bg-neutral-50 hover:text-neutral-715',
                      'active:bg-neutral-100 active:text-neutral-715',
                    )
                  }
                >
                  {option.label || option.value}{' '}
                  {option?.value === defaultValue?.value && <Check size={18} />}
                </Listbox.Option>
              ))}
            </Listbox.Options>,
            document.body,
          )}
        </Transition>
      </div>
    </Listbox>
  );
};
