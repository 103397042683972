import {
  FileText,
  MagnifyingGlass,
  Pencil,
  Trash,
  Eye,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Prompt, PromptRowItem } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { limitString } from 'src/utils/limitString';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'label', label: 'Name', width: '10%' },
  { key: 'content', label: 'Content', width: '50%' },
  { key: 'type', label: 'Type', width: '10%', disableSorting: true },
  { key: 'source', label: 'Created By', width: '10%', disableSorting: true },
  { key: 'tags', label: 'Tags', width: '10%', disableSorting: true },
  { key: 'action', label: '', width: '20%', disableSorting: true },
];

const PROMPT_TYPES = [
  { label: 'All', value: '' },
  { label: 'Prompts', value: 'prompt' },
  { label: 'Building Blocks', value: 'block' },
];

const PROMPT_SOURCES = [
  { label: 'All', value: '' },
  { label: 'User', value: 'user' },
  { label: 'Fluents', value: 'fluents' },
];

export const ListPrompts = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();

  const [rows, setRows] = useState<PromptRowItem[]>([]);
  const [filters, setFilters] = useState({
    content: '',
    type: '',
    source: '',
    scope: 'global|user',
  });
  const debouncedFilter = useDebounce(filters, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      filters: JSON.stringify(filters),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/prompts?${query.toString()}`, {
      envId,
    });

    const prompts = data.items.map((item: Prompt) => ({
      id: <CopyableField value={item.id} notification={notification} />,
      label: item.label,
      content: limitString(item.content, 50),
      type: item.type,
      source: item.user_id ? 'User' : 'Fluents',
      tags: item.tags?.join(','),
      action: (
        <div className="flex justify-center items-center">
          <SideDropActions
            options={[
              {
                label: item.user_id ? 'Edit' : 'View',
                Icon: item.user_id ? Pencil : Eye,
                onClick: () => navigate(`/prompt/edit/${item.id}`),
              },
              {
                label: 'Delete',
                Icon: Trash,
                color: 'error',
                show: !!item.user_id,
                onClick: () => openDeletePrompt(item),
              },
            ]}
          />
        </div>
      ),
    }));

    setRows(prompts as never[]);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const openDeletePrompt = (prompt: Prompt) => {
    setModalDeleteOpen(true);
    setSelectedId(prompt.id);
  };

  const handleRemovePrompt = async () => {
    setLoading(true);
    try {
      await remove(`/prompts/${selectedId}`, { envId });
      notification.success('Prompt deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete prompt');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-1">
      <Heading
        title="Prompt Library"
        subtitle="Prompts outline how your agent should behave during a call"
      >
        <div className="flex mt-6">
          <Button
            className="w-42 border-none flex items-center justify-center text-gray-600"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/prompts"
            target="_blank"
          >
            View help doc
            <FileText className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex flex-col md:flex-row justify-between mb-6">
          <div className="flex w-full md:w-1/2 self-end">
            <div className="flex w-full md:grow self-end">
              <Input
                type="text"
                required
                placeholder="Search prompts"
                value={filters.content}
                onChange={(e) =>
                  setFilters({ ...filters, content: e.target.value })
                }
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    submitFilter();
                  }
                }}
                icon={<MagnifyingGlass size={20} />}
                iconPosition="left"
                inputAction={() => fetchData(currentPage, size)}
              />
            </div>
            <div className="w-40 ml-2">
              <Label htmlFor="prompt-types">Type</Label>
              <SelectBox
                key="prompt-types"
                options={PROMPT_TYPES}
                defaultValue={
                  filters.type
                    ? PROMPT_TYPES.find(
                        (promptType) => promptType.value === filters.type,
                      )
                    : { label: 'All', value: '' }
                }
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) => {
                  setFilters({ ...filters, type: value });
                  handlePageChange(1, size);
                }}
                className="col-span-2"
              />
            </div>
            <div className="w-40 ml-2">
              <Label htmlFor="prompt-source">Created By</Label>
              <SelectBox
                key="prompt-source"
                options={PROMPT_SOURCES}
                defaultValue={
                  filters.source
                    ? PROMPT_SOURCES.find(
                        (promptSource) => promptSource.value === filters.source,
                      )
                    : { label: 'All', value: '' }
                }
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) => {
                  setFilters({ ...filters, source: value });
                  handlePageChange(1, size);
                }}
                className="col-span-3"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <Button onClick={() => navigate('/prompt/create')}>
              Create Prompt Template
            </Button>
          </div>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title="Delete Prompt"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button onClick={handleRemovePrompt}>Delete Prompt</Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this prompt?
        </div>
      </Modal>
    </div>
  );
};
