import { getAuth } from 'firebase/auth';
import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { get } from 'src/api/requests';
import { getPayloadJWT, isJWT } from 'src/utils/jwt';

export const OrganizationLockGuard = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const fetchCurrentUserAndOrganization = useCallback(async () => {
    const token = await auth.currentUser?.getIdToken();

    if (token && isJWT(token)) {
      const decoded = getPayloadJWT(token);

      if (decoded.organization) {
        const organization = await get(
          `/organizations/${decoded.organization.id}`,
        );
        if (
          !!organization.lockAt &&
          new Date(organization.lockAt).getTime() < new Date().getTime()
        ) {
          navigate('/billing?error=plan-expired');
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    if (!auth) {
      return;
    }

    fetchCurrentUserAndOrganization();
  }, [auth]);

  return <Outlet />;
};
