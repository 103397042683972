import clsx from 'clsx';
import { getAuth } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import Cookies from 'universal-cookie';
import RightHandSidebar from './RightHandSidebar';
import { Sidebar } from './Sidebar';
import { getPayloadJWT, isJWT } from 'src/utils/jwt';
import { get } from 'src/api/requests';

type LayoutProps = {
  children?: React.ReactNode;
};

const cookies = new Cookies(null, { path: '/' });
const DAYS_WARNING_THRESHOLD = 4;

export const LoggedLayout = ({ children }: LayoutProps) => {
  const { isOpen } = useRightHandSidebar();
  const defaultAuth = getAuth();
  const auth = useFirebaseAuth();
  const { isOpen: isRightSidebarOpen } = useRightHandSidebar();
  const [lockAt, setLockAt] = useState<Date>();
  const location = useLocation();
  cookies.set('userId', auth?.user?.uid);

  const isBannerOpen = () => !!lockAt;
  const daysLeft = lockAt
    ? Math.max(
        0,
        Math.floor(
          (lockAt.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
        ),
      )
    : 0;

  useEffect(() => {
    const user = auth?.user;
    if (user?.uid && !location.pathname.includes('/onboarding')) {
      window.pylon = {
        chat_settings: {
          app_id: process.env.REACT_APP_PYLON_APP_ID,
          email: user.email,
          name: user.displayName,
          avatar_url: user.photoURL,
        },
      };
    }
  }, [auth?.user, location.pathname]);

  const fetchCurrentUserAndOrganization = useCallback(async () => {
    const token = await defaultAuth.currentUser?.getIdToken();

    if (token && isJWT(token)) {
      const decoded = getPayloadJWT(token);

      if (decoded.organization) {
        const organization = await get(
          `/organizations/${decoded.organization.id}`,
        );
        if (!!organization.lockAt) {
          setLockAt(new Date(organization.lockAt));
        }
      }
    }
  }, [defaultAuth]);

  useEffect(() => {
    if (!defaultAuth) {
      return;
    }

    fetchCurrentUserAndOrganization();
  }, [defaultAuth]);

  return (
    <div className="flex flex-col bg-gray-100">
      {isBannerOpen() && (
        <div
          className={clsx(
            'text-white text-center p-2 text-sm lg:text-base fixed top-0 w-full z-10',
            daysLeft <= DAYS_WARNING_THRESHOLD ? 'bg-warning' : 'bg-primary',
          )}
        >
          Welcome to Fluents.ai! You have <b>{daysLeft}</b> days left on your
          current plan
        </div>
      )}

      <div
        className={clsx(
          'flex m-2 bg-white rounded-xl',
          isBannerOpen() ? 'min-h-[calc(97vh-40px)] mt-[48px]' : 'min-h-[97vh]',
        )}
      >
        <Sidebar />

        <div className={clsx('w-full block border-l border-gray-200')}>
          <div
            className={clsx(
              'grid w-full transition-all duration-300',
              isRightSidebarOpen
                ? 'grid-cols-[1fr_340px]'
                : 'grid-cols-[1fr_0]',
            )}
          >
            <div
              className={clsx(
                'transition-all duration-300 flex flex-1 overflow-y-auto',
              )}
            >
              <div
                className={clsx(
                  'bg-white w-full',
                  !isOpen ? 'rounded-r-xl' : 'rounded-r-none',
                  'overflow-auto',
                )}
              >
                {children || <Outlet />}
              </div>
            </div>

            <RightHandSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};
