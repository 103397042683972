import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { patch } from 'src/api/requests';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { OnboardingComponent } from 'src/interfaces/onboardingComponent.interface';

export const Welcome = ({
  onPhaseComplete,
  organization,
}: OnboardingComponent) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [isVisible, setIsVisible] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!envId) return;

    if (!organization.id) {
      return;
    }

    const visibilityTimer = setTimeout(() => setIsVisible(true), 300);

    const secondScreenTimer = setTimeout(() => {
      setShowSecondScreen(true);
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(visibilityTimer);
      clearTimeout(secondScreenTimer);
    };
  }, [envId, organization.id]);

  useEffect(() => {
    if (isLoading) return;

    const navigationTimer = setTimeout(async () => {
      await patch(
        `/organizations/${organization.id}/onboarding`,
        { currentPhase: 'caller' },
        { envId },
      );

      onPhaseComplete();
    }, 3000);

    return () => clearTimeout(navigationTimer);
  }, [isLoading, envId, organization.id, onPhaseComplete]);

  const SecondScreen = (
    <>
      <img
        src="/assets/logo-white.png"
        alt="Circle"
        className={clsx('absolute top-10 left-10 h-10 animate-fade-in-up')}
      />

      <div className="container mx-auto py-10">
        <div className="flex flex-col items-center justify-center min-h-[80vh] p-8 animate-fade-in">
          <img
            src="/assets/onboarding/circle.png"
            alt="Circle Chat"
            className="w-16 h-16 mb-4 animate-fade-in-up"
          />

          <h1 className="text-[40px] font-normal mb-2 text-center text-white animate-fade-in-up">
            Let us show you what Fluents can do.
          </h1>
        </div>
      </div>
    </>
  );

  return (
    <>
      {!showSecondScreen ? (
        <div className="flex flex-col items-center justify-center min-h-[80vh] p-8">
          <div
            className={clsx(
              'flex flex-col items-center gap-2 transition-opacity duration-1000',
              !isVisible && 'opacity-0',
            )}
          >
            <img src="/assets/onboarding/icon-logo.png" alt="Logo" />
            <img src="/assets/onboarding/welcome.png" alt="Welcome" />
          </div>
        </div>
      ) : (
        SecondScreen
      )}
    </>
  );
};
