import clsx from 'clsx';
import { FirebaseError } from 'firebase/app';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Input } from 'src/components/Input';
import { UnloggedCard } from 'src/components/UnloggedCard';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { getFirebaseErrorMessage } from 'src/utils/FirebaseErrorUtils';
import { Button } from '../components/Button';

export const ResetPassword = () => {
  const authed = useFirebaseAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const notification = useNotification();
  const navigate = useNavigate();

  if (authed && !authed.loading && authed.uid) {
    return <Navigate to="/" />;
  }

  const auth = getAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      notification.success('Password reset email sent!');

      navigate('/login');
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error sending password reset email');
      }
    }

    setLoading(false);
  };

  return (
    <UnloggedCard>
      <h2 className="text-h2 font-medium mb-6">Forgot your password?</h2>

      <p className="font-body-light text-sm mb-6">
        Let's help you get back in.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            required
          />
        </div>

        <Button className="w-full mb-6" type="submit" disabled={loading}>
          Send Reset Link
        </Button>

        <div className="relative mb-6">
          <div className="flex items-center justify-center">
            <div className="w-full border-t border-gray-300"></div>
            <span className="absolute bg-white px-2 text-gray-600">or</span>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <div
            className={clsx(
              'w-full mb-2 text-center font-medium text-body cursor-pointer',
              loading ? 'opacity-50 text-gray-400' : 'opacity-100 text-primary',
            )}
            onClick={() => navigate('/login')}
          >
            Back to Login
          </div>
        </div>
      </form>
    </UnloggedCard>
  );
};
