import React, { useState } from 'react';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  key?: string;
  defaultOpen?: boolean;
  className?: string;
};

function Accordion({
  title,
  children,
  key,
  defaultOpen = true,
  className = '',
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div key={key} className={`border rounded ${className}`}>
      <button
        className="flex items-center justify-between w-full px-4 py-2 font-semibold"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {title}
        <svg
          className={`w-4 h-4 ml-2 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
}

export default Accordion;
