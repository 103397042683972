import { Copy } from '@phosphor-icons/react';
import { Tooltip } from './Tooltip';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { NotificationContextState } from 'src/contexts/NotificationContext';

type CopyableFieldProps = {
  value: string;
  notification: NotificationContextState;
  className?: string;
};

export const CopyableField = ({
  value,
  notification,
  className,
}: CopyableFieldProps) => (
  <div className={`flex items-center ${className}`}>
    <div className="text-ellipsis whitespace-nowrap overflow-hidden mr-1">
      {value}
    </div>
    {value && (
      <Tooltip content="Copy to clipboard" position="top">
        <Copy
          className="cursor-pointer inline-block w-5 h-5"
          onClick={() => copyToClipboard(value, notification)}
        />
      </Tooltip>
    )}
  </div>
);
