export const AgentVoiceMaleStub = {
  type: 'voice_eleven_labs',
  voice_id: 'nPczCjzI2devNBz1zQrb',
  stability: 0.4,
  similarity_boost: 1.0,
  label: 'Brian',
};

export const AgentVoiceFemaleStub = {
  type: 'voice_eleven_labs',
  voice_id: 'cgSgspJ2msm6clMCkdW9',
  stability: 0.4,
  similarity_boost: 1.0,
  label: 'Jessica',
};

export const AgentStub = {
  language: 'en',
  initial_message_delay: 0,
  interrupt_sensitivity: 'low',
  max_idle_check_count: 3,
  idle_time_seconds: 7,
  call_duration_sec: 600,
  endpointing_sensitivity: 'auto',
  conversation_speed: 1,
  ask_if_human_present_on_idle: true,
  llm_fallback: {
    provider: 'openai',
    model_name: 'GPT-3.5-turbo',
  },
  llm_temperature: 0,
  provider: 'openai',
  prompt: {
    content: '',
  },
};

export const OnboardingAgentStub = {
  language: 'en',
  initial_message_delay: 0,
  max_idle_check_count: 3,
  idle_time_seconds: 7,
  call_duration_sec: 600,
  conversation_speed: 1.8,
  ask_if_human_present_on_idle: true,
  llm_temperature: 0,
  provider: 'openai',
  name: 'My First Agent',
  description: 'Onboarding Agent',
  voice: AgentVoiceFemaleStub,
  label: 'My First Agent',
};
