import { Call } from 'src/interfaces/call.interface';
import { processPhoneNumber } from 'src/utils/number';
import { CallRecordingButton } from './CallRecordingButton';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import clsx from 'clsx';
import { Button } from './Button';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { differenceInSeconds } from 'date-fns';

type CallDetailsProps = {
  call: Call;
};

export const CallDetails = ({ call }: CallDetailsProps) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const startDate = call.start_time
    ? new Date(Number(call.start_time) * 1000)
    : null;
  const endDate = call.end_time ? new Date(Number(call.end_time) * 1000) : null;
  const durationSecs =
    (startDate && endDate && differenceInSeconds(endDate, startDate)) || 0;

  const downloadTranscript = () => {
    const element = document.createElement('a');
    const file = new Blob([call.transcript], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'transcript_' + call.id + '.txt';
    document.body.appendChild(element);
    element.click();
  };

  return (
    <table className="min-w-full bg-white border border-gray-200">
      <tbody>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">ID</td>
          <td className="py-2 px-4 border-b border-gray-200">{call.id}</td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">Status</td>
          <td className="py-2 px-4 border-b border-gray-200">{call.status}</td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">Human Answered</td>
          <td className="py-2 px-4 border-b border-gray-200">
            {durationSecs === 0 ? (
              '-'
            ) : call.human_detection_result === 'no_human' ? (
              <span className="flex items-center gap-2 text-red-600">
                <XCircle size={20} weight="fill" className="flex-shrink-0" />
                <span style={{ transform: 'translateY(2px)' }}>No</span>
              </span>
            ) : (
              <span className="flex items-center gap-2 text-green-700">
                <CheckCircle
                  size={20}
                  weight="fill"
                  className="flex-shrink-0"
                />
                <span style={{ transform: 'translateY(2px)' }}>Yes</span>
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td
            className={clsx(
              'py-2 px-4',
              call.transcript ? '' : 'border-b border-gray-200',
            )}
          >
            Transcript
          </td>
          <td
            className={clsx(
              'py-2 px-4',
              call.transcript ? '' : 'border-b border-gray-200',
            )}
          >
            {call.transcript ? (
              <Button onClick={downloadTranscript}>Download</Button>
            ) : (
              'No transcript available.'
            )}
          </td>
        </tr>
        {call.transcript && (
          <tr>
            <td colSpan={2} className="py-2 px-4 border-b border-gray-200">
              <div className="mb-2 overflow-y-auto whitespace-pre-wrap h-auto max-h-52 p-4 rounded-lg bg-gray-100 border-gra-400 border-2">
                {call.transcript}
              </div>
            </td>
          </tr>
        )}
        {call.recording_available && (
          <tr>
            <td className="py-2 px-4 border-b border-gray-200">Recording</td>
            <td className="py-2 px-4 border-b border-gray-200">
              <CallRecordingButton
                callId={call.id}
                envId={envId}
                recordingAvailable={call.recording_available}
              />
            </td>
          </tr>
        )}
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">To Number</td>
          <td className="py-2 px-4 border-b border-gray-200">
            {processPhoneNumber(call.to_number)}
          </td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">From Number</td>
          <td className="py-2 px-4 border-b border-gray-200">
            {processPhoneNumber(call.from_number)}
          </td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">Agent</td>
          <td className="py-2 px-4 border-b border-gray-200">{call.agent}</td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">Provider</td>
          <td className="py-2 px-4 border-b border-gray-200">
            {call.telephony_provider}
          </td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-gray-200">{`${call.telephony_provider[0].toLocaleUpperCase()}${call.telephony_provider.substring(1)} ID`}</td>
          <td className="py-2 px-4 border-b border-gray-200 break-all">
            {call.telephony_metadata?.call_sid}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
