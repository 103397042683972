import { Outlet } from 'react-router-dom';

type OnboardingLayoutProps = {
  children?: React.ReactNode;
  welcomeScreenDuration?: number;
};

export const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <div
      className="bg-neutral-925 min-h-screen"
      style={{
        backgroundImage: `url(/assets/onboarding/bg.png)`,
        backgroundRepeat: 'repeat',
      }}
    >
      {children || <Outlet />}
    </div>
  );
};
