import {
  Code,
  FileText,
  MagnifyingGlass,
  Pencil,
  Trash,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get, patch, post, remove } from 'src/api/requests';
import { AutoCompleteInput } from 'src/components/AutoCompleteInput';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { RadioTab } from 'src/components/RadioTab';
import { SchemaBuilder } from 'src/components/SchemaBuilder';
import { SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import { Tab } from 'src/components/Tab';
import Table from 'src/components/Table';
import { Textarea } from 'src/components/Textarea';
import { Toggle } from 'src/components/Toggle';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import {
  Action,
  ActionPayload,
  ActionType,
  PhraseTriggerConditionType,
  ProcessingMode,
  TriggerType,
} from 'src/interfaces/action.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { limitString } from 'src/utils/limitString';
import { processPhoneNumber } from 'src/utils/number';
import { isValidHttpUrl } from 'src/utils/url';

const actions = [
  { label: 'End Conversation', value: ActionType.EndConversation },
  { label: 'DTMF', value: ActionType.DTMF },
  { label: 'Transfer Call', value: ActionType.TransferCall },
  { label: 'Add To Conference', value: ActionType.AddToConference },
  { label: 'Set Hold', value: ActionType.SetHold },
  { label: 'External', value: ActionType.External },
];

const actionsModal = [
  { label: 'End Conversation', value: ActionType.EndConversation },
  { label: 'Use DTMF (Dual Tone Multi-frequency)', value: ActionType.DTMF },
  { label: 'Place on Hold', value: ActionType.SetHold, disabled: true },
  { label: 'Transfer Calls', value: ActionType.TransferCall },
  {
    label: 'Add multiple participants',
    value: ActionType.AddToConference,
    disabled: true,
  },
  { label: 'Custom Input', value: ActionType.External },
];

const triggerTypes = [
  {
    label: 'Function Call',
    value: TriggerType.FunctionCall,
    description: 'Let Fluents.ai decide when to trigger this action',
  },
  {
    label: 'Phrase Based',
    value: TriggerType.PhraseBased,
    description: 'This action will trigger based on a bot-spoken phrase.',
  },
];

const newAction: ActionPayload = {
  type: null as unknown as ActionType,
  config: {},
  action_trigger: {
    type: TriggerType.FunctionCall,
    config: {},
  },
  label: '',
};

const headers = [
  { key: 'type', label: 'Name', width: '30%' },
  { key: 'description', label: 'Description', width: '30%' },
  { key: 'action_trigger', label: 'Action Trigger', width: '30%' },
  { key: 'id', label: 'ID', width: '8rem' },
  {
    key: 'phone_number',
    label: 'Phone Number',
    width: '30%',
    wrapValue: false,
  },
  { key: 'actions', label: '', width: '20%', disableSorting: true },
];

const actionIcons = {
  [ActionType.EndConversation]: 'end-conversation.svg',
  [ActionType.TransferCall]: 'transfer-calls.svg',
  [ActionType.SetHold]: 'place-on-hold.svg',
  [ActionType.AddToConference]: 'add-multiple-participants.svg',
  [ActionType.DTMF]: 'dtmf.svg',
  [ActionType.External]: 'custom-external-actions.svg',
};

const actionIconsSize = {
  [ActionType.EndConversation]: 'w-[22.4px] h-[8.7px]',
  [ActionType.TransferCall]: 'w-[24px] h-[24px]',
  [ActionType.SetHold]: 'w-[12px] h-[14px]',
  [ActionType.AddToConference]: 'w-[21px] h-[20px]',
  [ActionType.DTMF]: 'w-[25px] h-[25px]',
  [ActionType.External]: 'w-[18.12px] h-[18px]',
};

const actionLabels: Record<ActionType, string> = actions.reduce(
  (acc, action) => ({
    ...acc,
    [action.value]: action.label,
  }),
  {} as Record<ActionType, string>,
);

export const ListActions = () => {
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [isExternal, setIsExternal] = useState(
    searchParams.get('tab') === 'external',
  );

  const [rows, setRows] = useState<Action[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] = useState<ActionPayload>(newAction);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });
  const [inputsWithError, setInputsWithError] = useState<string[]>([]);
  const [showActionForm, setShowActionForm] = useState(false);

  const fetchData = useCallback(
    async (page: number, perPage: number) => {
      setLoading(true);
      setEquivalentCodeOpen(false);

      const queryObject: QueryObject = {
        page: String(page),
        size: String(perPage),
        filters: JSON.stringify({
          ...(filter.length > 0 && { label: filter }),
          type: isExternal ? 'action_external' : '!action_external',
          scope: 'global|user',
        }),
      };

      if (sortConfig.key) {
        queryObject.sort_column = sortConfig.key;
        queryObject.sort_desc =
          sortConfig.direction === 'desc' ? 'true' : 'false';
      }

      const query = new URLSearchParams(
        queryObject as unknown as Record<string, string>,
      );

      const data = await get(`/actions?${query.toString()}`, {
        envId,
      });

      const items = data.items;

      setRows(items);
      setTotalItems(data.total);
      setLoading(false);
    },
    [currentPage, sortConfig, debouncedFilter, isExternal, envId],
  );

  const fetchLastNumbers = async (search: string) => {
    try {
      const data = await get(`/numbers/recent?number=${search}`, {
        envId,
      });
      return data.map((i: { id: string; number: string }) => ({
        id: i.id,
        label: i.number,
        value: i.number,
      }));
    } catch (error) {
      console.error('Failed to fetch last numbers:', error);
      return [];
    }
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig, debouncedFilter, isExternal, fetchData, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const resetForm = () => {
    setForm(newAction);
  };

  const openModalEditAction = (action: Action) => {
    setModalOpen(true);
    setSelectedId(action.id);

    if (action) {
      setForm({
        ...action,
        ...(action.config.input_schema && {
          config: {
            ...action.config,
            input_schema: JSON.stringify(action.config.input_schema),
          },
        }),
      });
    } else {
      notification.error('Action not found');
    }
  };

  const openDeleteAction = (action: Action) => {
    setModalDeleteOpen(true);
    setSelectedId(action.id);
  };

  const closeModal = () => {
    setEquivalentCodeOpen(false);
    setModalOpen(false);
    setSelectedId('');
    setShowActionForm(false);
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    // Deep clone the form to avoid mutating the original object
    const cloneForm = JSON.parse(JSON.stringify(form)) as ActionPayload;

    try {
      if (cloneForm.type === ActionType.External) {
        cloneForm.label = cloneForm.config.name;
        cloneForm.description = cloneForm.config.description;
      }

      if (cloneForm.config.input_schema) {
        try {
          cloneForm.config.input_schema = JSON.parse(
            cloneForm.config.input_schema,
          );
        } catch {
          notification.error('Invalid JSON in input schema');
          setLoading(false);
          return;
        }
      }

      if (selectedId) {
        await patch(`/actions/${selectedId}`, cloneForm, {
          envId,
        });
        notification.success('Action updated successfully');
      } else {
        await post('/actions', cloneForm, {
          envId,
        });
        notification.success('Action created successfully');
      }

      resetForm();
      fetchData(currentPage, size);
      closeModal();
    } catch {
      notification.error('Failed to save action');
    } finally {
      setLoading(false);
    }
  };

  const handleEndpointAction = () => {
    if (selectedId) {
      return `actions/update?id=${selectedId}`;
    } else {
      return 'actions/create';
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidHttpUrl(e.target.value)) {
      setInputsWithError([...inputsWithError, 'url']);
      setForm({
        ...form,
        config: {
          ...form.config,
          url: '',
        },
      });
    } else {
      setInputsWithError(inputsWithError.filter((input) => input !== 'url'));
    }
  };

  const isFieldEmpty = (field: string | undefined) => !field || field === '';

  const isValidForm = (form: ActionPayload) => {
    const { action_trigger, config, type } = form;

    if (
      action_trigger.type === TriggerType.PhraseBased &&
      (!action_trigger.config ||
        !action_trigger.config.phrase_triggers ||
        isFieldEmpty(action_trigger.config.phrase_triggers[0]?.phrase))
    ) {
      return true;
    }

    if (needsPhoneNumber && isFieldEmpty(config.phone_number)) {
      return true;
    }

    if (type === ActionType.External) {
      const requiredFields = [
        config.name,
        config.description,
        config.url,
        config.input_schema,
      ];

      if (
        requiredFields.some(isFieldEmpty) ||
        config.speak_on_send === undefined ||
        config.speak_on_receive === undefined ||
        !isValidHttpUrl(String(config.url))
      ) {
        return true;
      }
    }

    return false;
  };

  const disabledSubmit = () => {
    if (loading) return true;

    return isValidForm(form);
  };

  const handleRemoveAction = async () => {
    setLoading(true);
    try {
      await remove(`/actions/${selectedId}`, { envId });
      notification.success('Action deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete action');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [form, equivalentCodeOpen, loading]);

  const needsPhoneNumber =
    form.type === ActionType.TransferCall ||
    form.type === ActionType.AddToConference;

  const canChangeTrigger = form.type !== ActionType.External;

  useEffect(() => {
    if (selectedId === '') {
      const resetedForm: ActionPayload = {
        type: form.type,
        config: {},
        action_trigger: {
          type: TriggerType.FunctionCall,
          config: {},
        },
      };

      if (form.type === ActionType.External) {
        resetedForm.config = {
          name: '',
          description: '',
          url: '',
          speak_on_send: false,
          speak_on_receive: false,
          input_schema: '',
          processing_mode: 'muted',
          signature_secret: '',
        };
      }

      setForm(resetedForm);
    }
  }, [form.type, selectedId]);

  useEffect(() => {
    if (form.type === ActionType.External && selectedId === '') {
      setForm({
        ...form,
        config: {
          ...form.config,
          speak_on_receive: false,
          speak_on_send: false,
        },
      });
    }
  }, [modalOpen]);

  const getActionDescription = (type: ActionType) => {
    switch (type) {
      case ActionType.EndConversation:
        return 'Configure when the agent should end the call.';
      case ActionType.DTMF:
        return 'Allow the bot to send keypresses during a call.';
      case ActionType.TransferCall:
        return 'Configure when the agent should transfer the call to another number.';
      case ActionType.AddToConference:
        return 'This action helps add multiple participants to a call and merges individual calls into a single conference, allowing group communication.';
      case ActionType.SetHold:
        return 'This action places an active call on hold, pausing the conversation so neither party can hear each other until the call is resumed.';
      case ActionType.External:
        return 'Trigger your own APIs during a call.';
      default:
        return '';
    }
  };

  return (
    <div className="flex-1">
      <Heading
        title="Actions"
        subtitle="Actions give you control in the middle of a conversation, allowing dynamic behavior."
        className="border-b-0"
      >
        <div className="flex mt-6">
          <Button
            className="w-42 border-none flex items-center justify-center text-gray-600"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/actions"
            target="_blank"
          >
            View help doc
            <FileText className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <RadioTab
        onChange={(index) => {
          setIsExternal(index === 'external');
        }}
        items={[
          { label: 'Phone Actions', value: 'phone' },
          {
            label: 'Custom External Actions',
            value: 'external',
          },
        ]}
        className="pl-2"
        value={isExternal ? 'external' : 'phone'}
      />

      <ContentWrapper>
        <div className="flex flex-col md:flex-row justify-between mb-6">
          <div className="flex w-full md:w-96 self-end">
            <Input
              type="text"
              required
              placeholder="Search actions"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  fetchData(currentPage, size);
                }
              }}
              icon={<MagnifyingGlass size={20} />}
              iconPosition="left"
              inputAction={() => fetchData(currentPage, size)}
            />
          </div>

          <Button
            className="mt-4 md:mt-0 h-auto"
            onClick={() => setShowActionForm(true)}
          >
            Create Action
          </Button>
        </div>

        <h2 className="text-2xl font-medium mb-4">
          {isExternal ? (
            <div className="flex items-center">
              <img
                src="/assets/actions/modal/custom-external-actions.svg"
                alt="External"
                className="w-6 h-6 mr-2"
              />
              <span>Custom External Actions</span>
            </div>
          ) : (
            'Phone Actions'
          )}
        </h2>

        <Table
          headers={headers}
          rows={
            rows.map((item: Action) => ({
              ...item,
              id: (
                <CopyableField
                  className="text-neutral-500"
                  value={item.id}
                  notification={notification}
                />
              ),
              label: item.label || 'Untitled',
              description: (
                <div className="text-neutral-500">
                  {limitString(item.description || '', 80)}
                </div>
              ),
              type: (
                <div className="flex">
                  {!isExternal && (
                    <div className="w-[50px] h-[50px] bg-gray-300/25 rounded-lg flex items-center justify-center">
                      <img
                        src={`/assets/actions/modal/${actionIcons[item.type]}`}
                        alt={actionLabels[item.type]}
                        className={clsx(
                          'flex select-none pointer-events-none',
                          actionIconsSize[item.type],
                        )}
                      />
                    </div>
                  )}
                  <div className="ml-2">
                    <div className="text-medium strong text-black">
                      {item.label || 'Untitled'}
                    </div>
                    <div className="text-[14px] text-gray-600">
                      {actionLabels[item.type]}
                    </div>
                  </div>
                </div>
              ),
              action_trigger: (
                <div
                  className={clsx(
                    'inline-flex px-3 pt-1.5 pb-1 text-black rounded-md',
                    item.action_trigger.type === TriggerType.FunctionCall &&
                      'bg-badge-secondary',
                    item.action_trigger.type === TriggerType.PhraseBased &&
                      'bg-badge-primary',
                  )}
                >
                  {triggerTypes.find(
                    (trigger) => trigger.value === item.action_trigger.type,
                  )?.label || item.action_trigger.type}
                </div>
              ),
              phone_number: (
                <div className="text-neutral-500">
                  {item.config.phone_number
                    ? processPhoneNumber(item.config.phone_number)
                    : ''}
                </div>
              ),
              actions: item.user_id && (
                <div className="flex justify-center items-center">
                  <SideDropActions
                    options={[
                      {
                        label: 'Edit',
                        Icon: Pencil,
                        onClick: () => openModalEditAction(item),
                      },
                      {
                        label: 'Delete',
                        Icon: Trash,
                        color: 'error',
                        onClick: () => openDeleteAction(item),
                      },
                    ]}
                  />
                </div>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title="Let's create a new action."
        isOpen={showActionForm}
        onClose={() => closeModal()}
        actionButton={
          <Button
            onClick={() => {
              setModalOpen(true);
              setShowActionForm(false);
            }}
            disabled={!form.type}
          >
            Continue
          </Button>
        }
      >
        <p className="mb-4">Specify how this action will take place.</p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {actionsModal
            .filter((action) => !action.disabled)
            .map((action) => (
              <div
                key={action.value}
                className={clsx(
                  'border rounded-lg cursor-pointer',
                  'hover:border-primary/25 hover:shadow-md',
                  form.type === action.value
                    ? 'p-1 action-box-selected rounded-lg shadow-md'
                    : 'p-3 m-1 border-gray-200',
                )}
                onClick={() => setForm({ ...form, type: action.value })}
              >
                <div
                  className={clsx(
                    'bg-white',
                    form.type === action.value && 'p-3 rounded-md h-full',
                  )}
                >
                  <div className="flex items-start mb-2">
                    <div className="flex mr-2 h-6 justify-center items-center">
                      <img
                        src={`/assets/actions/modal/${actionIcons[action.value]}`}
                        alt={actionLabels[action.value]}
                        className={clsx(
                          'flex select-none pointer-events-none',
                          actionIconsSize[action.value],
                        )}
                      />
                    </div>
                    <p className="font-regular">{action.label}</p>
                  </div>
                  <p className="text-sm text-gray-600">
                    {getActionDescription(action.value)}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </Modal>

      <Modal
        title={
          <div className="flex items-center">
            <div className="mr-3">
              <img
                src={`/assets/actions/modal/${actionIcons[form.type]}`}
                alt={actionLabels[form.type]}
                className={clsx(
                  'flex select-none pointer-events-none',
                  actionIconsSize[form.type],
                )}
              />
            </div>
            {selectedId
              ? `Edit Action - ${actionLabels[form.type]}`
              : form.type === ActionType.External
                ? 'Custom External Actions'
                : actionLabels[form.type]}
          </div>
        }
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button onClick={submitModal} disabled={disabledSubmit()}>
            {selectedId ? 'Save Action' : 'Create Action'}
          </Button>
        }
      >
        <Tab
          panelClassName="focus:outline-none"
          items={[
            {
              label: 'Customize',
              content: (
                <div className="p-2">
                  <div className="flex justify-end italic text-sm mt-4">
                    * Required fields
                  </div>

                  {form.type === ActionType.External && (
                    <div className="mb-6">
                      <Input
                        label="What is the action name?"
                        type="text"
                        id="name"
                        placeholder="Enter Action Name"
                        value={form.config.name}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            config: {
                              ...form.config,
                              name: e.target.value as string,
                            },
                          })
                        }
                        disabled={loading}
                        required
                      />
                    </div>
                  )}

                  {form.type !== ActionType.External && (
                    <div className="mb-6 w-full md:w-[60%] lg:w-[35%] min-w-[300px]">
                      <Input
                        label="What is the action name?"
                        type="text"
                        id="label"
                        placeholder="Enter Action Name"
                        value={form.label}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            label: e.target.value as string,
                          })
                        }
                        disabled={loading}
                      />
                    </div>
                  )}

                  {form.type === ActionType.External && (
                    <div className="mb-6">
                      <Input
                        label="How should this action be triggered?"
                        type="text"
                        id="description"
                        placeholder="Ex: 'If the user asks to create an appointment, then this action would be triggered'"
                        value={form.config.description}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            config: {
                              ...form.config,
                              description: e.target.value as string,
                            },
                          })
                        }
                        disabled={loading}
                        required
                      />
                    </div>
                  )}

                  {form.type !== ActionType.External && (
                    <div className="mb-6 w-full md:w-[60%] lg:w-[35%] min-w-[300px]">
                      <Label htmlFor="description">
                        How can we describe this action?
                      </Label>
                      <Textarea
                        id="description"
                        placeholder="Ex: this action is used to create an appointment"
                        value={form.description}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            description: e.target.value,
                          })
                        }
                        disabled={loading}
                        rows={4}
                      />
                    </div>
                  )}

                  {form.type === ActionType.External && (
                    <div className="mb-6">
                      <Input
                        label="What is the API URL?"
                        type="text"
                        id="url"
                        placeholder="Ex: https://www.google.com/maps/search/?api=1&parameters"
                        className={clsx(
                          'w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-300',
                          inputsWithError.includes('url') && 'border-red-500',
                        )}
                        value={form.config.url}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            config: {
                              ...form.config,
                              url: e.target.value as string,
                            },
                          })
                        }
                        onBlur={handleUrlChange}
                        disabled={loading}
                        required
                      />

                      {inputsWithError.includes('url') && (
                        <span className="text-red-500 text-xs font-bold">
                          Please enter a valid URL
                        </span>
                      )}
                    </div>
                  )}

                  {form.type === ActionType.External && (
                    <div className="mb-6">
                      <Input
                        label="Signature Secret"
                        type="text"
                        id="signature_secret"
                        placeholder="Ex: 1234567890"
                        value={form.config.signature_secret}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            config: {
                              ...form.config,
                              signature_secret: e.target.value as string,
                            },
                          })
                        }
                        disabled={loading}
                      />
                    </div>
                  )}

                  {form.type === ActionType.External && (
                    <div>
                      <div className="mb-6 flex items-center justify-between">
                        <div>
                          <Label
                            required
                            htmlFor="speak_on_send"
                            className="mb-0"
                          >
                            Speak on Send
                          </Label>

                          <span className="text-sm text-gray-600">
                            Toggle On to allow the Voice AI Agent to respond to
                            the caller before an external action starts.
                          </span>
                        </div>

                        <Toggle
                          initialState={form.config.speak_on_send}
                          onChange={(value) =>
                            setForm({
                              ...form,
                              config: { ...form.config, speak_on_send: value },
                            })
                          }
                          disabled={loading}
                        />
                      </div>

                      <div className="mb-6 flex items-center justify-between">
                        <div>
                          <Label required htmlFor="speak_on_receive">
                            Speak on Receive
                          </Label>

                          <span className="text-sm text-gray-600">
                            Toggle On to allow the the Voice AI Agent responds
                            immediately when it receives data or a result from
                            an external action
                          </span>
                        </div>

                        <Toggle
                          initialState={form.config.speak_on_receive}
                          onChange={(value) =>
                            setForm({
                              ...form,
                              config: {
                                ...form.config,
                                speak_on_receive: value,
                              },
                            })
                          }
                          disabled={loading}
                        />
                      </div>
                    </div>
                  )}

                  {form.type === ActionType.External && (
                    <div className="mb-6">
                      <Label htmlFor="processing_mode">Processing Mode</Label>
                      <SelectBox
                        key={form.config.processing_mode}
                        options={[{ label: 'Muted', value: 'muted' }]}
                        defaultValue={{ label: 'Muted', value: 'muted' }}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onChange={(value) =>
                          setForm({
                            ...form,
                            config: {
                              ...form.config,
                              processing_mode: value as ProcessingMode,
                            },
                          })
                        }
                        className="min-w-[140px]"
                        disabled={loading}
                      />
                    </div>
                  )}

                  {form.type === ActionType.External && (
                    <div className="mb-6">
                      <Label htmlFor="input_schema">Input Schema</Label>
                      <SchemaBuilder
                        value={form.config.input_schema || ''}
                        onChange={(schema) =>
                          setForm({
                            ...form,
                            config: {
                              ...form.config,
                              input_schema: schema,
                            },
                          })
                        }
                      />
                    </div>
                  )}

                  <div>
                    {needsPhoneNumber && (
                      <div className="mb-6 w-full md:w-[60%] lg:w-[35%] min-w-[300px]">
                        <AutoCompleteInput
                          label={
                            form.type === ActionType.TransferCall
                              ? 'What is the transfer number?'
                              : 'What is the number to dial?'
                          }
                          id="phone-number"
                          placeholder={
                            form.type === ActionType.TransferCall
                              ? 'Enter Transfer Number'
                              : 'Enter Number to Dial'
                          }
                          value={form.config.phone_number || ''}
                          onChange={(value) =>
                            setForm({
                              ...form,
                              config: {
                                ...form.config,
                                phone_number: value as string,
                              },
                            })
                          }
                          onRemove={(value) =>
                            remove(`/numbers/recent/${value}`, { envId })
                          }
                          fetchOptions={fetchLastNumbers}
                          disabled={loading}
                          required
                        />
                      </div>
                    )}
                    {canChangeTrigger && (
                      <div className="mb-6">
                        <Label required htmlFor="action-trigger">
                          What should trigger this action?
                        </Label>

                        <div className="flex flex-wrap gap-4">
                          {triggerTypes.map((trigger) => (
                            <div
                              key={trigger.value}
                              className={clsx(
                                'border rounded-lg cursor-pointer w-full md:w-[60%] lg:w-[35%] min-w-[300px]',
                                'hover:border-primary/25 hover:shadow-md',
                                form.action_trigger.type === trigger.value
                                  ? 'p-1 action-box-selected rounded-lg shadow-md'
                                  : 'p-3 m-1 border-gray-200',
                                form.type === ActionType.DTMF &&
                                  trigger.value === TriggerType.PhraseBased &&
                                  'opacity-50 cursor-not-allowed',
                              )}
                              onClick={() => {
                                if (
                                  form.type === ActionType.DTMF &&
                                  trigger.value === TriggerType.PhraseBased
                                ) {
                                  return;
                                }

                                setForm({
                                  ...form,
                                  action_trigger: { type: trigger.value },
                                });
                              }}
                            >
                              <div
                                className={clsx(
                                  'bg-white',
                                  form.action_trigger.type === trigger.value &&
                                    'p-3 rounded-md h-full',
                                )}
                              >
                                <div className="flex items-start mb-2 justify-between">
                                  <p className="font-regular">
                                    {trigger.label}
                                  </p>

                                  <div
                                    className={clsx(
                                      'w-[18px] h-[18px] rounded-full border b-1 border-black',
                                      form.action_trigger.type === trigger.value
                                        ? 'bg-primary/25'
                                        : 'bg-white',
                                    )}
                                  ></div>
                                </div>
                                <p className="text-sm text-gray-600">
                                  {trigger.description}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {form.type === ActionType.AddToConference && (
                      <div className="mb-6 flex items-center justify-between w-full">
                        <div>
                          <Label
                            required
                            htmlFor="place_primary_on_hold"
                            className="mb-0"
                          >
                            Place Primary on Hold
                          </Label>

                          <span className="text-sm text-gray-600">
                            Toggle On to allow the Voice AI Agent to place the
                            primary on hold.
                          </span>
                        </div>

                        <Toggle
                          initialState={form.config.place_primary_on_hold}
                          onChange={(value) =>
                            setForm({
                              ...form,
                              config: {
                                ...form.config,
                                place_primary_on_hold: value,
                              },
                            })
                          }
                          disabled={loading}
                        />
                      </div>
                    )}
                    {form.action_trigger.type === TriggerType.PhraseBased && (
                      <div className="mb-6 w-full md:w-[60%] lg:w-[35%] min-w-[300px]">
                        <Input
                          label="What is the phrase to trigger on?"
                          type="text"
                          id="phrase-trigger"
                          placeholder="Ex: 'Transfer me'"
                          value={
                            form.action_trigger.config?.phrase_triggers?.[0]
                              ?.phrase
                          }
                          onChange={(e) =>
                            setForm({
                              ...form,
                              action_trigger: {
                                ...form.action_trigger,
                                config: {
                                  ...form.config,
                                  phrase_triggers: [
                                    {
                                      phrase: e.target.value as string,
                                      conditions: [
                                        PhraseTriggerConditionType.PhraseConditionTypeContains,
                                      ],
                                    },
                                  ],
                                },
                              },
                            })
                          }
                          disabled={loading}
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
              ),
            },
            {
              label: (
                <div className="flex items-center">
                  <Code className="mr-2" size={20} />
                  Show equivalent code
                </div>
              ),
              content: (
                <EquivalentCode
                  payload={form}
                  endpoint={handleEndpointAction()}
                  method="POST"
                />
              ),
            },
          ]}
        />
      </Modal>

      <Modal
        title="Delete Action"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button onClick={handleRemoveAction}>Delete Action</Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this action?
        </div>
      </Modal>
    </div>
  );
};
