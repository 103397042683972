import { Phone, PhoneDisconnect } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { post } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { WebsocketClient } from 'src/components/WebsocketClient/client';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Agent } from 'src/interfaces/agent.interface';
import { useDraggable } from 'src/hooks/useDraggable';
import { AnimatedCircles } from './AnimatedCircles';
import { Tooltip } from 'src/components/Tooltip';

const CONSTANTS = {
  MODAL_WIDTH: 308,
  MODAL_HEIGHT: 400,
  AUDIO_LEVEL_MULTIPLIER: 700,
  CALL_END_DELAY: 500,
  INITIAL_POSITION: {
    x: window.innerWidth - 308,
    y: window.innerHeight * 0.1,
  },
} as const;

interface AgentCallerProps {
  agent: Agent;
  isEditing: boolean;
  onCallEnd?: () => void;
  onCallStart?: () => void;
  customControls?: React.ReactNode;
  isEmbedded?: boolean;
}

export const AgentCaller = ({
  agent,
  isEditing,
  onCallEnd,
  onCallStart,
  customControls,
  isEmbedded = false,
}: AgentCallerProps) => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const startCallRef = useRef<HTMLAudioElement>(null);
  const endCallRef = useRef<HTMLAudioElement>(null);

  const websocketUrl = process.env.REACT_APP_CALL_SERVER_WEBSOCKET_URL;

  const [websocketClient, setWebsocketClient] =
    useState<WebsocketClient | null>(null);

  const [isCalling, setIsCalling] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const [isMaximized, setIsMaximized] = useState(!isEmbedded);

  const draggable = useDraggable({
    initialPosition: CONSTANTS.INITIAL_POSITION,
    bounds: {
      maxX: window.innerWidth - CONSTANTS.MODAL_WIDTH,
      maxY: window.innerHeight - CONSTANTS.MODAL_HEIGHT,
    },
    enabled: !isMaximized,
  });

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const startCall = async () => {
    try {
      const call = await post(
        `/calls`,
        {
          agent: agent?.id,
          from_number: 'web',
          to_number: 'web',
        },
        { envId },
      );

      if (!call) {
        notification.error('No response received from call creation');
        return;
      }

      if (!call.telephony_id) {
        notification.error('Call created but no telephony ID received');
        return;
      }

      startCallRef.current?.play();

      const newWs = new WebSocket(String(websocketUrl));

      setWebsocketClient(
        new WebsocketClient({
          socket: newWs,
          callSid: call.telephony_id,
          setAudioLevel: (level: number) => {
            setAudioLevel(Math.ceil(level * 700));
          },
        }),
      );

      setIsCalling(true);
      onCallStart?.();
    } catch (error) {
      notification.error(
        'Failed to start call: ' +
          (error instanceof Error ? error.message : 'Unknown error'),
      );
    }
  };

  const endCall = async () => {
    if (websocketClient) {
      websocketClient.close();
      setWebsocketClient(null);
    }

    endCallRef.current?.play();

    setTimeout(() => {
      setIsCalling(false);
      onCallEnd?.();
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (websocketClient) {
        websocketClient.close();
        setWebsocketClient(null);
      }
      if (isCalling) {
        endCallRef.current?.play();
        setIsCalling(false);
      }
    };
  }, [websocketClient, isCalling]);

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center p-4 md:p-8 lg:p-16 w-full relative',
        !isEmbedded && 'h-full',
      )}
      style={{
        backgroundImage: !isEmbedded
          ? `url(/assets/agents/bg-call.png)`
          : 'none',
        backgroundRepeat: 'repeat',
        background: !isEmbedded
          ? `linear-gradient(345.95deg, rgba(221, 214, 255, 0.13) 14.69%, rgba(255, 190, 244, 0.13) 31.81%, rgba(255, 209, 203, 0.13) 54.58%, rgba(255, 250, 231, 0.13) 75.9%, rgba(213, 255, 237, 0.13) 93.27%), url(/assets/agents/bg-call.png)`
          : 'none',
        backgroundBlendMode: 'overlay',
      }}
    >
      <audio
        ref={startCallRef}
        preload="auto"
        src="/assets/agents/start-call.mp3"
        className="hidden"
      />

      <audio
        ref={endCallRef}
        preload="auto"
        src="/assets/agents/end-call.mp3"
        className="hidden"
      />

      {!isEmbedded && isCalling && (
        <div
          className={clsx(
            'fixed inset-0 w-full h-full z-50',
            isMaximized ? 'bg-black/50' : 'pointer-events-none',
          )}
        >
          <div
            className={clsx(
              'bg-neutral-925',
              'fixed',
              'flex',
              'flex-col',
              isMaximized
                ? [
                    'min-h-[90vh]',
                    'min-w-[80vw]',
                    'top-1/2',
                    'left-1/2',
                    '-translate-x-1/2',
                    '-translate-y-1/2',
                    'rounded-3xl',
                  ]
                : [
                    'h-[400px]',
                    'w-[300px]',
                    'pointer-events-auto',
                    draggable.isDragging ? 'cursor-grabbing' : 'cursor-grab',
                    'rounded-xl',
                  ],
            )}
            style={
              !isMaximized
                ? {
                    top: `${draggable.position.y}px`,
                    left: `${draggable.position.x}px`,
                    transition: draggable.isDragging
                      ? 'none'
                      : 'all 0.2s ease-in-out',
                  }
                : undefined
            }
            {...(!isMaximized ? draggable.handlers : {})}
          >
            <div className="flex justify-end p-4 gap-4">
              {!isEmbedded && (
                <Tooltip content="Maximize" theme="light" position="bottom">
                  <button
                    type="button"
                    className="bg-transparent border-0 active:opacity-70"
                    onClick={toggleMaximize}
                  >
                    <img
                      src="/assets/agents/maximize.svg"
                      className="w-6 h-6 select-none"
                      alt="Maximize"
                      style={{
                        transform: isMaximized
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                    />
                  </button>
                </Tooltip>
              )}

              <Tooltip content="End Call" theme="light" position="bottom">
                <button
                  type="button"
                  className="bg-transparent border-0 active:opacity-70"
                  onClick={endCall}
                >
                  <img
                    src="/assets/agents/cancel.svg"
                    className="w-6 h-6 select-none"
                    alt="Cancel"
                  />
                </button>
              </Tooltip>
            </div>

            <div className="container mx-auto flex-1">
              <div
                className={clsx(
                  'flex flex-col items-center justify-center',
                  isMaximized ? 'p-8 min-h-[80vh]' : 'p-4',
                )}
              >
                <AnimatedCircles
                  isMaximized={isMaximized}
                  isCalling={isCalling}
                  audioLevel={audioLevel}
                  variant={isMaximized ? 'large' : 'small'}
                />

                <p
                  className={clsx(
                    'text-white mt-6',
                    isMaximized ? 'text-2xl mb-12' : 'text-xl mb-6 text-center',
                  )}
                >
                  {agent?.name || 'Agent'} is{' '}
                  {isCalling
                    ? audioLevel > 1
                      ? 'speaking...'
                      : 'listening...'
                    : 'waiting for your call...'}
                </p>
              </div>
            </div>

            <div
              className={clsx(
                'bg-neutral-715',
                isMaximized ? 'p-4 rounded-b-3xl' : 'p-3 rounded-b-xl',
              )}
            >
              <div className="flex justify-center">
                {!isCalling && (
                  <button
                    className={clsx(
                      'rounded-lg bg-primary text-white flex items-center justify-center gap-2',
                      isMaximized ? 'px-6 py-3' : 'px-4 py-2 text-sm w-full',
                    )}
                    onClick={startCall}
                  >
                    <PhoneDisconnect size={isMaximized ? 20 : 16} />
                    Test Call Agent
                  </button>
                )}

                {isCalling && (
                  <button
                    className={clsx(
                      'rounded-lg bg-red-500 text-white flex items-center justify-center gap-2',
                      isMaximized ? 'px-6 py-3' : 'px-4 py-2 text-sm w-full',
                    )}
                    onClick={endCall}
                  >
                    <PhoneDisconnect size={isMaximized ? 20 : 16} />
                    End Call
                  </button>
                )}
              </div>
            </div>

            {customControls}
          </div>
        </div>
      )}

      {isEmbedded && (
        <div className="flex flex-col items-center justify-center w-full min-h-[60vh]">
          <div className="container mx-auto flex-1 mt-12">
            <div
              className={clsx(
                'flex flex-col items-center justify-center p-8 m-2',
              )}
            >
              <AnimatedCircles
                isMaximized={true}
                isCalling={isCalling}
                audioLevel={audioLevel}
                variant="large"
              />

              <p className="text-2xl text-white mt-6 mb-6">
                {agent?.name || 'Agent'} is{' '}
                {isCalling
                  ? audioLevel > 1
                    ? 'speaking...'
                    : 'listening...'
                  : 'waiting for your call...'}
              </p>

              <div className="flex justify-center gap-4">
                {!isCalling && (
                  <button
                    className="px-6 py-3 rounded-lg bg-primary text-white flex items-center gap-2"
                    onClick={startCall}
                  >
                    <PhoneDisconnect size={20} />
                    Test Call Agent
                  </button>
                )}

                {isCalling && (
                  <button
                    className="px-6 py-3 rounded-lg bg-red-500 text-white flex items-center gap-2"
                    onClick={endCall}
                  >
                    <PhoneDisconnect size={20} />
                    End Call
                  </button>
                )}

                {customControls}
              </div>
            </div>
          </div>
        </div>
      )}

      {!isEmbedded && !isCalling && agent?.id && (
        <div
          className="flex w-full p-4 flex-col items-center gap-6 rounded-2xl bg-white"
          style={{
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <div className="relative w-full flex flex-col items-center justify-center py-2">
            <AnimatedCircles
              isMaximized={false}
              isCalling={isCalling}
              audioLevel={audioLevel}
              variant="small"
            />

            <div className="flex flex-col items-center gap-2 mt-6 w-full">
              <span className="text-neutral-715 text-center">{agent.name}</span>

              {!isEditing && (
                <span className="text-neutral-715 text-center">
                  Save the agent first to test it.
                </span>
              )}

              {isEditing && (
                <Button
                  onClick={isCalling ? endCall : startCall}
                  className="flex items-center gap-2 w-full"
                  color={isCalling ? 'error' : 'success'}
                >
                  {isCalling ? (
                    <PhoneDisconnect size={20} />
                  ) : (
                    <Phone size={20} />
                  )}
                  {isCalling ? 'End Call' : 'Test Call Agent'}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
