import { Checkbox } from '@headlessui/react';
import {
  Code,
  FileText,
  MagnifyingGlass,
  Pencil,
  Trash,
  X,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { AccountConnection } from 'src/interfaces/accountConnection.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import {
  RimeVoiceDto,
  Voice,
  VoicePayload,
} from 'src/interfaces/voice.interface';
import { testVoice } from '../../api/providerConnection';
import { listenVoice } from '../../api/voice';
import { Textarea } from 'src/components/Textarea';

const voices = [
  { label: 'Azure', value: 'voice_azure', is_import_enabled: true },
  { label: 'Eleven Labs', value: 'voice_eleven_labs', is_import_enabled: true },
  { label: 'Play HT', value: 'voice_play_ht', is_import_enabled: true },
  { label: 'Rime', value: 'voice_rime', is_import_enabled: true },
  { label: 'Cartesia', value: 'voice_cartesia', is_import_enabled: true },
  { label: 'Google', value: 'voice_google', is_import_enabled: false },
];

enum PlayHTVoiceEngine {
  PLAY_3MINI = '3-mini', // This value is for backwards compatibility.
  PLAY_DIALOG = 'PlayDialog',
}

const PlayHTVoiceEngineLabels: { [key in PlayHTVoiceEngine]: string } = {
  [PlayHTVoiceEngine.PLAY_3MINI]: 'Play 3.0 Mini',
  [PlayHTVoiceEngine.PLAY_DIALOG]: 'PlayDialog',
};

const VOICE_SOURCES = [
  { label: 'All', value: '' },
  { label: 'User', value: 'user' },
  { label: 'Fluents', value: 'fluents' },
];

const DEFAULT_PLAYHT_VOICE_ENGINE = PlayHTVoiceEngine.PLAY_3MINI;

const newVoice: VoicePayload = {
  type: 'voice_azure',
  voice_name: '',
  label: '',
  description: '',
  rate: 0,
  pitch: 0,
  version: '',
  speed_alpha: 1,
  model_id: 'mistv2',
};

const voicesAndProviders = [
  { voiceType: 'voice_azure', providerType: 'account_connection_azure_speech' },
  {
    voiceType: 'voice_eleven_labs',
    providerType: 'account_connection_elevenlabs',
  },
  { voiceType: 'voice_play_ht', providerType: 'account_connection_playht' },
  { voiceType: 'voice_rime', providerType: 'account_connection_rime' },
  { voiceType: 'voice_cartesia', providerType: 'account_connection_cartesia' },
];

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'type', label: 'Provider', width: '10%' },
  { key: 'label', label: 'Voice/Model', width: '10%' },
  { key: 'description', label: 'Description', width: '50%' },
  { key: 'source', label: 'Created By', width: '10%', disableSorting: true },
  { key: 'actions', label: '', width: '20%', disableSorting: true },
];

const EMOTION_NAMES = [
  { label: 'Anger', value: 'anger' },
  { label: 'Positivity', value: 'positivity' },
  { label: 'Surprise', value: 'surprise' },
  { label: 'Sadness', value: 'sadness' },
  { label: 'Curiosity', value: 'curiosity' },
];

const EMOTION_LEVELS = [
  { label: 'Lowest', value: 'lowest' },
  { label: 'Low', value: 'low' },
  { label: 'Moderate', value: '' },
  { label: 'High', value: 'high' },
  { label: 'Highest', value: 'highest' },
];

const convertToDto = (form: VoicePayload) => {
  switch (form.type) {
    case 'voice_azure':
      return {
        type: form.type,
        voice_name: form.voice_name,
        label: form.label,
        description: form.description,
        rate: form.rate,
        pitch: form.pitch,
      };
    case 'voice_eleven_labs':
      return {
        type: form.type,
        voice_id: form.voice_name,
        label: form.label,
        description: form.description,
        stability: form.stability,
        similarity_boost: form.similarity_boost,
        model_id: form.model_id,
        optimize_streaming_latency: form.optimize_streaming_latency,
      };
    case 'voice_play_ht':
      return {
        type: form.type,
        voice_id: form.voice_name,
        label: form.label,
        description: form.description,
        version: form.version,
        speed: form.speed,
      };
    case 'voice_rime':
      return {
        type: form.type,
        speaker: form.voice_name,
        label: form.label,
        description: form.description,
        speed_alpha: form.speed_alpha,
        model_id: form.model_id,
      };
    case 'voice_cartesia':
      return {
        type: form.type,
        voice_id: form.voice_name,
        label: form.label,
        description: form.description,
        model: form.model,
        speed: form.speed,
        emotion: form.emotion,
      };
    default:
      return form;
  }
};

const getPlaceholderByProvider = (type: string) => {
  switch (type) {
    case 'voice_azure':
      return 'e.g. en-US-JennyNeural';
    case 'voice_eleven_labs':
      return 'e.g. bIHbv24MWmeRgasZH58o';
    case 'voice_play_ht':
      return 'e.g. s3://voice-cloning-zero-shot/[id]/manifest.json';
    case 'voice_rime':
      return 'e.g. speaker_name';
    case 'voice_cartesia':
      return 'e.g. voice_id';
    default:
      return '';
  }
};

const getAdditionalFields = (
  type: string,
  form: VoicePayload,
  setForm: (form: VoicePayload) => void,
) => {
  switch (type) {
    case 'voice_azure':
      return (
        <>
          <div className="mb-6">
            <Label>Rate</Label>
            <SelectBox
              options={[
                { value: '0.5', label: 'x-slow (-50%)' },
                { value: '0.64', label: 'slow (-46%)' },
                { value: '1', label: 'medium (default)' },
                { value: '1.55', label: 'fast (+55%)' },
                { value: '2', label: 'x-fast (+100%)' },
              ]}
              defaultValue={{ value: '1', label: 'medium (default)' }}
              onChange={(value) =>
                setForm({
                  ...form,
                  rate: value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-6">
            <Label>Pitch</Label>
            <SelectBox
              options={[
                { value: '0.55', label: 'x-low (-45%)' },
                { value: '0.8', label: 'low (-20%)' },
                { value: '1', label: 'medium (default)' },
                { value: '1.2', label: 'high (+20%)' },
                { value: '1.45', label: 'x-high (+45%)' },
              ]}
              defaultValue={{ value: '1', label: 'medium (default)' }}
              onChange={(value) =>
                setForm({
                  ...form,
                  pitch: value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
        </>
      );
    case 'voice_play_ht':
      return (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="mb-6">
              <Label>Model</Label>
              <SelectBox
                options={[
                  {
                    value: PlayHTVoiceEngine.PLAY_DIALOG,
                    label:
                      PlayHTVoiceEngineLabels[PlayHTVoiceEngine.PLAY_DIALOG],
                  },
                  {
                    value: PlayHTVoiceEngine.PLAY_3MINI,
                    label:
                      PlayHTVoiceEngineLabels[PlayHTVoiceEngine.PLAY_3MINI],
                  },
                ]}
                defaultValue={{
                  value: DEFAULT_PLAYHT_VOICE_ENGINE,
                  label: PlayHTVoiceEngineLabels[DEFAULT_PLAYHT_VOICE_ENGINE],
                }}
                onChange={(value) =>
                  setForm({
                    ...form,
                    version: value,
                  })
                }
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="mb-6">
            <Label>
              Speed <i className="text-gray-400 ml-1">({form.speed})</i>
            </Label>
            <input
              type="range"
              step="0.1"
              min="0.1"
              max="5"
              value={form.speed}
              onChange={(e) =>
                setForm({
                  ...form,
                  speed: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb focus:ring-control-plane-300"
            />
          </div>
        </>
      );
    case 'voice_rime':
      return (
        <>
          <div className="mb-6">
            <Label>
              Speed Alpha{' '}
              <i className="text-gray-400 ml-1">({form.speed_alpha})</i>
            </Label>
            <input
              type="range"
              min="0.5"
              max="3"
              step="0.1"
              defaultValue="1.1"
              value={form.speed_alpha}
              onChange={(e) =>
                setForm({
                  ...form,
                  speed_alpha: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb focus:ring-control-plane-300"
            />

            <i className="text-gray-400">
              Lower speed values make speech faster, higher values make speech
              slower.
            </i>
          </div>
          <div className="mb-6">
            <Label>Model ID</Label>
            <SelectBox
              key={form.model_id + '_' + form.type}
              defaultValue={
                form.model_id
                  ? { value: form.model_id, label: form.model_id }
                  : { value: 'mistv2', label: 'Mist V2' }
              }
              options={[
                { value: 'mistv2', label: 'Mist V2' },
                { value: 'mist', label: 'Mist' },
                { value: 'v1', label: 'V1' },
              ]}
              onChange={(value) =>
                setForm({
                  ...form,
                  model_id: value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-300"
            />
          </div>
        </>
      );
    case 'voice_cartesia':
      return (
        <>
          <div className="mb-6">
            <Label>Model</Label>
            <input
              type="text"
              value={form.model || ''}
              onChange={(e) =>
                setForm({
                  ...form,
                  model: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-300"
              placeholder="e.g. model_name"
            />
          </div>

          <div className="mb-6">
            <Label>
              Speed <i className="text-gray-400 ml-1">({form.speed})</i>
            </Label>
            <input
              type="range"
              step="0.1"
              min="-1"
              max="1"
              value={form.speed}
              onChange={(e) =>
                setForm({
                  ...form,
                  speed: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb focus:ring-control-plane-300"
            />
          </div>

          <div className="mb-6">
            <Label>Emotions</Label>
            <div className="space-y-4">
              {(form.emotion || []).map((emotion, index) => {
                const [emotionName, emotionLevel] = emotion.split(':');
                return (
                  <div key={index} className="flex gap-2">
                    <SelectBox
                      options={EMOTION_NAMES}
                      defaultValue={EMOTION_NAMES.find(
                        (e) => e.value === emotionName,
                      )}
                      onChange={(value) => {
                        const newEmotions = [...(form.emotion || [])];
                        newEmotions[index] =
                          `${value}${emotionLevel ? `:${emotionLevel}` : ''}`;
                        setForm({
                          ...form,
                          emotion: newEmotions,
                        });
                      }}
                      className="flex-1"
                    />
                    <SelectBox
                      options={EMOTION_LEVELS}
                      defaultValue={EMOTION_LEVELS.find(
                        (e) => e.value === emotionLevel,
                      )}
                      onChange={(value) => {
                        const newEmotions = [...(form.emotion || [])];
                        newEmotions[index] =
                          `${emotionName}${value ? `:${value}` : ''}`;
                        setForm({
                          ...form,
                          emotion: newEmotions,
                        });
                      }}
                      className="flex-1"
                    />
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        const newEmotions = [...(form.emotion || [])];
                        newEmotions.splice(index, 1);
                        setForm({
                          ...form,
                          emotion: newEmotions,
                        });
                      }}
                    >
                      <X size={20} />
                    </Button>
                  </div>
                );
              })}
              <Button
                variant="outlined"
                onClick={() => {
                  setForm({
                    ...form,
                    emotion: [...(form.emotion || []), 'anger'],
                  });
                }}
                className="w-full"
              >
                Add Emotion
              </Button>
            </div>
          </div>
        </>
      );
    case 'voice_eleven_labs':
      return (
        <>
          <div className="mb-6">
            <Label>
              Stability <i className="text-gray-400 ml-1">({form.stability})</i>
            </Label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              defaultValue="0.5"
              value={form.stability}
              onChange={(e) =>
                setForm({
                  ...form,
                  stability: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
            />
          </div>

          <div className="mb-6">
            <Label>
              Similarity Boost{' '}
              <i className="text-gray-400 ml-1">({form.similarity_boost})</i>
            </Label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              defaultValue="1.0"
              value={form.similarity_boost}
              onChange={(e) =>
                setForm({
                  ...form,
                  similarity_boost: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
            />
          </div>

          <div className="mb-6">
            <Label>Model ID</Label>
            <SelectBox
              options={[
                { value: 'eleven_multilingual_v2', label: 'Multilingual v2' },
                { value: 'eleven_flash_v2_5', label: 'Flash v2.5' },
                { value: 'eleven_flash_v2', label: 'Flash v2' },
                {
                  value: 'eleven_multilingual_sts_v2',
                  label: 'Multilingual STS v2',
                },
                { value: 'eleven_english_sts_v2', label: 'English STS v2' },
                { value: 'eleven_turbo_v2_5', label: 'Turbo v2.5' },
                { value: 'eleven_turbo_v2', label: 'Turbo v2' },
              ]}
              onChange={(value) =>
                setForm({
                  ...form,
                  model_id: value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="mb-6">
            <Label>
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={form.optimize_streaming_latency || false}
                  onChange={(newChecked) =>
                    setForm({
                      ...form,
                      optimize_streaming_latency: newChecked,
                    })
                  }
                  className={`
                    ${form.optimize_streaming_latency ? 'bg-primary border-transparent' : 'bg-white border-gray-300'}
                    relative inline-flex h-5 w-5 items-center justify-center rounded border-2
                    transition-colors duration-200 ease-in-out
                    focus:outline-none
                  `}
                >
                  <span className="sr-only">Optimize Streaming Latency</span>
                  <span
                    className={`
                      pointer-events-none absolute h-3 w-3 transform
                      ${form.optimize_streaming_latency ? 'opacity-100' : 'opacity-0'}
                      transition-opacity duration-200 ease-in-out
                    `}
                  >
                    <svg
                      className="h-3 w-3 text-white"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M3 6l2 2 4-4"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Checkbox>
                <span className="text-sm text-gray-700">
                  Optimize Streaming Latency
                </span>
              </div>
            </Label>
          </div>
        </>
      );
    default:
      return null;
  }
};

export const ListVoices = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [rows, setRows] = useState<Voice[]>([]);
  const [filters, setFilters] = useState({
    type: '',
    label: '',
    source: '',
    scope: 'global|user',
  });
  const debouncedFilter = useDebounce(filters, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] = useState<VoicePayload>(newVoice);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const [providers, setProviders] = useState<AccountConnection[]>([]);
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [ttsModalOpen, setTtsModalOpen] = useState(false);
  const [ttsText, setTtsText] = useState('');
  const [selectedVoiceForTTS, setSelectedVoiceForTTS] = useState<Voice | null>(
    null,
  );

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);
    setEquivalentCodeOpen(false);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      filters: JSON.stringify({
        type: filters.type,
        label: filters.label,
        source: filters.source,
        scope: 'global|user',
      }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/voices?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    setRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const resetForm = () => {
    setForm(newVoice);
  };

  const cleanForm = (type: string) => {
    setForm({
      voice_name: '',
      type,
      ...(type === 'voice_cartesia' && {
        speed: 0,
      }),
      ...(type === 'voice_play_ht' && {
        version: DEFAULT_PLAYHT_VOICE_ENGINE,
        speed: 1,
      }),
      ...(type === 'voice_rime' && {
        speed_alpha: 1.1,
        model_id: 'mistv2',
      }),
      ...(type === 'voice_eleven_labs' && {
        stability: 0.5,
        similarity_boost: 1.0,
      }),
    });
  };

  const openModalNewVoice = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditVoice = async (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const voice = rows.find((item) => item.id === id);

    if (voice) {
      setForm({
        ...voice,
        voice_name:
          voice.voice_name || voice.voice_id || (voice as RimeVoiceDto).speaker,
      });
    }
  };

  const closeModal = () => {
    setEquivalentCodeOpen(false);
    setModalOpen(false);
    setSelectedId('');
    setForm(newVoice);
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    const isProviderConnected = await validateVoice(form.type, String(envId));

    if (!isProviderConnected) {
      setLoading(false);
      return;
    }

    if (selectedId) {
      await patch(`/voices/${selectedId}`, convertToDto(form), {
        envId,
      });
      notification.success('Voice updated successfully');
    } else {
      await post('/voices', convertToDto(form), {
        envId,
      });
      notification.success('Voice created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const handleEndpointVoice = () => {
    if (selectedId) {
      return `voices/update?id=${selectedId}`;
    } else {
      return 'voices/create';
    }
  };

  const openDeleteVoice = (voice: Voice) => {
    setModalDeleteOpen(true);
    setSelectedId(voice.id);
  };

  const handleRemoveVoice = async () => {
    setLoading(true);
    try {
      await remove(`/voices/${selectedId}`, { envId });
      notification.success('Voice deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete voice');
    } finally {
      setLoading(false);
    }
  };

  const validateVoice = async (provider: string, envId: string) => {
    const providerType = voicesAndProviders.find(
      (p) => p.voiceType === provider,
    );

    if (!providerType) {
      notification.error('Provider not connected');
      return false;
    }

    const isProviderConnected = providers.find(
      (p) => p.type === providerType?.providerType,
    );

    if (!isProviderConnected) {
      notification.error('Provider not connected');
      return false;
    }

    const isVoiceConnected = await testVoice(isProviderConnected, envId);

    if (!isVoiceConnected) {
      notification.error('Voice could not be verified');
      return false;
    }

    return true;
  };

  const handlePlayTTS = (voice: Voice) => {
    setSelectedVoiceForTTS(voice);
    setTtsText('');
    setTtsModalOpen(true);
  };

  const handleConfirmTTS = async () => {
    if (!selectedVoiceForTTS?.id || !ttsText) return;

    setPlayingVoice(selectedVoiceForTTS.id);
    setTtsModalOpen(false);

    const provider = providers.find(
      (p) =>
        voicesAndProviders.find(
          (vp) => vp.voiceType === selectedVoiceForTTS.type,
        )?.providerType === p.type,
    );

    if (!provider) {
      notification.error('Provider not connected');
      setPlayingVoice(null);
      return;
    }

    const success = await listenVoice(
      selectedVoiceForTTS,
      ttsText,
      String(envId),
      provider,
    );

    if (!success) {
      notification.error('Failed to play voice');
    }

    setPlayingVoice(null);
    setSelectedVoiceForTTS(null);
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [form, equivalentCodeOpen]);

  useEffect(() => {
    const fetchProviders = async () => {
      const { items: data } = await get('/account-connections', { envId });
      if (data) setProviders(data);
    };
    fetchProviders();
  }, []);

  const isProviderConnected = (provider: string) => {
    return providers.find(
      (p) =>
        voicesAndProviders.find((vp) => vp.voiceType === provider)
          ?.providerType === p.type,
    );
  };

  return (
    <div className="flex-1">
      <Heading
        title="Voice Library"
        subtitle="Voices add personality to your conversation by making it feel more human."
      >
        <div className="flex mt-6">
          <Button
            className="w-42 border-none flex items-center justify-center text-gray-600"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/voices"
            target="_blank"
          >
            View help doc
            <FileText className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex flex-col md:flex-row justify-between mb-6">
          <div className="flex w-full md:w-1/2 self-end">
            <div className="flex w-full md:grow self-end">
              <Input
                type="text"
                required
                placeholder="Search voices"
                value={filters.label}
                onChange={(e) =>
                  setFilters({ ...filters, label: e.target.value })
                }
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    submitFilter();
                  }
                }}
                icon={<MagnifyingGlass size={20} />}
                iconPosition="left"
                inputAction={() => fetchData(currentPage, size)}
              />
            </div>

            <div className="w-40 ml-2">
              <Label htmlFor="providers">Provider</Label>
              <SelectBox
                key="providers"
                options={[{ label: 'All', value: '' }, ...voices]}
                defaultValue={
                  filters.type
                    ? voices.find((voice) => voice.value === filters.type)
                    : { label: 'All', value: '' }
                }
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) => {
                  setFilters({ ...filters, type: value });
                  handlePageChange(1, size);
                }}
                className="col-span-2"
              />
            </div>
            <div className="w-60 ml-2">
              <Label htmlFor="prompt-source">Created By</Label>
              <SelectBox
                key="voice-source"
                options={VOICE_SOURCES}
                defaultValue={
                  filters.source
                    ? VOICE_SOURCES.find(
                        (voiceSource) => voiceSource.value === filters.source,
                      )
                    : { label: 'All', value: '' }
                }
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) => {
                  setFilters({ ...filters, source: value });
                  handlePageChange(1, size);
                }}
                className="col-span-2"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <Button onClick={openModalNewVoice}>Import Voice</Button>
          </div>
        </div>

        <Table
          headers={headers}
          rows={
            rows.map((item: Voice) => ({
              ...item,
              id: <CopyableField value={item.id} notification={notification} />,
              type: voices.find((voice) => voice.value === item.type)?.label,
              source: item.user_id ? 'User' : 'Fluents',
              voice_name: item.label,
              actions: (
                <div className="flex justify-center items-center">
                  <SideDropActions
                    options={[
                      {
                        label: 'Test voice',
                        Icon: Pencil,
                        show: !!isProviderConnected(item.type),
                        onClick: () =>
                          playingVoice ? {} : handlePlayTTS(item),
                      },
                      {
                        label: 'Edit',
                        Icon: Pencil,
                        show: !!item.user_id,
                        onClick: () => openModalEditVoice(item.id as string),
                      },
                      {
                        label: 'Delete',
                        Icon: Trash,
                        color: 'error',
                        show: !!item.user_id,
                        onClick: () => openDeleteVoice(item),
                      },
                    ]}
                  />
                </div>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'Import'} Voice`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button
            onClick={submitModal}
            disabled={loading || !form.voice_name || !form.type || !form.label}
          >
            {selectedId ? 'Save Voice' : 'Import Voice'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              equivalentCodeOpen
                ? setEquivalentCodeOpen(false)
                : setEquivalentCodeOpen(true)
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="mb-6">
            <Label required htmlFor="provider">
              Provider
            </Label>

            <SelectBox
              key={`voice_type_${selectedId}_${form.type}`}
              options={
                form.type === 'voice_rime'
                  ? voices
                  : voices.filter((v) => v.is_import_enabled)
              }
              defaultValue={voices.find((v) => v.value === form.type)}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) => {
                setForm({
                  ...form,
                  type: value,
                });

                cleanForm(value);
              }}
              className="min-w-[140px]"
              disabled={loading}
            />
          </div>

          <div className="mb-6">
            <Label required htmlFor="label">
              Label
            </Label>

            <input
              type="text"
              id="label"
              value={form.label}
              onChange={(e) =>
                setForm({
                  ...form,
                  label: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-300"
              placeholder="Enter a descriptive name"
            />
          </div>

          <div className="mb-6">
            <Label required htmlFor="voice-name">
              Voice ID
            </Label>

            <input
              type="text"
              id="voice-name"
              value={form.voice_name}
              onChange={(e) =>
                setForm({
                  ...form,
                  voice_name: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-300"
              placeholder={getPlaceholderByProvider(form.type)}
            />
          </div>

          <div className="mb-6">
            <Label htmlFor="description">Description</Label>

            <input
              type="text"
              id="description"
              value={form.description}
              onChange={(e) =>
                setForm({
                  ...form,
                  description: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-300"
            />
          </div>
        </div>

        {getAdditionalFields(form.type, form, setForm)}

        {equivalentCodeOpen && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="block text-gray-700 text-md font-bold mb-2">
                Equivalent Code
              </h3>

              <X
                className="cursor-pointer inline-block w-5 h-5"
                onClick={() => setEquivalentCodeOpen(false)}
              />
            </div>

            <EquivalentCode
              payload={form}
              endpoint={handleEndpointVoice()}
              method="POST"
            />
          </div>
        )}
      </Modal>

      <Modal
        title="Delete Voice"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={<Button onClick={handleRemoveVoice}>Delete Voice</Button>}
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this voice?
        </div>
      </Modal>

      <Modal
        title="Test Voice"
        isOpen={ttsModalOpen}
        onClose={() => {
          setTtsModalOpen(false);
          setSelectedVoiceForTTS(null);
        }}
        actionButton={
          <Button
            color="primary"
            onClick={handleConfirmTTS}
            disabled={!ttsText || ttsText.length > 20}
          >
            Play Voice
          </Button>
        }
        className="w-96"
      >
        <div className="mb-6">
          <div className="mb-4">
            <div className="font-medium text-gray-500">Voice Name</div>
            <div className="font-medium mb-4">{selectedVoiceForTTS?.label}</div>
            <div className="font-medium text-gray-500 mt-2">Provider</div>
            <div className="font-medium">
              {
                voices.find(
                  (voice) => voice.value === selectedVoiceForTTS?.type,
                )?.label
              }
            </div>
          </div>

          <Label className="text-gray-500">
            Enter text to speak (max 20 characters)
          </Label>
          <Textarea
            value={ttsText}
            onChange={(e) => setTtsText(e.target.value)}
            maxLength={20}
            placeholder="Enter text here..."
          />
          <div className="text-right text-sm text-gray-500 mt-1">
            {ttsText.length}/20 characters
          </div>
        </div>
      </Modal>
    </div>
  );
};
