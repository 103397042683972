export function getTelephonyProviderType(
  accountConnectionType: string,
): string {
  switch (accountConnectionType) {
    case 'account_connection_twilio':
      return 'twilio';
    case 'account_connection_telnyx':
      return 'telnyx';
    default:
      throw new Error(
        `account_connection_type=${accountConnectionType} is not a telephony account connection`,
      );
  }
}

export function getAccountConnectionType(telephonyProvider: string): string {
  switch (telephonyProvider) {
    case 'twilio':
      return 'account_connection_twilio';
    case 'telnyx':
      return 'account_connection_telnyx';
    default:
      throw new Error(
        `telephonyProvider=${telephonyProvider} is not a telephony provider`,
      );
  }
}
