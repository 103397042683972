import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Label } from './Label';

export type InputProps = {
  label?: React.ReactNode;
  error?: string;
  variant?: 'outlined' | 'contained' | 'text';
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  required?: boolean;
  disabled?: boolean;
  subText?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  inputAction?: () => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;

const sizeClasses = {
  small: 'px-2 py-1 text-sm',
  medium: 'px-3 py-2 text-base',
  large: 'px-4 py-3 text-lg',
};

const variantClasses = {
  outlined: {
    base: 'border',
    primary: 'border-gray-300 focus:border-primary',
    secondary: 'border-gray-300 focus:border-gray-500',
    error: 'border-red-500 bg-danger text-neutral',
    warning: 'border-yellow-500',
    success: 'border-green-500',
    info: 'border-blue-500',
    default: 'border-gray-300',
  },
  contained: {
    base: 'border-0',
    primary: 'bg-gray-100 focus:bg-gray-50',
    secondary: 'bg-gray-100 focus:bg-gray-50',
    error: 'bg-danger text-neutral',
    warning: 'bg-yellow-50',
    success: 'bg-green-50',
    info: 'bg-blue-50',
    default: 'bg-gray-100 focus:bg-gray-50',
  },
  text: {
    base: 'border-0',
    primary: 'focus:bg-gray-50',
    secondary: 'focus:bg-gray-50',
    error: 'text-red-500',
    warning: 'text-yellow-500',
    success: 'text-green-500',
    info: 'text-blue-500',
    default: 'focus:bg-gray-50',
  },
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      variant = 'outlined',
      color = 'primary',
      size = 'medium',
      className,
      required = false,
      disabled = false,
      subText,
      icon,
      iconPosition = 'right',
      inputAction,
      ...props
    },
    ref,
  ) => {
    const variantClass = variantClasses[variant];
    const sizeClass = sizeClasses[size];

    return (
      <div className="w-full">
        {label && (
          <Label
            htmlFor={props.id}
            className={clsx(error ? 'text-red-500' : 'text-gray-700')}
            required={required}
          >
            {label}
          </Label>
        )}

        <div className="relative">
          {icon && (
            <button
              onClick={inputAction}
              className={clsx(
                'absolute inset-y-0 flex items-center text-gray-500',
                iconPosition === 'left' ? 'left-0 pl-3' : 'right-0 pr-3',
              )}
            >
              {icon}
            </button>
          )}
          <input
            ref={ref}
            {...props}
            disabled={disabled}
            className={clsx(
              icon && iconPosition === 'left' && 'pl-10',
              icon && iconPosition === 'right' && 'pr-10',
              'w-full rounded-md transition-colors duration-200',
              'focus:outline-none',
              variantClass.base,
              variantClass[error ? 'error' : color],
              error ? 'focus:ring-red-500/20' : 'focus:ring-primary/20',
              sizeClass,
              disabled && 'cursor-not-allowed bg-neutral-350 text-neutral-250',
              'leading-[1.7] placeholder:text-[14.9px]',
              className,
            )}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && inputAction) {
                inputAction();
              }
            }}
          />
        </div>

        {(error || subText) && (
          <p
            className={clsx(
              'mt-1 text-sm',
              error ? 'text-red-500' : 'text-gray-500',
            )}
          >
            {error || subText}
          </p>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
