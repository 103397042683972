import { useEffect, useState } from 'react';
import { Input } from './Input';
import { Label } from './Label';
import { SelectBox } from './SelectBox';
import { SchemaProperty } from './SchemaBuilder';
import { Button } from './Button';
import { Toggle } from './Toggle';

interface PropertyFormProps {
  property?: SchemaProperty | null;
  onSave: (property: SchemaProperty) => void;
  onClose?: () => void;
}

const propertyTypes = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
];

export const PropertyForm = ({
  property,
  onSave,
  onClose,
}: PropertyFormProps) => {
  const [form, setForm] = useState<SchemaProperty>({
    name: '',
    description: '',
    type: 'string',
    required: false,
  });

  useEffect(() => {
    if (property) {
      setForm({
        name: property.name,
        description: property.description || '',
        type: property.type,
        required: property.required || false,
      });
    } else {
      setForm({
        name: '',
        description: '',
        type: 'string',
        required: false,
      });
    }
  }, [property]);

  const handleFormChange = (updates: Partial<SchemaProperty>) => {
    const updatedForm = { ...form, ...updates };
    setForm(updatedForm);
  };

  const handleSave = () => {
    onSave(form);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <Label required htmlFor="property-name" className="text-white">
          Name
        </Label>
        <Input
          id="property-name"
          type="text"
          value={form.name}
          onChange={(e) => handleFormChange({ name: e.target.value })}
          placeholder="Enter property name"
        />
      </div>

      <div>
        <Label htmlFor="property-description" className="text-white">
          Description
        </Label>
        <Input
          id="property-description"
          type="text"
          value={form.description}
          onChange={(e) => handleFormChange({ description: e.target.value })}
          placeholder="Enter property description"
        />
      </div>

      <div>
        <Label required htmlFor="property-type" className="text-white">
          Type
        </Label>
        <SelectBox
          className="text-white hover:text-neutral-300 hover:border-neutral-300"
          options={propertyTypes}
          defaultValue={propertyTypes.find((t) => t.value === form.type)}
          onChange={(value) =>
            handleFormChange({ type: value as SchemaProperty['type'] })
          }
          variant="outlined"
          color="primary"
          size="medium"
        />
      </div>
      <div className="flex items-center justify-between">
        <Label htmlFor="property-required" className="text-white">
          Required field
        </Label>

        <Toggle
          initialState={form.required}
          onChange={(value) => handleFormChange({ required: value })}
        />
      </div>

      <div className="flex justify-end pt-4">
        <Button size="small" onClick={handleSave} disabled={!form.name}>
          {property ? 'Update Property' : 'Create Property'}
        </Button>
      </div>
    </div>
  );
};
