import clsx from 'clsx';
import React, { forwardRef, useState } from 'react';
import { Input, InputProps } from './Input';
import { Eye, EyeClosed } from '@phosphor-icons/react';

export const PasswordInput = (props: InputProps) => {
  const [inputType, setInputType] = useState('password');
  return (
    <Input
      {...props}
      type={inputType}
      icon={
        inputType === 'password' ? (
          <Eye className={props.className} />
        ) : (
          <EyeClosed className={props.className} />
        )
      }
      inputAction={() => {
        inputType === 'password'
          ? setInputType('text')
          : setInputType('password');
      }}
    />
  );
};
