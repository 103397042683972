import { Tab as HeadlessTab } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

interface TabItem {
  label: string | React.ReactNode;
  content: React.ReactNode;
}

interface TabProps {
  items: TabItem[];
  defaultIndex?: number;
  onChange?: (index: number) => void;
  className?: string;
  panelWrapperClassName?: string;
  panelClassName?: string;
}

export const Tab = ({
  items,
  defaultIndex = 0,
  onChange,
  className,
  panelWrapperClassName,
  panelClassName,
}: TabProps) => {
  return (
    <HeadlessTab.Group defaultIndex={defaultIndex} onChange={onChange}>
      <HeadlessTab.List
        className={clsx('flex gap-2 border-b border-gray-200', className)}
      >
        {items.map((item) => (
          <HeadlessTab as={Fragment} key={`tab-panel-${items.indexOf(item)}`}>
            {({ selected }) => (
              <button
                className={clsx(
                  'py-2 font-medium transition-all duration-200 focus:outline-none border-b-2 mx-4',
                  selected
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700',
                )}
              >
                {item.label}
              </button>
            )}
          </HeadlessTab>
        ))}
      </HeadlessTab.List>
      <HeadlessTab.Panels
        className={clsx(panelWrapperClassName ? panelWrapperClassName : 'mt-4')}
      >
        {items.map((item) => (
          <HeadlessTab.Panel
            key={`tab-panel-${items.indexOf(item)}`}
            className={clsx(
              panelClassName
                ? panelClassName
                : 'rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
            )}
          >
            {item.content}
          </HeadlessTab.Panel>
        ))}
      </HeadlessTab.Panels>
    </HeadlessTab.Group>
  );
};
