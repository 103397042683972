import { ArrowRight, Code } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, patch, post } from 'src/api/requests';
import Accordion from 'src/components/Accordion';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { MultiSelectBox } from 'src/components/MultiSelectBox';
import { SelectBox } from 'src/components/SelectBox';
import { Textarea } from 'src/components/Textarea';
import { Tooltip } from 'src/components/Tooltip';
import { PROMPT_TAGS } from 'src/constants';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Prompt, PromptPayload } from 'src/interfaces/prompt.interface';

type PromptProps = {
  editing?: boolean;
};

const PROMPT_TYPES = [
  { label: 'Prompt', value: 'prompt' },
  { label: 'Building Block', value: 'block' },
];

export const ManagePrompt = ({ editing = false }: PromptProps) => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();
  const { id: editId } = useParams();

  const notification = useNotification();

  const [dateSaved, setDateSaved] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState<PromptPayload>({
    content: '',
    type: 'prompt',
  });
  const [promptBlocks, setPromptBlocks] = useState<Prompt[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const otherBlocks = promptBlocks.filter(
    (promptBlock) =>
      promptBlock.tags &&
      promptBlock.tags.length > 0 &&
      PROMPT_TAGS.filter((promptTag) => promptBlock.tags!.includes(promptTag))
        .length === 0,
  );
  const uncategorizedBlocks = promptBlocks.filter(
    (promptBlock) => !promptBlock.tags || !promptBlock.tags.length,
  );

  const handleSave = async () => {
    setLoading(true);

    if (editing) {
      await patch(`/prompts/${editId}`, prompt, {
        envId,
      });
      notification.success('Prompt updated successfully 🎉');
    } else {
      const createPrompt = await post('/prompts', prompt, {
        envId,
      });
      navigate(`/prompt/edit/${createPrompt.id}`);
      notification.success('Prompt created successfully 🎉');
    }

    setDateSaved(new Date());
    setLoading(false);
  };

  const endpoint = editing ? `prompts/update?id=${editId}` : 'prompts/create';

  const handlePromptEquivalentCode = () => {
    openSidebar(
      <EquivalentCode payload={prompt} endpoint={endpoint} method="POST" />,
      'Equivalent Code',
    );
  };

  const fetchPromptBlocks = async () => {
    const query = new URLSearchParams({
      size: '50',
      filters: JSON.stringify({
        type: 'block',
      }),
    });

    const data = await get(`/prompts?${query.toString()}`, {
      envId,
    });
    setPromptBlocks(data.items);
  };

  const insertAtCursor = (newText: string) => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;

    textarea.focus();

    // Use deprecated API in order to preserve undo functionality in text area
    // If it's not supported by browser fallback to setting state
    if (!document.execCommand('insertText', false, newText)) {
      const updatedText =
        prompt.content.substring(0, selectionStart) +
        newText +
        prompt.content.substring(selectionEnd);
      setPrompt({ ...prompt, content: updatedText });
    }

    // Move the cursor to the end of the inserted text
    textarea.selectionStart = selectionStart + newText.length;
    textarea.selectionEnd = selectionStart + newText.length;

    //Refocus on the textarea after updating
    textarea.focus();
  };

  useEffect(() => {
    fetchPromptBlocks();
  }, []);

  useEffect(() => {
    if (editing) {
      const fetchPrompt = async () => {
        setLoading(true);
        const prompt = await get(`/prompts/${editId}`, {
          envId,
        });
        setPrompt(prompt);
        setLoading(false);
      };

      fetchPrompt();
    }
  }, [editing, editId]);

  useEffect(() => {
    if (isOpen && !loading) {
      handlePromptEquivalentCode();
    }
  }, [prompt, isOpen]);

  return (
    <div className="flex-1">
      <Heading
        title={
          editing ? `Editing Prompt: ${prompt.label || editId}` : 'New Prompt'
        }
      />

      <ContentWrapper>
        <div className="grid grid-cols-1 md:grid-cols-5">
          {promptBlocks.length > 0 && (
            <div className="max-sm:hidden md:col-span-1 text-gray-900 mr-4">
              <div className="font-medium text-lg text-control-plane-300 transition-all duration-200 focus:outline-none py-2 px-1">
                Building Blocks
              </div>
              <div className="w-full bg-white bg-gray-700">
                {PROMPT_TAGS.map((promptTag) => {
                  const tagBlocks = promptBlocks.filter((promptBlock) =>
                    promptBlock.tags?.includes(promptTag),
                  );
                  if (!tagBlocks || !tagBlocks.length) {
                    return;
                  }
                  return (
                    <Accordion
                      key={promptTag}
                      title={`${promptTag.charAt(0).toUpperCase()}${promptTag.slice(1)}s`}
                    >
                      {tagBlocks.map((tagBlock) => {
                        return (
                          <Tooltip
                            className="w-full"
                            content={tagBlock.content}
                          >
                            <button
                              className="inline-flex justify-between w-full text-sm font-medium hover:bg-gray-100 hover:text-control-plane-500 focus:z-10 focus:ring-2 focus:ring-control-plane-500 focus:text-control-plane-500"
                              onClick={() => insertAtCursor(tagBlock.content)}
                            >
                              {tagBlock.label}
                              <ArrowRight size={16} />
                            </button>
                          </Tooltip>
                        );
                      })}
                    </Accordion>
                  );
                })}
                {otherBlocks.length > 0 && (
                  <Accordion key={'other'} title={`Other`}>
                    {otherBlocks.map((otherBlock) => {
                      return (
                        <Tooltip
                          className="w-full"
                          content={otherBlock.content}
                        >
                          <button
                            className="inline-flex justify-between w-full text-sm font-medium hover:bg-gray-100 hover:text-control-plane-500 focus:z-10 focus:ring-2 focus:ring-control-plane-500 focus:text-control-plane-500"
                            onClick={() => insertAtCursor(otherBlock.content)}
                          >
                            {otherBlock.label}
                            <ArrowRight size={16} />
                          </button>
                        </Tooltip>
                      );
                    })}
                  </Accordion>
                )}
                {uncategorizedBlocks.length > 0 && (
                  <Accordion
                    key={'uncategorized'}
                    title={`Uncategorized`}
                    className="text-"
                  >
                    {uncategorizedBlocks.map((uncategorizedBlock) => {
                      return (
                        <Tooltip
                          className="w-full"
                          content={uncategorizedBlock.content}
                        >
                          <button
                            className="inline-flex justify-between w-full text-sm font-medium hover:bg-gray-100 hover:text-control-plane-500 focus:z-10 focus:ring-2 focus:ring-control-plane-500 focus:text-control-plane-500"
                            onClick={() =>
                              insertAtCursor(uncategorizedBlock.content)
                            }
                          >
                            {uncategorizedBlock.label}
                            <ArrowRight size={16} />
                          </button>
                        </Tooltip>
                      );
                    })}
                  </Accordion>
                )}
              </div>
            </div>
          )}
          <div
            className={`${promptBlocks.length > 0 ? 'col-span-3' : 'col-span-4'} mr-4`}
          >
            <div className="w-full md:col-span-2 lg:col-span-2 text-sm mt-2">
              <div className="flex">
                <div className="w-2/3">
                  <Label htmlFor="prompt-name">Name</Label>
                  <Input
                    type="text"
                    id="label"
                    placeholder="Name"
                    value={prompt.label}
                    onChange={(e) =>
                      setPrompt({ ...prompt, label: e.target.value })
                    }
                    disabled={loading || (!prompt.user_id && !!editId)}
                  />
                </div>
                <div className="ml-2 w-1/3">
                  <Label htmlFor="prompt-types">Type</Label>
                  <SelectBox
                    key="prompt-types"
                    options={PROMPT_TYPES}
                    defaultValue={PROMPT_TYPES.find(
                      (promptType) => promptType.value === prompt.type,
                    )}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onChange={(value) => {
                      setPrompt({ ...prompt, type: value });
                    }}
                    className="col-span-2"
                    disabled={loading || (!prompt.user_id && !!editId)}
                  />
                </div>
              </div>
              {prompt.type === 'block' && (
                <div className="mt-2">
                  <Label htmlFor="prompt-tags-selector">Tags</Label>
                  <div className="w-full">
                    <MultiSelectBox
                      key="prompt-tags-selector"
                      defaultValue={(prompt.tags || []).map((tag) => ({
                        value: tag,
                        label: tag,
                      }))}
                      options={PROMPT_TAGS.map((tag) => ({
                        value: tag,
                        label: tag,
                      }))}
                      variant="outlined"
                      color="primary"
                      size="medium"
                      className="w-full"
                      onChange={(value) =>
                        setPrompt({ ...prompt, tags: value })
                      }
                      disabled={loading || (!prompt.user_id && !!editId)}
                    />
                  </div>
                </div>
              )}
            </div>
            <Label className="text-sm mt-2" htmlFor="prompt-content">
              Content
            </Label>
            <Textarea
              placeholder="Prompt Content"
              value={prompt.content}
              onChange={(e) =>
                setPrompt({ ...prompt, content: e.target.value })
              }
              disabled={loading || (!prompt.user_id && !!editId)}
              rows={11}
              ref={textareaRef}
            />

            <div className="flex items-center mt-4 justify-end">
              {dateSaved && !loading && (
                <p className="mr-4 text-green-500">
                  Last saved at {dateSaved.toLocaleTimeString()}
                </p>
              )}

              <Button
                color="secondary"
                className="mr-4"
                onClick={() => navigate('/prompts')}
                disabled={loading}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                onClick={handleSave}
                disabled={
                  loading || !prompt.content || (!prompt.user_id && !!editId)
                }
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
          <div className="col-span-1">
            <Button
              onClick={() =>
                isOpen ? closeSidebar() : handlePromptEquivalentCode()
              }
              className="mt-2 w-full md:col-span-1 lg:col-span-1 flex items-center justify-center"
            >
              <Code className="mr-2" size={20} />
              Show equivalent code
            </Button>
            <div className="mt-4">
              <div>
                <div className="bg-gray-100 p-4 rounded-md">
                  <h2 className="text-xl font-semibold">Prompt Guidance</h2>
                  <div className="text-gray-600 text-sm mt-2">
                    Here are some tips for creating a great prompt:
                    <ul className="list-disc ml-5 mt-2">
                      <li>
                        Avoid overly complex instructions. Simple instructions
                        are more reliable
                      </li>
                      <li>
                        If using uncommon words or proper nouns, write it out
                        phonetically
                      </li>
                      <li>
                        When working with large prompts, split it into sections
                      </li>
                      <li>Provide some examples of desired behavior</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
