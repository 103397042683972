import { useEffect, useState } from 'react';
import { get, patch } from 'src/api/requests';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Agent } from 'src/interfaces/agent.interface';
import { OnboardingComponent } from 'src/interfaces/onboardingComponent.interface';
import { AgentCaller } from 'src/pages/Agents/components/AgentCaller';
import { AnimatedCircles } from 'src/pages/Agents/components/AnimatedCircles';

export const Caller = ({
  onPhaseComplete,
  organization,
  secondaryAction,
}: OnboardingComponent) => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [loading, setLoading] = useState(false);
  const [currentAgent, setCurrentAgent] = useState<Agent | null>(null);
  const [callStartedOnce, setCallStartedOnce] = useState(false);

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        setLoading(true);
        const agents = await get(`/agents`, { envId });
        if (agents.items.length > 0) {
          setCurrentAgent(agents.items[0]);
        } else {
          notification.error('No agents found...');
        }
        setLoading(false);
      } catch (error) {
        notification.error('Agent not found...');
      }
    };

    fetchAgent();
  }, [envId, notification]);

  const handleCallEnd = async () => {
    await patch(
      `/organizations/${organization.id}/onboarding`,
      { currentPhase: 'feedback' },
      { envId },
    );
    onPhaseComplete();
  };

  const skipCall = async () => {
    if (!secondaryAction) return;

    await patch(
      `/organizations/${organization.id}/onboarding`,
      { currentPhase: 'ready' },
      { envId },
    );
    secondaryAction();
  };

  return (
    <>
      <img
        src="/assets/logo-white.png"
        alt="Circle"
        className="absolute top-10 left-10 h-10 animate-fade-in-up"
      />

      <div className="container mx-auto py-10">
        <div className="flex flex-col items-center justify-center min-h-[80vh]">
          {loading ? (
            <div className="flex flex-col items-center">
              <AnimatedCircles
                isMaximized={true}
                isCalling={false}
                audioLevel={0}
                variant="large"
              />
              <p className="text-2xl text-white mt-6 mb-6 animate-fade-in-up">
                Loading Agent...
              </p>
            </div>
          ) : (
            currentAgent && (
              <AgentCaller
                agent={currentAgent}
                isEditing={true}
                isEmbedded={true}
                onCallEnd={handleCallEnd}
                onCallStart={() => setCallStartedOnce(true)}
                customControls={
                  !callStartedOnce && (
                    <button
                      className="px-6 py-3 rounded-lg bg-neutral-700 text-white flex items-center gap-2"
                      onClick={skipCall}
                    >
                      Skip Call for Now
                    </button>
                  )
                }
              />
            )
          )}
        </div>
      </div>
    </>
  );
};
