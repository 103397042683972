import { ActionKey } from 'src/constants';
import { ActionType, TriggerType } from 'src/interfaces/action.interface';

const BASE_ACTION_URL = 'https://api.integration.app';

const BaseActionPayload = {
  type: ActionType.External,
  action_trigger: {
    type: TriggerType.FunctionCall,
  },
};

type ActionConfigStub = {
  name: string;
  description: string;
  url?: string;
  input_schema: object;
  speak_on_send?: boolean;
  speak_on_receive?: boolean;
  signature_secret?: string;
};

const RetrieveAvailabilityActionStub: ActionConfigStub = {
  name: 'Retrieve Available Slots',
  description: 'A service to find meeting or appointment availability',
  input_schema: {
    type: 'object',
    properties: {
      endTime: {
        type: 'string',
        description:
          'End date time of the range to query for available slots. Example: 2025-02-05',
      },
      startTime: {
        type: 'string',
        description:
          'Start date time of the range to query for available slots. Example: 2025-02-01',
      },
      timeZone: {
        type: 'string',
        description:
          'Timezone identifier such as "America/Los_Angeles" or "America/New_York"',
      },
      eventTypeId: {
        type: 'string',
        description:
          'The calendar event type ID used to determine which type of event to look for',
      },
    },
    required: ['endTime', 'startTime', 'timeZone', 'eventTypeId'],
  },
  speak_on_send: false,
  speak_on_receive: false,
};

const BookAppointmentActionStub: ActionConfigStub = {
  name: 'Book Appointment',
  description: 'A service to book a meeting or an appointment',
  input_schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        description: 'Name of participant',
      },
      email: {
        type: 'string',
        description: 'Email address of participant',
      },
      start: {
        type: 'string',
        description:
          'Start date and time of meeting in ISO format. Example: 2025-03-01T10:00:00Z',
      },
      timeZone: {
        type: 'string',
        description:
          'Timezone identifier such as "America/Los_Angeles" or "America/New_York"',
      },
      eventTypeId: {
        type: 'string',
        description:
          'The calendar event type ID used to determine which type of event to look for',
      },
    },
    required: ['name', 'email', 'start', 'timeZone', 'eventTypeId'],
  },
  speak_on_send: false,
  speak_on_receive: false,
};

const actionConfigStubsByType: { [key: string]: ActionConfigStub } = {
  [ActionKey.RETRIEVE_AVAILABILITY]: RetrieveAvailabilityActionStub,
  [ActionKey.BOOK_APPOINTMENT]: BookAppointmentActionStub,
};

const getActionUrl = (connectionId: string, actionId: string) => {
  return `${BASE_ACTION_URL}/connections/${connectionId}/actions/${actionId}/run`;
};

export const generateActionStub = (
  type: ActionKey,
  connectionId: string,
  actionId: string,
  appName: string,
) => {
  return Object.assign({}, BaseActionPayload, {
    config: Object.assign({}, actionConfigStubsByType[type], {
      url: getActionUrl(connectionId, actionId),
    }),
    label: `${appName} - ${actionConfigStubsByType[type].name}`,
    description: actionConfigStubsByType[type].description,
  });
};
