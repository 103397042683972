import {
  useConnections,
  useIntegrationApp,
  useIntegrations,
} from '@integration-app/react';
import {
  Code,
  Copy,
  Eye,
  EyeClosed,
  FileText,
  Pencil,
  Trash,
  X,
} from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import {
  AccountConnection,
  AccountConnectionPayload,
} from 'src/interfaces/accountConnection.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { obfuscateToken } from 'src/utils/token';
import { nonApiKeyConnections, providerOptions } from './providerOptions';
import { PasswordInput } from 'src/components/PasswordInput';
import IntegrationAppActions from './IntegrationAppActions';

const newAccountConnection: AccountConnectionPayload = {
  credentials: {
    twilio_account_sid: '',
    twilio_auth_token: '',
  },
};

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'type', label: 'Provider', width: '15%' },
  { key: 'provider_type', label: 'Type', width: '3rem' },
  { key: 'info', label: 'Provider Info', width: '40%', disableSorting: true },
  { key: 'actions', label: '', width: '15%', disableSorting: true },
];

export const ListAccountConnections = () => {
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [providers, setProviders] = useState(providerOptions);
  const [providersKeyObfuscated, setProvidersKeyObfuscated] = useState<{
    [key: string]: boolean;
  }>({
    account_connection_twilio: true,
    account_connection_openai: true,
  });
  const [rows, setRows] = useState<AccountConnection[]>([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] =
    useState<AccountConnectionPayload>(newAccountConnection);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });
  const integrationApp = useIntegrationApp();
  const { integrations } = useIntegrations();

  const { connections, loading: connectionsLoading } = useConnections();

  const fetchData = useCallback(
    async (page: number, perPage: number) => {
      setLoading(true);

      const queryObject: QueryObject = {
        page: String(page),
        size: String(perPage),
      };

      if (sortConfig.key) {
        queryObject.sort_column = sortConfig.key;
        queryObject.sort_desc =
          sortConfig.direction === 'desc' ? 'true' : 'false';
      }

      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/account-connections?${query.toString()}`, {
        envId,
      });

      const items = data.items;

      const providersToBeObfuscated: { [key: string]: boolean } = {};

      for (const item of items) {
        providersToBeObfuscated[item.id] = true;
      }

      setProvidersKeyObfuscated(providersToBeObfuscated);

      setRows(items);
      setTotalItems(data.total);
      setLoading(false);
    },
    [envId, sortConfig],
  );

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig, fetchData, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const resetForm = () => {
    setForm(newAccountConnection);
  };

  const openModalNewAccountConnection = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditAccountConnection = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const account = rows.find((item) => item.id === id);

    if (account) {
      setForm({
        credentials: {
          twilio_account_sid: account.credentials.twilio_account_sid,
          twilio_auth_token: account.credentials.twilio_auth_token,
          openai_api_key: account.credentials.openai_api_key,
          api_key: account.credentials.api_key,
          header_name: account.credentials.header_name,
          region: account.credentials.region,
          userId: account.credentials.userId,
          public_key: account.credentials.public_key,
          token_url: account.credentials.token_url,
          login_key: account.credentials.login_key,
          username: account.credentials.username,
          password: account.credentials.password,
          client_id: account.credentials.client_id,
          client_secret: account.credentials.client_secret,
        },
        type: account.type,
      });
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedId('');
    setEquivalentCodeOpen(false);
    resetForm();
  };

  const onCloseIntegration = () => {
    fetchData(currentPage, size);
    closeModal();
  };

  const submitModal = async () => {
    setLoading(true);

    const integration = integrations.find(
      (integration) => integration.id === form.type,
    );

    if (integration) {
      integrationApp
        ?.integration(integration.id)
        .open({ onClose: () => onCloseIntegration() });

      setLoading(false);
      return;
    }

    if (selectedId) {
      await patch(`/account-connections/${selectedId}`, form, {
        envId,
      });
      notification.success('Account updated successfully');
    } else {
      await post('/account-connections', form, {
        envId,
      });
      notification.success('Account created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const changeProvider = (value: string) => {
    if (value === 'account_connection_twilio') {
      setForm({
        ...form,
        credentials: {
          twilio_account_sid: '',
          twilio_auth_token: '',
        },
        type: value,
      });
    } else {
      setForm({
        ...form,
        credentials: {
          openai_api_key: '',
        },
        type: value,
      });
    }
  };

  const hasOpenAI = rows.some(
    (item) => item.type === 'account_connection_openai',
  );

  const hasElevenLabs = rows.some(
    (item) => item.type === 'account_connection_elevenlabs',
  );

  const hasDeepgram = rows.some(
    (item) => item.type === 'account_connection_deepgram',
  );

  const hasCartesia = rows.some(
    (item) => item.type === 'account_connection_cartesia',
  );

  const hasPlayht = rows.some(
    (item) => item.type === 'account_connection_playht',
  );

  const hasDeepSeek = rows.some(
    (item) => item.type === 'account_connection_deepseek',
  );

  const hasAssemblyAi = rows.some(
    (item) => item.type === 'account_connection_assemblyai',
  );

  const hasAzureSpeech = rows.some(
    (item) => item.type === 'account_connection_azure_speech',
  );

  const hasGoogle = rows.some(
    (item) => item.type === 'account_connection_google',
  );

  const hasAzureLlm = rows.some(
    (item) => item.type === 'account_connection_azure_llm',
  );

  const hasTelnyx = rows.some(
    (item) => item.type === 'account_connection_telnyx',
  );

  const hasAllProviders =
    hasOpenAI &&
    hasElevenLabs &&
    hasDeepgram &&
    hasCartesia &&
    hasPlayht &&
    hasAzureSpeech &&
    hasGoogle &&
    hasAzureLlm &&
    hasTelnyx &&
    hasDeepSeek &&
    hasAssemblyAi;

  useEffect(() => {
    const missingProviders = providerOptions.filter(
      (option) =>
        !rows.some(
          (row) =>
            row.type === option.value &&
            option.value !== 'account_connection_twilio',
        ),
    );

    setProviders(missingProviders);
  }, [rows]);

  const toggleProvidersKeyObfuscated = (key: string) => {
    setProvidersKeyObfuscated({
      ...providersKeyObfuscated,
      [key]: !providersKeyObfuscated[key],
    });
  };

  const endpoint = selectedId
    ? `account_connections/update?id=${selectedId}`
    : 'account_connections/create';

  const isValidForm = (form: AccountConnectionPayload) => {
    if (
      form.type === 'account_connection_twilio' &&
      (!form.credentials.twilio_account_sid ||
        !form.credentials.twilio_auth_token)
    )
      return true;

    if (
      form.type === 'account_connection_openai' &&
      !form.credentials.openai_api_key
    )
      return true;

    return false;
  };

  const disabledSubmit = () => {
    if (loading) return true;

    if (!form.type) return true;

    return isValidForm(form);
  };

  const openDeleteProvider = (provider: AccountConnection) => {
    setModalDeleteOpen(true);
    setSelectedId(provider.id);
  };

  const handleRemoveProvider = async () => {
    setLoading(true);
    try {
      await remove(`/account-connections/${selectedId}`, { envId });
      notification.success('Provider deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete provider');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [form, equivalentCodeOpen, loading]);

  const providersIntegrations = providers
    .filter((provider) => !provider.hidden)
    .concat(
      integrations.map((integration) => ({
        value: integration.id,
        label: integration.name,
        type: 'Workflow',
      })),
    );

  return (
    <div className="flex-1">
      <Heading
        title="Providers"
        subtitle="Manage your account connections and providers."
      >
        <div className="flex mt-6">
          <Button
            className="w-42 border-none flex items-center justify-center text-gray-600"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/account_connections"
            target="_blank"
          >
            View help doc
            <FileText className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        {!hasAllProviders && (
          <div className="flex justify-end mb-6">
            <Button className="mr-2" onClick={openModalNewAccountConnection}>
              Add Account
            </Button>
          </div>
        )}

        <div className="pb-3">
          <Table
            headers={headers}
            rows={[
              ...(rows.map((item: AccountConnection) => ({
                ...item,
                id: (
                  <CopyableField value={item.id} notification={notification} />
                ),
                type:
                  providerOptions.find((option) => option.value === item.type)
                    ?.label || item.type.split('account_connection_')[1],
                provider_type: providerOptions.find(
                  (option) => option.value === item.type,
                )?.type,
                info: (
                  <div>
                    {item.type === 'account_connection_twilio' && (
                      <div>
                        <p>
                          <span className="font-bold">Twilio Account SID:</span>{' '}
                          {item.credentials.twilio_account_sid}
                        </p>
                        <p>
                          <span className="font-bold">Twilio Auth Token:</span>{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(
                                  item.credentials.twilio_auth_token,
                                )
                              : item.credentials.twilio_auth_token}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.twilio_auth_token,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                      </div>
                    )}

                    {item.type === 'account_connection_openai' && (
                      <div>
                        <p>
                          <span className="font-bold">OpenAI API Key:</span>{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(item.credentials.openai_api_key)
                              : item.credentials.openai_api_key}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.openai_api_key,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                      </div>
                    )}

                    {!nonApiKeyConnections.includes(item.type) && (
                      <div>
                        {item.credentials.header_name && (
                          <p>
                            <span className="font-bold">Header Name:</span>{' '}
                            <span className="mr-2">
                              {item.credentials.header_name}
                            </span>
                          </p>
                        )}
                        <p>
                          <span className="font-bold">API Key:</span>{' '}
                          <span
                            className="cursor-pointer mr-2"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(item.credentials.api_key)
                              : item.credentials.api_key}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.api_key,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                        <p>
                          {item.type === 'account_connection_playht' && (
                            <span>
                              <span className="font-bold">User ID:</span>{' '}
                              {item.credentials.userId}
                            </span>
                          )}
                          {item.type === 'account_connection_azure_speech' && (
                            <span>
                              <span className="font-bold">Region:</span>{' '}
                              {item.credentials.region}
                            </span>
                          )}

                          {item.type === 'account_connection_azure_llm' && (
                            <span>
                              <span className="font-bold">URL:</span>{' '}
                              {item.credentials.url}
                            </span>
                          )}
                        </p>
                      </div>
                    )}

                    {item.type === 'account_connection_telnyx' && (
                      <div>
                        <p>
                          <span className="font-bold">Public Key:</span>{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(item.credentials.public_key!)
                              : item.credentials.public_key}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.public_key!,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                      </div>
                    )}

                    {item.type === 'account_connection_login_key_oauth' && (
                      <div>
                        <p>
                          <span className="font-bold">Token URL:</span>{' '}
                          {item.credentials.token_url}
                        </p>
                        <p>
                          <span className="font-bold">Login Key:</span>{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(item.credentials.login_key!)
                              : item.credentials.login_key}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.public_key!,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                      </div>
                    )}

                    {item.type === 'account_connection_un_pw_oauth' && (
                      <div>
                        <p>
                          <span className="font-bold">Token URL:</span>{' '}
                          {item.credentials.token_url}
                        </p>
                        <p>
                          <span className="font-bold">Username:</span>{' '}
                          {item.credentials.username}
                        </p>
                        <p>
                          <span className="font-bold">Client ID:</span>{' '}
                          {item.credentials.client_id}
                        </p>
                      </div>
                    )}
                  </div>
                ),
                actions: (
                  <div className="flex justify-center items-center">
                    <SideDropActions
                      options={[
                        {
                          label: 'Edit',
                          Icon: Pencil,
                          onClick: () =>
                            openModalEditAccountConnection(item.id as string),
                        },
                        {
                          label: 'Delete',
                          Icon: Trash,
                          color: 'error',
                          onClick: () => openDeleteProvider(item),
                        },
                      ]}
                    />
                  </div>
                ),
              })) as never[]),
              ...(connections.map((connection) => ({
                ...connection,
                id: connection.id,
                type: connection.integration?.name || connection.name,
                provider_type: 'Workflow',
                info: '-',
                actions: <IntegrationAppActions {...connection} />,
              })) as never[]),
            ]}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onSort={handleSort}
            loading={loading || connectionsLoading}
            setSize={setSize}
            defaultSize={size}
            disablePagination
          />
        </div>
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'New'} Account Connection`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button onClick={submitModal} disabled={disabledSubmit()}>
            {selectedId ? 'Save Account' : 'Add Account'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              equivalentCodeOpen
                ? setEquivalentCodeOpen(false)
                : setEquivalentCodeOpen(true)
            }
            className="flex items-center justify-center"
            disabled={integrations.some(
              (integration) => integration.id === form.type,
            )}
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {!selectedId && (
            <div>
              <div className="mb-6">
                <Label required>Provider</Label>

                <SelectBox
                  options={providersIntegrations}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onChange={(value) => changeProvider(value)}
                  className="min-w-[140px]"
                  defaultValue={providersIntegrations.find(
                    (item) => item.value === form.type,
                  )}
                  disabled={loading}
                  key={form.type}
                />
              </div>
            </div>
          )}

          <div>
            {form.type === 'account_connection_twilio' && (
              <>
                <Input
                  className="mb-6"
                  label="Twilio Account SID"
                  type="text"
                  id="twilio-account-sid"
                  placeholder="Enter Twilio Account SID"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        twilio_account_sid: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.twilio_account_sid}
                  disabled={loading}
                  required
                />
                <PasswordInput
                  className="mb-6"
                  label="Twilio Auth Token"
                  id="twilio-auth-token"
                  placeholder="Enter Twilio Auth Token"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        twilio_auth_token: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.twilio_auth_token}
                  disabled={loading}
                  required
                />
              </>
            )}

            {form.type === 'account_connection_openai' && (
              <PasswordInput
                className="mb-6"
                label="OpenAI API Key"
                id="openai-api-key"
                placeholder="Enter OpenAI API Key"
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: {
                      ...form.credentials,
                      openai_api_key: e.target.value,
                    },
                  })
                }
                value={form.credentials.openai_api_key}
                disabled={loading}
                required
              />
            )}

            {!!form.type &&
              !nonApiKeyConnections.includes(form.type) &&
              !integrations.some(
                (integration) => integration.id === form.type,
              ) && (
                <div className="row mb-6">
                  {form.type === 'account_connection_external_api_key' && (
                    <Input
                      className="mb-6"
                      label={`${
                        providerOptions.find(
                          (option) => option.value === form.type,
                        )?.label
                      } - Header Name`}
                      id="aheader_name"
                      placeholder="Enter header name (e.g. X-API-KEY)"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          credentials: {
                            ...form.credentials,
                            header_name: e.target.value,
                          },
                        })
                      }
                      value={form.credentials.header_name}
                      disabled={loading}
                      required
                    />
                  )}

                  <PasswordInput
                    className="mb-6"
                    label={`${
                      providerOptions.find(
                        (option) => option.value === form.type,
                      )?.label
                    } - API Key`}
                    id="api-key"
                    placeholder="Enter API Key"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        credentials: {
                          ...form.credentials,
                          api_key: e.target.value,
                        },
                      })
                    }
                    value={form.credentials.api_key}
                    disabled={loading}
                    required
                  />
                </div>
              )}

            {form.type === 'account_connection_telnyx' && (
              <PasswordInput
                className="mb-6"
                label="Telnyx Public Key"
                id="telnyx-public-key"
                placeholder="Enter Telnyx Public Key"
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: {
                      ...form.credentials,
                      public_key: e.target.value,
                    },
                  })
                }
                value={form.credentials.public_key}
                disabled={loading}
                required
              />
            )}

            {form.type === 'account_connection_playht' && (
              <Input
                className="mb-6"
                label="User ID"
                type="text"
                id="userId"
                placeholder="Enter User ID"
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: {
                      ...form.credentials,
                      userId: e.target.value,
                    },
                  })
                }
                value={form.credentials.userId}
                disabled={loading}
                required
              />
            )}

            {form.type === 'account_connection_azure_speech' && (
              <Input
                className="mb-6"
                label="Region"
                type="text"
                id="region"
                placeholder="Enter Region"
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: {
                      ...form.credentials,
                      region: e.target.value,
                    },
                  })
                }
                value={form.credentials.region}
                disabled={loading}
                required
              />
            )}

            {form.type === 'account_connection_azure_llm' && (
              <Input
                className="mb-6"
                label="URL"
                type="text"
                id="url"
                placeholder="Enter URL"
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: { ...form.credentials, url: e.target.value },
                  })
                }
                value={form.credentials.url}
                disabled={loading}
              />
            )}

            {form.type === 'account_connection_login_key_oauth' && (
              <>
                <Input
                  label="Token URL"
                  type="text"
                  id="url"
                  placeholder="Enter Token URL"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        token_url: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.token_url}
                  disabled={loading}
                  className="mb-6"
                  required
                />
                <PasswordInput
                  label="Login Key"
                  id="url"
                  placeholder="Enter Login Key"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        login_key: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.login_key}
                  disabled={loading}
                  className="mb-6"
                  required
                />
              </>
            )}

            {form.type === 'account_connection_un_pw_oauth' && (
              <>
                <Input
                  label="Token URL"
                  type="text"
                  id="url"
                  placeholder="Enter Token URL"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        token_url: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.token_url}
                  disabled={loading}
                  className="mb-6"
                  required
                />
                <Input
                  label="Username"
                  type="text"
                  id="username"
                  placeholder="Enter username"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        username: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.username}
                  disabled={loading}
                  className="mb-6"
                  required
                />
                <PasswordInput
                  label="Password"
                  id="password"
                  placeholder="Enter password"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        password: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.password}
                  disabled={loading}
                  className="mb-6"
                  required
                />
                <Input
                  label="Client ID"
                  type="text"
                  id="client-id"
                  placeholder="Enter Client ID"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        client_id: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.client_id}
                  disabled={loading}
                  className="mb-6"
                  required
                />
                <PasswordInput
                  label="Client Secret"
                  id="client-id"
                  placeholder="Enter Client ID"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        client_secret: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.client_secret}
                  disabled={loading}
                  className="mb-6"
                  required
                />
              </>
            )}
          </div>
        </div>

        {equivalentCodeOpen && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="block text-gray-700 text-md font-bold mb-2">
                Equivalent Code
              </h3>

              <X
                className="cursor-pointer inline-block w-5 h-5"
                onClick={() => setEquivalentCodeOpen(false)}
              />
            </div>

            <EquivalentCode payload={form} endpoint={endpoint} method="POST" />
          </div>
        )}
      </Modal>

      <Modal
        title="Delete Provider"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button onClick={handleRemoveProvider}>Delete Provider</Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this provider?
        </div>
      </Modal>
    </div>
  );
};
