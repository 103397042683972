import { getAuth } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, patch, post } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { Heading } from 'src/components/Heading';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Action } from 'src/interfaces/action.interface';
import { Agent } from 'src/interfaces/agent.interface';
import { Number } from 'src/interfaces/number.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { Webhook } from 'src/interfaces/webhook.interface';
import { AgentStub } from 'src/stubs/agent.stub';
import { processPhoneNumber } from 'src/utils/number';
import { TabsAgent } from './TabsAgent';

type AgentProps = {
  editing?: boolean;
};

export const ManageAgent = ({ editing = false }: AgentProps) => {
  const auth = getAuth();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const { id: editId } = useParams();

  const [agent, setAgent] = useState<Agent>(AgentStub as Agent);
  const [agentLoading, setAgentLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [attachedVoice, setAttachedVoice] = useState<Voice>();
  const [attachedPrompt, setAttachedPrompt] = useState<Prompt>();
  const [attachedWebhook, setAttachedWebhook] = useState<Webhook>();
  const [attachedActions, setAttachedActions] = useState<Action[]>([]);
  const [actions, setActions] = useState<Action[]>([]);

  const [agentNumbers, setAgentNumbers] = useState<Number[]>([]);

  const navigate = useNavigate();
  const notification = useNotification();

  const fetchActions = useCallback(async () => {
    setActionLoading(true);
    const query = new URLSearchParams({
      size: '50',
      filters: JSON.stringify({ scope: 'global|user' }),
    });
    const { items } = await get(`/actions?${query.toString()}`, { envId });
    setActions(items);
    setActionLoading(false);
  }, [envId]);

  const saveAgent = async () => {
    const url = editing ? `/agents/${editId}` : '/agents';

    const { actions, webhook, ...restAgent } = agent;

    const clonedAgent = {
      ...restAgent,
      ...(typeof webhook === 'string' && webhook.length && { webhook }),
      ...(typeof webhook !== 'string' &&
        !!webhook &&
        webhook.id && { webhook }),
      ...(!!actions && { actions }),
      label: agent.name || agent.label,
    };

    setAgentLoading(true);

    let createdAgent: Agent | null = null;

    if (editing) {
      await patch(url, clonedAgent, { envId });
    } else {
      const canCreateAgent = async () => {
        const resp = await auth.currentUser?.getIdTokenResult();
        if (!resp) {
          notification.error(`Auth expired`);
          auth.signOut();
          return (window.location.href = '/login');
        }
        const { id } = resp.claims;

        const currentUser = await get(`/users/${id}`, {
          envId,
        });
        const query = new URLSearchParams({ size: '1', page: '1' });
        const data = await get(`/agents?${query.toString()}`, {
          envId,
        });

        if (currentUser?.organization?.planId) {
          const plan = await get(
            `/plans/${currentUser?.organization?.planId}`,
            {
              envId,
            },
          );
          const maxAgents = plan?.featureGates?.agent;
          if (maxAgents && data.total >= maxAgents) {
            notification.error(
              `The ${plan.name} plan is limited to ${maxAgents} agent(s). Please upgrade to add more agents`,
            );
            return false;
          }
        }
        return true;
      };

      if (!(await canCreateAgent())) {
        setAgentLoading(false);
        return;
      }

      createdAgent = await post(url, clonedAgent, { envId });
    }

    notification.success(
      `Agent ${editing ? 'updated' : 'created'} successfully`,
    );

    if (!editing && createdAgent) {
      navigate(createdAgent?.id ? `/agent/edit/${createdAgent.id}` : '/agents');
    }

    setAgentLoading(false);
  };

  const saveAndExit = async () => {
    await saveAgent();
    navigate('/agents');
  };

  useEffect(() => {
    fetchActions();
  }, [envId, fetchActions]);

  useEffect(() => {
    if (editId) {
      const populateAgent = async () => {
        setAgentLoading(true);

        const agent = await get(`/agents/${editId}`, { envId });

        setAgent({
          ...agent,
          llm_fallback: agent.llm_fallback || AgentStub.llm_fallback,
          prompt: agent.prompt?.id || '',
          voice: agent.voice?.id || '',
          webhook: agent.webhook?.id || '',
          actions: agent.actions.map((action: Action) => action.id),
          transcriber: agent.transcriber,
        });
        setAttachedActions(agent.actions);
        setAttachedVoice(agent.voice);
        setAttachedPrompt(agent.prompt);
        setAttachedWebhook(agent.webhook);
        setAgentLoading(false);

        const numbers = await get(
          `/numbers?filters=${JSON.stringify({ agent: editId })}`,
          {
            envId,
          },
        );
        setAgentNumbers(numbers?.items || []);
      };

      populateAgent();
    }
  }, [editId, envId]);

  useEffect(() => {
    setDisabled(
      !agent.voice ||
        !agent.prompt ||
        String(agent.prompt) === '' ||
        (agent.prompt as Prompt).content === '',
    );
  }, [agent]);

  useEffect(() => {
    async function populatePromptContent() {
      if (typeof agent?.prompt === 'string') {
        const prompt = await get(`/prompts/${agent.prompt}`, {
          envId,
        });
        setAgent({ ...agent, prompt });
      }
    }

    populatePromptContent();
  }, [agent?.prompt]);

  return (
    <>
      <Heading
        className="border-b-0"
        title={editing ? (agent.name ? agent.name : 'Edit Agent') : 'New Agent'}
        backAction={() => navigate(`/agents`)}
        subtitle={
          (agentNumbers.length > 0 &&
            agentNumbers
              .map((number) => processPhoneNumber(number.number))
              .join(', ')) ||
          'No numbers assigned'
        }
      />

      <TabsAgent
        agent={agent}
        setAgent={setAgent}
        loading={actionLoading || agentLoading}
        defaultVoice={attachedVoice}
        defaultPrompt={attachedPrompt}
        defaultWebhook={attachedWebhook}
        defaultActions={attachedActions}
        actions={actions}
        finishAction={disabled ? undefined : saveAgent}
        isEditing={editing}
      />

      <div className="flex justify-end p-4 border-t border-gray-200 m-0">
        <div className="flex gap-2">
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate('/agents')}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            size="small"
            onClick={saveAndExit}
            disabled={disabled || agentLoading || actionLoading}
          >
            Save & Exit
          </Button>

          <Button
            size="small"
            onClick={saveAgent}
            disabled={disabled || agentLoading || actionLoading}
          >
            {agentLoading || actionLoading ? 'Loading...' : 'Apply'}
          </Button>
        </div>
      </div>
    </>
  );
};
