import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'src/api/requests';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import useOrganization from 'src/hooks/useOrganization';
import { Caller } from './components/Caller';
import { Feedback } from './components/Feedback';
import { Ready } from './components/Ready';
import { Welcome } from './components/Welcome';
import { OnboardingPhase } from './components/onboardingPhase.type';

export const Onboarding = () => {
  const notification = useNotification();

  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const { organization } = useOrganization();
  const navigate = useNavigate();

  const [currentPhase, setCurrentPhase] = useState<OnboardingPhase>(
    OnboardingPhase.Welcome,
  );

  useEffect(() => {
    const fetchOnboarding = async () => {
      if (!organization.id) return;

      try {
        const response = await get(
          `/organizations/${organization.id}/onboarding`,
          { envId },
        );

        if (
          response &&
          Object.keys(response).length > 0 &&
          response?.currentPhase
        ) {
          setCurrentPhase(response?.currentPhase || OnboardingPhase.Welcome);
        } else {
          throw new Error('No onboarding data found');
        }
      } catch (error) {
        console.error('Error fetching onboarding data', error);
        navigate('/');
      }
    };

    fetchOnboarding();
  }, [organization.id, envId]);

  const renderPhase = () => {
    switch (currentPhase) {
      case OnboardingPhase.Welcome:
        return (
          <Welcome
            onPhaseComplete={() => setCurrentPhase(OnboardingPhase.Caller)}
            organization={organization}
          />
        );
      case OnboardingPhase.Caller:
        return (
          <Caller
            onPhaseComplete={() => setCurrentPhase(OnboardingPhase.Feedback)}
            organization={organization}
            secondaryAction={() => setCurrentPhase(OnboardingPhase.Ready)}
          />
        );
      case OnboardingPhase.Feedback:
        return (
          <Feedback
            onPhaseComplete={() => setCurrentPhase(OnboardingPhase.Ready)}
            organization={organization}
            secondaryAction={() => setCurrentPhase(OnboardingPhase.Welcome)}
          />
        );
      case OnboardingPhase.Ready:
        return (
          <Ready
            onPhaseComplete={() => navigate('/agents')}
            organization={organization}
            secondaryAction={() => setCurrentPhase(OnboardingPhase.Welcome)}
          />
        );
      default:
        return (
          <Welcome
            onPhaseComplete={() => setCurrentPhase(OnboardingPhase.Caller)}
            organization={organization}
          />
        );
    }
  };

  return <div className="min-h-screen">{renderPhase()}</div>;
};
