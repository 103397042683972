import clsx from 'clsx';

interface AnimatedCirclesProps {
  isMaximized: boolean;
  isCalling: boolean;
  audioLevel: number;
  variant: 'large' | 'small';
}

export const AnimatedCircles = ({
  isMaximized,
  isCalling,
  audioLevel,
  variant,
}: AnimatedCirclesProps) => {
  const sizes = {
    large: {
      container: isMaximized ? 'w-[326px] h-[326px]' : 'w-[173px] h-[173px]',
      circles: {
        four: {
          width: isMaximized ? '326px' : '173px',
          height: isMaximized ? '326px' : '173px',
          left: '0',
          top: '0',
        },
        three: {
          width: isMaximized ? '288px' : '152px',
          height: isMaximized ? '288px' : '152px',
          left: isMaximized ? '19px' : '10px',
          top: isMaximized ? '19px' : '10px',
        },
        two: {
          width: isMaximized ? '263px' : '138px',
          height: isMaximized ? '263px' : '138px',
          left: isMaximized ? '31.5px' : '17.5px',
          top: isMaximized ? '31.5px' : '17.5px',
        },
        one: {
          width: isMaximized ? '173px' : '92px',
          height: isMaximized ? '172px' : '92px',
          left: isMaximized ? '76.5px' : '40px',
          top: isMaximized ? '77px' : '40px',
        },
      },
    },
    small: {
      container: 'w-[144px] h-[144px]',
      circles: {
        four: { width: '144px', height: '144px', left: '0', top: '0' },
        three: { width: '127px', height: '127px', left: '8.5px', top: '8.5px' },
        two: { width: '115px', height: '115px', left: '14.5px', top: '14.5px' },
        one: { width: '77px', height: '77px', left: '33.5px', top: '33.5px' },
      },
    },
  };

  const currentSize = sizes[variant];

  return (
    <div className={clsx('relative', currentSize.container)}>
      <div
        className="absolute transition-all"
        style={{
          backgroundImage: `url(/assets/onboarding/call-circle-four.png)`,
          backgroundSize: '100% 100%',
          ...currentSize.circles.four,
          opacity: isCalling ? 0.5 + audioLevel * 0.1 : 1,
          transform: isCalling ? `scale(${1 + audioLevel * 0.01})` : 'scale(1)',
          transition: isCalling
            ? 'opacity 0.15s ease-out, transform 0.15s ease-out'
            : 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        }}
      />
      <div
        className="absolute transition-all"
        style={{
          backgroundImage: `url(/assets/onboarding/call-circle-three.png)`,
          backgroundSize: '100% 100%',
          ...currentSize.circles.three,
          opacity: isCalling ? 0.7 + audioLevel * 0.06 : 1,
          transform: isCalling
            ? `scale(${1 + audioLevel * 0.025})`
            : 'scale(1)',
          transition: isCalling
            ? 'opacity 0.2s ease-out, transform 0.2s ease-out'
            : 'opacity 0.6s ease-in-out, transform 0.6s ease-in-out',
        }}
      />
      <div
        className="absolute transition-all"
        style={{
          backgroundImage: `url(/assets/onboarding/call-circle-two.png)`,
          backgroundSize: '100% 100%',
          ...currentSize.circles.two,
          transform: isCalling
            ? `scale(${1 - audioLevel * 0.018})`
            : 'scale(1)',
          transition: isCalling
            ? 'transform 0.1s ease-out'
            : 'transform 0.7s ease-in-out',
        }}
      />
      <div
        className="absolute transition-all"
        style={{
          backgroundImage: `url(/assets/onboarding/call-circle-one.png)`,
          backgroundSize: '100% 100%',
          ...currentSize.circles.one,
        }}
      />
    </div>
  );
};
