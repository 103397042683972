import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { getPayloadJWT, isJWT } from 'src/utils/jwt';

const useOrganization = () => {
  const auth = getAuth();
  const [organizationId, setOrganizationId] = useState(null);

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const token = await auth.currentUser?.getIdToken();

        if (!token || !isJWT(token)) {
          setOrganizationId(null);
          return;
        }

        const decoded = getPayloadJWT(token);
        setOrganizationId(decoded.organization?.id);
      } catch (error) {
        console.error('Error getting organization:', error);
        setOrganizationId(null);
      }
    };

    getOrganization();
  }, [auth.currentUser?.uid]);

  return {
    organization: { id: organizationId },
  };
};

export default useOrganization;
