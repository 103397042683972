import clsx from 'clsx';
import { useState } from 'react';
import { patch } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { OnboardingComponent } from 'src/interfaces/onboardingComponent.interface';

export const Feedback = ({
  onPhaseComplete,
  organization,
  secondaryAction,
}: OnboardingComponent) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [selectedRating, setSelectedRating] = useState<string | null>(null);

  const ratings = [
    { value: 'Bad', label: 'Bad' },
    { value: 'Poor', label: 'Poor' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Good', label: 'Good' },
    { value: 'Excellent', label: 'Excellent' },
  ];

  const handleSubmit = async () => {
    await patch(
      `/organizations/${organization.id}/onboarding`,
      { currentPhase: 'ready', rating: selectedRating, isCompleted: true },
      { envId },
    );
    onPhaseComplete();
  };

  const resetFlow = async () => {
    if (!secondaryAction) return;

    await patch(
      `/organizations/${organization.id}/onboarding`,
      { currentPhase: 'welcome', isCompleted: false },
      { envId },
    );

    secondaryAction();
  };

  return (
    <>
      <img
        src="/assets/logo-white.png"
        alt="Circle"
        className={clsx('absolute top-10 left-10 h-10 animate-fade-in-up')}
      />

      <div className="container mx-auto py-10">
        <div className="flex flex-col items-center justify-center min-h-[80vh] p-8 animate-fade-in">
          <img
            src="/assets/onboarding/circle.png"
            alt="Circle Chat"
            className="w-16 h-16 mb-4 animate-fade-in-up"
          />

          <h1 className="text-[32px] md:text-[40px] font-normal mb-2 text-center text-white animate-fade-in-up">
            How was your experience with the agent?
          </h1>

          <p className="text-[20px] md:text-2xl mb-8 text-center font-normal text-neutral-225">
            Submit feedback to help improve the voice agent model
          </p>

          <div className="flex justify-center items-center gap-12 mb-16 mt-6">
            {ratings.map((rating) => (
              <div
                key={rating.value}
                className="flex flex-col items-center gap-2"
              >
                <button
                  className={`w-[38px] h-[38px] rounded-full border-2 ${
                    selectedRating === rating.value
                      ? 'border-white bg-white bg-opacity-20'
                      : 'border-white border-opacity-50'
                  } transition-all duration-200`}
                  onClick={() => setSelectedRating(rating.value)}
                  aria-label={`Rate as ${rating.label}`}
                />
                <span className="text-white text-normal font-medium">
                  {rating.label}
                </span>
              </div>
            ))}
          </div>

          <div className="w-screen absolute bottom-0 left-0 bg-neutral-715 p-4 flex flex-col md:flex-row md:justify-end gap-6">
            <Button
              variant="outlined"
              className="border-white text-white px-4 py-3 min-w-[96px]"
              size="small"
              onClick={resetFlow}
            >
              Back
            </Button>

            <Button
              onClick={handleSubmit}
              className="px-4 py-3 min-w-[96px]"
              disabled={!selectedRating}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
