// Adapted from https://github.com/samirkumardas/pcm-player/blob/master/pcm-player.js

type PCMPlayerOptions = {
  encoding: '8bitInt' | '16bitInt' | '32bitInt' | '32bitFloat';
  channels: number;
  sampleRate: number;
  flushingTime: number;
  bufferSize: number;
};
export class PCMPlayer {
  option;
  samples: Float32Array;
  interval;
  maxValue;
  typedArray;
  audioCtx: AudioContext | null = null;
  gainNode: GainNode | null = null;
  startTime: number = 0;
  bufferSources;

  constructor(option: PCMPlayerOptions) {
    const defaults = {
      encoding: '16bitInt',
      channels: 1,
      sampleRate: 8000,
      flushingTime: 1000,
    };
    this.option = Object.assign({}, defaults, option);
    this.samples = new Float32Array();
    this.flush = this.flush.bind(this);
    this.interval = setInterval(this.flush, this.option.flushingTime);
    this.maxValue = this.getMaxValue();
    this.typedArray = this.getTypedArray();
    this.createContext();
    // Used to keep track of buffers that haven't been played yet so we can clear it
    // upon interruption
    this.bufferSources = new TimedData();
  }

  getMaxValue = () => {
    const encodings = {
      '8bitInt': 128,
      '16bitInt': 32768,
      '32bitInt': 2147483648,
      '32bitFloat': 1,
    };

    return encodings[this.option.encoding]
      ? encodings[this.option.encoding]
      : encodings['16bitInt'];
  };

  getTypedArray = () => {
    const typedArrays = {
      '8bitInt': Int8Array,
      '16bitInt': Int16Array,
      '32bitInt': Int32Array,
      '32bitFloat': Float32Array,
    };

    return typedArrays[this.option.encoding]
      ? typedArrays[this.option.encoding]
      : typedArrays['16bitInt'];
  };

  createContext = () => {
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    // context needs to be resumed on iOS and Safari (or it will stay in "suspended" state)
    this.audioCtx.resume();
    this.gainNode = this.audioCtx.createGain();
    this.gainNode.gain.value = 1;
    this.gainNode.connect(this.audioCtx.destination);
    this.startTime = this.audioCtx.currentTime;
  };

  isTypedArray = (data: Uint8Array) => {
    return (
      data.byteLength && data.buffer && data.buffer.constructor == ArrayBuffer
    );
  };

  feed = (data: Uint8Array) => {
    if (!this.isTypedArray(data)) return;
    const formattedData = this.getFormattedValue(data);
    const tmp = new Float32Array(this.samples.length + formattedData.length);
    tmp.set(this.samples, 0);
    tmp.set(formattedData, this.samples.length);
    this.samples = tmp;
  };

  getFormattedValue = (data: Uint8Array) => {
    const formattedData = new this.typedArray(data.buffer as ArrayBuffer),
      float32 = new Float32Array(data.length / 2);

    for (let i = 0; i < formattedData.length; i++) {
      float32[i] = formattedData[i] / this.maxValue;
    }
    return float32;
  };

  volume = (volume: number) => {
    if (!this.gainNode) {
      return;
    }
    this.gainNode.gain.value = volume;
  };

  stop = () => {
    this.samples = new Float32Array();
    Object.values<{ value: AudioBufferSourceNode }>(
      this.bufferSources.data,
    ).forEach((item) => item.value?.stop());
  };

  destroy = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.samples = new Float32Array();
    if (this.audioCtx) {
      this.audioCtx.close();
      this.audioCtx = null;
    }
  };

  flush = () => {
    if (!this.samples.length || !this.audioCtx || !this.gainNode) return;
    const bufferSource = this.audioCtx.createBufferSource(),
      numSamples = this.samples.length / this.option.channels,
      audioBuffer = this.audioCtx.createBuffer(
        this.option.channels,
        numSamples,
        this.option.sampleRate,
      );
    let audioData;
    let offset;
    let decrement;

    for (let channel = 0; channel < this.option.channels; channel++) {
      audioData = audioBuffer.getChannelData(channel);
      offset = channel;
      decrement = 50;
      for (let i = 0; i < numSamples; i++) {
        audioData[i] = this.samples[offset];
        /* fadein */
        if (i < 50) {
          audioData[i] = (audioData[i] * i) / 50;
        }
        /* fadeout*/
        if (i >= numSamples - 51) {
          audioData[i] = (audioData[i] * decrement--) / 50;
        }
        offset += this.option.channels;
      }
    }

    if (this.startTime < this.audioCtx.currentTime) {
      this.startTime = this.audioCtx.currentTime;
    }
    // Used to detect drift from incoming audio and played audio
    // console.debug(
    //   `start ${this.startTime} vs current ${this.audioCtx.currentTime}. duration: ${audioBuffer.duration}`
    // );
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(this.gainNode);
    bufferSource.start(this.startTime);
    this.startTime += audioBuffer.duration;
    this.samples = new Float32Array();
    this.bufferSources.add(
      `${this.startTime}`,
      bufferSource,
      audioBuffer.duration * 1000,
    );
  };
}

// Used to keep track of the buffers that have not played yet
// If they have not been played and we pause, we want to iterate through them
// and stop each buffer
class TimedData {
  data: { [key: string]: { value: AudioBufferSourceNode; expiry: number } };
  constructor() {
    this.data = {};
  }

  add(key: string, value: AudioBufferSourceNode, expiryTime: number) {
    this.data[key] = { value, expiry: Date.now() + expiryTime };
    setTimeout(() => {
      this.delete(key);
    }, expiryTime);
  }

  get(key: string) {
    if (this.data[key] && this.data[key].expiry > Date.now()) {
      return this.data[key].value;
    }
    this.delete(key);
    return undefined;
  }

  delete(key: string) {
    delete this.data[key];
  }
}
