import clsx from 'clsx';
import { patch } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { OnboardingComponent } from 'src/interfaces/onboardingComponent.interface';

export const Ready = ({
  onPhaseComplete,
  organization,
  secondaryAction,
}: OnboardingComponent) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const backButton = async () => {
    if (!secondaryAction) return;

    await patch(
      `/organizations/${organization.id}/onboarding`,
      { currentPhase: 'welcome', isCompleted: false },
      { envId },
    );

    secondaryAction();
  };

  const continueButton = async () => {
    await patch(
      `/organizations/${organization.id}/onboarding`,
      { currentPhase: 'ready', isCompleted: true },
      { envId },
    );
    onPhaseComplete();
  };

  return (
    <>
      <img
        src="/assets/logo-white.png"
        alt="Circle"
        className={clsx('absolute top-10 left-10 h-10 animate-fade-in-up')}
      />
      <div className="container mx-auto py-10">
        <div className="flex flex-col items-center justify-center min-h-[80vh] p-8 animate-fade-in">
          <img
            src="/assets/onboarding/circle.png"
            alt="Circle Chat"
            className="w-16 h-16 mb-4 animate-fade-in-up"
          />

          <h1 className="text-[40px] font-normal mb-2 text-center text-white animate-fade-in-up">
            I think you're ready to uncover more at Fluents.
          </h1>

          <div className="w-screen absolute bottom-0 left-0 bg-neutral-715 p-4 flex flex-col md:flex-row md:justify-end gap-6">
            <Button
              variant="outlined"
              className="border-white text-white px-4 py-3 min-w-[96px]"
              size="small"
              onClick={backButton}
            >
              Back
            </Button>

            <Button onClick={continueButton} className="px-4 py-3 min-w-[96px]">
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
