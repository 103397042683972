import { Plus, X } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { Popover } from '@headlessui/react';
import { Button } from './Button';
import { PropertyForm } from './PropertyForm';
import { PropertyItem } from './PropertyItem';
import { useNotification } from 'src/contexts/NotificationContext';
import { createPortal } from 'react-dom';

export interface SchemaProperty {
  name: string;
  description: string;
  type: 'string' | 'number' | 'boolean';
  required?: boolean;
}

interface JsonSchemaType {
  type: string;
  properties: { [key: string]: any };
  required?: string[];
}

interface SchemaBuilderProps {
  value: string;
  onChange: (schema: string) => void;
}

export const SchemaBuilder = ({ value, onChange }: SchemaBuilderProps) => {
  const notification = useNotification();

  const [properties, setProperties] = useState<SchemaProperty[]>([]);
  const [selectedProperty, setSelectedProperty] =
    useState<SchemaProperty | null>(null);
  const [editingForm, setEditingForm] = useState<SchemaProperty | null>(null);

  const generateJsonSchema = (props: SchemaProperty[]): JsonSchemaType => {
    const schema: JsonSchemaType = {
      type: 'object',
      properties: {},
      required: [],
    };

    props.forEach((prop) => {
      schema.properties[prop.name] = {
        type: prop.type,
        description: prop.description,
      };

      if (prop.required) {
        schema.required?.push(prop.name);
      }
    });

    if (schema.required?.length === 0) {
      delete schema.required;
    }

    return schema;
  };

  const parseJsonSchema = (jsonString: string): SchemaProperty[] => {
    try {
      const schema = JSON.parse(jsonString);
      if (schema.type !== 'object') return [];

      const properties: SchemaProperty[] = [];

      for (const [name, value] of Object.entries(schema.properties)) {
        const prop: SchemaProperty = {
          name,
          description: (value as { description?: string }).description || '',
          type: (value as { type: string }).type as
            | 'string'
            | 'number'
            | 'boolean',
          required: schema.required?.includes(name) || false,
        };
        properties.push(prop);
      }

      return properties;
    } catch (e) {
      notification.warning('Invalid JSON schema');
      return [];
    }
  };

  useEffect(() => {
    if (value && value !== '{}') {
      const parsedProperties = parseJsonSchema(value);
      setProperties(parsedProperties);
    }
  }, []);

  useEffect(() => {
    const schema = generateJsonSchema(properties);
    onChange(JSON.stringify(schema, null, 2));
  }, [properties]);

  const handlePropertySave = (property: SchemaProperty, close: () => void) => {
    if (selectedProperty) {
      setProperties(
        properties.map((p) =>
          p.name === selectedProperty.name ? property : p,
        ),
      );
    } else {
      setProperties([...properties, property]);
    }
    setEditingForm(null);
    setSelectedProperty(null);
    close();
  };

  const popoverStyle =
    'fixed z-[9999] top-[calc(50vh-200px)] left-[calc(50vw-192px)] w-96 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 p-4 bg-neutral-475';

  const popoverHeaderStyle = 'flex justify-between items-center mb-2';

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="flex justify-between items-center mb-4 border-b pb-1">
          <span className="text-sm">Properties</span>

          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button as={Button} size="small">
                  <Plus size={16} className="mr-2" weight="bold" />
                  Add Property
                </Popover.Button>

                {open &&
                  createPortal(
                    <Popover.Panel className={popoverStyle}>
                      <div className={popoverHeaderStyle}>
                        <span className="text-lg font-medium text-white">
                          Create new property
                        </span>
                        <button onClick={close} className="text-white">
                          <X size={18} />
                        </button>
                      </div>
                      <PropertyForm
                        property={null}
                        onSave={(property) =>
                          handlePropertySave(property, close)
                        }
                      />
                    </Popover.Panel>,
                    document.body,
                  )}
              </>
            )}
          </Popover>
        </div>

        <div className="space-y-2">
          {properties.map((prop, index) => (
            <Popover key={index} className="relative">
              {({ open, close }) => (
                <>
                  <PropertyItem
                    property={prop}
                    onEdit={() => {
                      const propertyToEdit = { ...properties[index] };
                      setSelectedProperty(propertyToEdit);
                      setEditingForm(propertyToEdit);
                    }}
                    onDelete={() => {
                      setProperties(properties.filter((_, i) => i !== index));
                    }}
                  />

                  {open &&
                    createPortal(
                      <Popover.Panel className={popoverStyle}>
                        <div className={popoverHeaderStyle}>
                          <span className="text-lg font-medium text-white">
                            Edit Property
                          </span>
                          <button onClick={close} className="text-white">
                            <X size={18} />
                          </button>
                        </div>
                        <PropertyForm
                          property={editingForm}
                          onSave={(property) =>
                            handlePropertySave(property, close)
                          }
                        />
                      </Popover.Panel>,
                      document.body,
                    )}
                </>
              )}
            </Popover>
          ))}
        </div>
      </div>

      <CopyBlock
        text={value || '{}'}
        language="json"
        theme={dracula}
        showLineNumbers={false}
        customStyle={{
          overflowX: 'auto',
          minHeight: '60px',
        }}
        wrapLongLines={true}
      />
    </div>
  );
};

export default SchemaBuilder;
