interface ProviderOption {
  label: string;
  value: string;
  type: 'Telephony' | 'LLM' | 'Voice' | 'Transcription' | 'Workflow' | 'Auth';
  hidden?: boolean;
}

export const nonApiKeyConnections = [
  'account_connection_twilio',
  'account_connection_openai',
  'account_connection_login_key_oauth',
  'account_connection_un_pw_oauth',
];

export const providerOptions: ProviderOption[] = [
  { label: 'Twilio', value: 'account_connection_twilio', type: 'Telephony' },
  { label: 'Telnyx', value: 'account_connection_telnyx', type: 'Telephony' },
  { label: 'OpenAI', value: 'account_connection_openai', type: 'LLM' },
  {
    label: 'ElevenLabs',
    value: 'account_connection_elevenlabs',
    type: 'Voice',
  },
  {
    label: 'Deepgram',
    value: 'account_connection_deepgram',
    type: 'Transcription',
  },
  {
    label: 'AssemblyAI',
    value: 'account_connection_assemblyai',
    type: 'Transcription',
  },
  {
    label: 'Cartesia',
    value: 'account_connection_cartesia',
    type: 'Voice',
  },
  { label: 'PlayHT', value: 'account_connection_playht', type: 'Voice' },
  { label: 'Rime', value: 'account_connection_rime', type: 'Voice' },
  {
    label: 'Azure Speech',
    value: 'account_connection_azure_speech',
    type: 'Voice',
  },
  {
    label: 'Google',
    value: 'account_connection_google',
    type: 'LLM',
  },
  { label: 'Azure LLM', value: 'account_connection_azure_llm', type: 'LLM' },
  { label: 'DeepSeek', value: 'account_connection_deepseek', type: 'LLM' },
  {
    label: 'Login Key Auth',
    value: 'account_connection_login_key_oauth',
    type: 'Auth',
    hidden: true,
  },
  {
    label: 'API Key Auth',
    value: 'account_connection_external_api_key',
    type: 'Auth',
  },
  {
    label: 'Username Password Oauth',
    value: 'account_connection_un_pw_oauth',
    type: 'Auth',
    hidden: true,
  },
];
