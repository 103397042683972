import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { DropdownMenu } from './DropdownMenu';
import { Tooltip } from './Tooltip';

type MenuItemProps = {
  title: string;
  isCollapsed: boolean | undefined;
  icon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

type CollapsibleMenuItemProps = {
  title: string;
  isCollapsed: boolean | undefined;
  children: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
};

type MenuItemGroupProps = {
  title: string;
  isCollapsed: boolean | undefined;
  children: React.ReactNode;
  className?: string;
};

type SubMenuItemProps = {
  title: string;
  onClick?: () => void;
};

export const MenuElement = ({
  icon,
  title,
  isCollapsed = false,
  onClick,
  className,
}: MenuItemProps) => (
  <li
    className={`p-2 rounded cursor-pointer flex items-center border-2 border-white rounded-lg hover:bg-neutral-975 text-slate-700 hover:text-black font-semi-bold ${
      isCollapsed ? 'justify-center' : ''
    } ${className}`}
    onClick={onClick}
  >
    {!!icon && <span className={isCollapsed ? '' : 'mr-4'}>{icon}</span>}
    {!isCollapsed && <span>{title}</span>}
  </li>
);

export const MenuItem = (props: MenuItemProps) => {
  return props.isCollapsed ? (
    <Tooltip content={props.title} position="right">
      <MenuElement {...props} />
    </Tooltip>
  ) : (
    <MenuElement {...props} />
  );
};

export const SubMenuItem = ({ title, onClick }: SubMenuItemProps) => {
  return (
    <li
      className={clsx(
        `border-2 hover:border-control-plane-300 text-control-plane-300 p-2 rounded cursor-pointer`,
      )}
      onClick={onClick}
    >
      {title}
    </li>
  );
};

export const CollapsibleMenuItem = ({
  icon,
  title,
  isCollapsed = false,
  children,
  className = '',
}: CollapsibleMenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = () => {
    if (isCollapsed) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const CollapsibleMenuElement = () => (
    <li className={className}>
      <div
        onClick={toggleSubMenu}
        className={`border-2 border-white hover:border-control-plane-300 text-control-plane-300 p-2 rounded cursor-pointer flex items-center font-bold ${
          isCollapsed ? 'justify-center' : 'justify-between'
        }`}
      >
        <div
          className={`flex items-center ${isCollapsed ? 'justify-center' : ''}`}
        >
          <span className={isCollapsed ? '' : 'mr-4'}>{icon}</span>
          {!isCollapsed && <span>{title}</span>}
        </div>
        {!isCollapsed && <span>{isOpen ? '-' : '+'}</span>}
      </div>

      {!isCollapsed && isOpen && (
        <ul className="space-y-1 mt-1 ml-4">{children}</ul>
      )}
    </li>
  );

  return isCollapsed ? (
    <Tooltip content={title} position="right">
      <div ref={dropdownRef}>
        <MenuElement
          icon={icon}
          title={title}
          isCollapsed={isCollapsed}
          onClick={toggleSubMenu}
        />

        <DropdownMenu
          isOpen={isOpen}
          position="bottom-left"
          onItemClick={() => setIsOpen(false)}
        >
          {children}
        </DropdownMenu>
      </div>
    </Tooltip>
  ) : (
    <CollapsibleMenuElement />
  );
};

export const MenuItemGroup = ({
  title,
  isCollapsed = false,
  children,
  className = '',
}: MenuItemGroupProps) => {
  return isCollapsed ? (
    <div className={className}>
      <hr className="border-gray-400 mb-2" />
      {children}
    </div>
  ) : (
    <li className={className}>
      <div className="text-sm border-2 border-white text-slate-600 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <span className="uppercase text-[14px] font-normal">{title}</span>
        </div>
      </div>
      <ul className="space-y-1 mt-1">{children}</ul>
    </li>
  );
};
